
import { IonTitle, IonGrid,IonRow,IonCol,IonList,IonItem,IonToggle, ToggleCustomEvent, } from '@ionic/vue';

import { defineComponent, onMounted, ref, Ref } from 'vue';
import { useRouter } from 'vue-router';
import { ReturnReasonDto } from '@/models/configService/ReturnReasons';
import { useAuthStore } from '@/store/authStore';
import { useMerchantStore } from '@/store/merchantStore';
import { useConfigStore } from '@/store/configStore';

class ReturnReasonViewModel  
{
    id: string;
    code: string;
    warehouseReasonCode: string;
    text: string;
    enabled: boolean;
    isRemoved: boolean;

    constructor(dto: ReturnReasonDto, locale: string, enabled: boolean){
        this.id = dto.id;
        this.code = dto.code;
        this.warehouseReasonCode = dto.warehouseReasonCode;
        this.text = dto.localizations.find(l=>l.lang === locale)?.context ?? 
                    dto.localizations.find(l=>l.lang === "en")?.context ?? 
                    "";
        this.enabled = enabled;
        this.isRemoved = dto.isRemoved;
    }
}

export default defineComponent({
  name: 'ReturnReasons',
  components: {
    // IonSplitPane, 
    // PageHeader,
    // PageFooter,
    IonGrid,
    IonRow,
    IonCol, 
    // IonPage, 
    // IonContent,
    IonList,IonItem,
    IonToggle,
    IonTitle,
    // IonButton,
    // IonSelect,
    // IonSelectOption,
    // MenuComponent,
    // ReturnMenu,
    // PageDescription,
  },
  data() {
    return {
      chartOptions: {
        responsive: true
      }
    }
  },
  setup (){
    const router = useRouter();
    const auth = useAuthStore();
    const merchantStore = useMerchantStore();
    const configStore = useConfigStore();
    const returnReasons: Ref<Array<ReturnReasonViewModel>> = ref([]);

    let allReasons:Array<ReturnReasonDto> = [];

    const updateReturnReasons = async (active: string[]) => {
      returnReasons.value = allReasons.map((val)=> {
        return new ReturnReasonViewModel(val,"en", active.indexOf(val.id) >= 0);
      }).sort((i,j)=>{
        if (i.text < j.text) { return -1; }
        if (i.text > j.text) { return 1; }
        return 0;      
      });
      returnReasons.value = returnReasons.value.filter(i=>!i.isRemoved ||  (i.enabled && i.isRemoved));
    }

    onMounted(async () => {
      if(!auth.merchantId || !auth.channelId)
        return;

      allReasons = await configStore.getChannelReturnReasons(auth.channelId);
      const active = await merchantStore.getEnabledReturnReasons(auth.merchantId, auth.channelId);
      await updateReturnReasons(active);        
    });

    const wasChanged = async (_a: ToggleCustomEvent, _b:ReturnReasonViewModel) =>{
      if(!auth.merchantId || !auth.channelId)
        return;
      
      const idList = returnReasons.value.filter(i=>i.enabled).map(i=>i.id);
      const active = await merchantStore.updateEnabledReturnReasons(auth.merchantId, auth.channelId, idList);
      await updateReturnReasons(active);
    }

  return {
    returnReasons,router,wasChanged,
  }
}
});

