
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonTitle,
  IonToolbar,
  IonInput,
  IonItem,
  IonList,
  IonGrid,
  IonRow,
  IonCol,
  IonSelect,
  IonSelectOption,
  loadingController,
  IonText,
} from "@ionic/vue";

import { defineComponent, onMounted, Ref, ref } from "vue";
import { useRouter } from "vue-router";
import { personOutline, personAddOutline, logInOutline, cartOutline, syncOutline, } from "ionicons/icons";
import { UserLogin, } from "../../store";
import { useErrorBox } from "@/components/errorBox";
import { useI18n } from "vue-i18n";
import { MessageSchema, NumberSchema } from "@/main";
import { useAuthStore } from "@/store/authStore";

export default defineComponent({
  name: "LoginView",
  data() {
    return {
      loginInfo: { username: "", password: "" },
    };
  },
  components: {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonList,
    IonIcon,
    IonItem,
    IonPage,
    IonTitle,
    IonToolbar,
    IonInput,
    IonGrid,
    IonRow,
    IonCol,
    IonSelect,
    IonSelectOption,
    IonText,
  },
  setup() {
    const router = useRouter();
    const auth = useAuthStore();
    
    const { t, n, availableLocales, locale,te } = useI18n<{
      message: MessageSchema;
      number: NumberSchema;
    }>({
      useScope: "global",
      // inheritLocale: true,
    });

    const processing: Ref<boolean> = ref(false);
    const { showError } = useErrorBox();
    const pageContent: Ref<object|undefined> = ref(undefined);

    const loading = async () => {
      const loading = await loadingController.create({
        cssClass: "my-custom-class",
        message: "Processing...",
        duration: undefined,
      });

      await loading.present();
      return loading;
    };

    onMounted(async () => {
      const l = auth.locale;
      if (l) { locale.value = l as any; }
      await auth.checkAuth();
      
      // Login with myOGO1
      loginWithRedirect();
    });

    const loginNow = async (_p: UserLogin) => {
      const l = await loading();
      try {
        processing.value = true;
        // await store.dispatch(ActionTypes.LOGIN, {
        //   login: p,
        //   waitAll: true,
        // });
        processing.value = false;
        l.dismiss();

        // if (session.enteredRoute && session.enteredRoute != "/login") {
        //   router.replace(session.enteredRoute);
        // } else 
        router.replace("/main/home");
      } catch (err) {        
        processing.value = false;
        l.dismiss();

        if(te(err as string))
          showError(t(err as string), t("global.Error"));
        else
          showError(err as string, t("global.Error"));
      }
    };

    const loginWithRedirect = () => {

      const currentDomain = location.protocol + "//" + location.hostname + (location.port ? ":" + location.port : "");
      const url = auth.myOGO1Domain + "/OAuth/Authorize?response_type=token&redirect_uri=" + currentDomain + "/auth/return&client_id=myOgo2&redir=/";

      window.location.replace(url);
    }

    const localeChanged = async (_a: any) => {
      auth.locale = locale.value;
    };

    return {
      router,
      loginNow,
      logInOutline,
      personOutline,
      personAddOutline,
      cartOutline,
      syncOutline,
      processing,
      localeChanged,
      t,
      n,
      availableLocales,
      locale,
      pageContent,
      loginWithRedirect,
    };
  },
});
