
import {
    IonPage, IonCol, IonItem, IonLabel, IonGrid, IonRow, IonInput,
    IonIcon, IonButton, IonContent,
} from '@ionic/vue';
import { images, documentOutline, copyOutline, trashOutline, downloadOutline, eyeOutline } from 'ionicons/icons';
import { Ref, defineComponent, onMounted, ref, } from 'vue';
import PageDescription from '@/components/PageDescription.vue';
import PageFooter from "@/components/PageFooter.vue";
import PageHeader from "@/components/PageHeader.vue";
import { useMerchantStore } from '@/store/merchantStore';
import { SearchDefinition } from '@/models/orderService/ReturnsListFilter';
import { useAuthStore } from '@/store/authStore';
import { useErrorBox } from '@/components/errorBox';


export default defineComponent({
    name: 'StaffSearch',
    components: {
        IonPage,
        PageDescription,
        PageFooter,
        PageHeader,
        IonItem,
        IonGrid,
        IonButton,
        IonCol,
        IonRow,
        IonInput,
        IonIcon,
        IonContent,
        IonLabel,
    },
    setup() {
        const merchantStore = useMerchantStore();
        const { showError } = useErrorBox();
        const auth = useAuthStore();
        const merchantTest: Ref<boolean> = ref(false);
        const merchantSearchInput: Ref<string|undefined> = ref(undefined);
        const listResult: Ref<Array<any>> = ref([]);     
         onMounted( async() => {
            if(!auth.filteredRoles.includes("Staff"))
          await showError("Missing admins role", "Missing permission");
         });

            /// Load data to list
    const searchMerchant = async (take: number) => {
      const search = new SearchDefinition();
      listResult.value = [];
      search.limit.take = take;
      search.globalSearch = merchantSearchInput.value;

      try {
        const r = await merchantStore.getChannels(search);
        r.forEach(row => { listResult.value.push(row); });

      } catch(e) {
        console.log(e);
      }
    }

    const openMerchant = (merchantId : string) => {
          window.location.assign(auth.myOGO1Domain + `/merchant/edit/${merchantId}`);
        //  location.replace(auth.myOGO1Domain + `/merchant/edit/${merchantId}`);       
      }

        return { images, documentOutline, copyOutline, trashOutline, downloadOutline, eyeOutline, merchantTest, merchantSearchInput, searchMerchant, listResult, openMerchant }
    }
});

