import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bb4ce3aa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "page-layout" }
const _hoisted_2 = { class: "inner-panel" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_header = _resolveComponent("page-header")!
  const _component_PageDescription = _resolveComponent("PageDescription")!
  const _component_AdminMenu = _resolveComponent("AdminMenu")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_badge = _resolveComponent("ion-badge")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_page_footer = _resolveComponent("page-footer")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_page_header, { pageName: "Return reasons list" }),
      _createVNode(_component_ion_content, { fullscreen: true }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            false
              ? (_openBlock(), _createBlock(_component_PageDescription, {
                  key: 0,
                  pageName: "Return reasons list",
                  pageDescription: "Return reasons list",
                  breadCrumbs: [{title: 'Admin', active: true, url: '/home'}]
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_AdminMenu),
            _createVNode(_component_ion_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_col, null, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_2, [
                      _createVNode(_component_ion_button, {
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.router.push({name: 'ReturnReasonAdminDetails', params: {id: ``}}))),
                        class: "ion-float-right"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("New")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_select, {
                        label: "preview language",
                        "label-placement": "floating",
                        modelValue: _ctx.lang,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.lang) = $event)),
                        style: {"width":"200px"},
                        class: "ion-float-right"
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.allLanguages, (lang) => {
                            return (_openBlock(), _createBlock(_component_ion_select_option, {
                              key: lang.code,
                              value: lang.code
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(lang.name), 1)
                              ]),
                              _: 2
                            }, 1032, ["value"]))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["modelValue"]),
                      _createVNode(_component_ion_title, null, {
                        default: _withCtx(() => [
                          _createTextVNode("Common return reasons")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_list, { style: {"clear":"both"} }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_item, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => [
                                  _createTextVNode("Code")
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => [
                                  _createTextVNode("Text")
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.allReturnReasons, (reason) => {
                            return (_openBlock(), _createBlock(_component_ion_item, {
                              key: reason.id,
                              class: _normalizeClass({'removed': reason.isRemoved})
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_label, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(reason.code), 1)
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_ion_label, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(reason.localizations.find(l=>l.lang === _ctx.lang)?.context) + " ", 1),
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(reason.localizations.filter(i=>i.context), (langOption) => {
                                      return (_openBlock(), _createBlock(_component_ion_badge, {
                                        class: "fized-size ion-float-right",
                                        color: "tertiary",
                                        style: {"margin-right":"5px","cursor":"pointer"},
                                        onClick: ($event: any) => (_ctx.lang = langOption.lang),
                                        key: langOption.lang
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(langOption.lang), 1)
                                        ]),
                                        _: 2
                                      }, 1032, ["onClick"]))
                                    }), 128))
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_ion_button, {
                                  onClick: ($event: any) => (_ctx.router.push({name: 'ReturnReasonAdminDetails', params: {id: reason.id}}))
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode("edit")
                                  ]),
                                  _: 2
                                }, 1032, ["onClick"])
                              ]),
                              _: 2
                            }, 1032, ["class"]))
                          }), 128))
                        ]),
                        _: 1
                      })
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_page_footer)
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}