

import {
  peopleOutline,
} from "ionicons/icons";

import qs from "qs";
import jwt_decode from "jwt-decode";
import { onMounted } from 'vue';
import router from '@/router';
import { useErrorBox } from "@/components/errorBox";
import { useAuthStore } from "@/store/authStore";
import { useLogger } from "@/utils/logging";

  export default {
    props: {
      pageName: String,
    },
    components: {
    },
    name: "ReturnPage",
    setup (){
      const auth = useAuthStore();
      const {showError} = useErrorBox();
      const {logInfo, logError} = useLogger();

      onMounted(async () => {
        const hash = window.location.hash.substring(1);
        const parsed = qs.parse(hash);
        const token = parsed.access_token as string;
        const decoded = jwt_decode(token);
        // console.log(decoded);
        logInfo("ReturnPage token:{token}", {token:decoded});

        try {
          await auth.loginWithToken(token);
          const redir = (parsed.redir as string|undefined) || "/";
          router.replace(redir);          
        } catch (err){          
          logError("ReturnPage auth error:{err} token:{token}", {err, token:decoded});          
          await showError(err, "ERROR!");
          router.replace("/");
        }        
    });


      return {
        peopleOutline,
      }
    }
  };
