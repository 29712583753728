

import {
    IonGrid, IonRow, IonCol, IonItem, IonList,
    IonButton, toastController, IonBadge,
IonButtons,
IonTextarea,
} from '@ionic/vue';
import { computed, defineComponent, onMounted, ref, Ref, } from 'vue';
import { useAuthStore } from '@/store/authStore';
import { useMerchantStore } from '@/store/merchantStore';
import { ChannelUIDto, LanguageSettings, Theme, TextCustomizationDto } from '@/models/merchantService/CustomerPortalSettings';
import { useErrorBox } from '@/components/errorBox';
import { stringToHash } from '@/utils/changeTracking';
import { RebuyLevel } from '@/models/merchantService/Merchant';
export default defineComponent({
    name: 'TextCustomizationSettings',
    components: {
        IonGrid,
        IonRow,
        IonCol,
        IonItem,
        IonList,
        IonBadge,
        IonTextarea,
        IonButton,
        IonButtons,

    },
    setup(props, { expose }) {
        const auth = useAuthStore();
        const merchantStore = useMerchantStore();
        const settings: Ref<ChannelUIDto | undefined> = ref(undefined);
        const rebuyLevel = merchantStore.currentMerchant?.level ?? RebuyLevel.Free;
        const textCustomization: Ref<Array<TextCustomizationDto>> = ref([]);

        const tcDefaults: Ref<TextCustomizationDto[]> = ref([]);

        const languageOptions: string[] = [];

        const { showError } = useErrorBox();

        onMounted(async () => {
            if (!auth.merchantId || !auth.channelId)
                return;

            settings.value = await merchantStore.getCustomerPortalSettings(auth.channelId);
            tcDefaults.value = await merchantStore.getTextCustomizationDefaults();

            if (!settings.value.whiteLabelling)
                settings.value.whiteLabelling = new Theme();

            if (!settings.value.whiteLabelling.languageSettings)
                settings.value.whiteLabelling.languageSettings = new LanguageSettings();

            textCustomization.value = settings.value.textCustomizations ?? [];

            // Set the language options
            Object.entries(settings.value.whiteLabelling.languageSettings).forEach(([key, value]) => {
                if (value && key.length == 2)
                    languageOptions.push(key);
            });


            tcDefaults.value.forEach(tcDefault => {

                // Check that all keys exist in the textCustomization
                if (!textCustomization.value.find(tc => tc.key === tcDefault.key)) {
                    textCustomization.value.push({
                        key: tcDefault.key,
                        value: [],
                    });
                }

                // Check that all languages exist in the textCustomization keys
                languageOptions.forEach(lang => {
                    textCustomization.value.find(tc => tc.key === tcDefault.key)?.value.find(tc => tc.lang === lang) ??
                        textCustomization.value.find(i => i.key == tcDefault.key)?.value.push({
                            lang: lang,
                            text: tcDefault.value.find(v => v.lang === lang)?.text ?? "",
                        });
                })
            });

            savedHash.value = hashComponentState.value;

        });

        const save = async () => {
            if (!auth.merchantId || !auth.channelId || !settings.value || !textCustomization.value)
                return;

            try {

                settings.value = await merchantStore.updateTextCustomizations(settings.value.uid, textCustomization.value);
                textCustomization.value = settings.value.textCustomizations ?? [];
                savedHash.value = hashComponentState.value;

                const toast = await toastController.create({
                    message: "Text customizations saved successfully!",
                    duration: 1500,
                    position: "top",
                    color: "primary",
                });

                await toast.present();

            } catch (e) {
                showError(e as string, "Error saving texts!");
            }
        }

        const revertToDefault = async (tcKey: string, lang: string) => {
            const tc = textCustomization.value.find(tc => tc.key === tcKey);
            if (!tc)
                return;

            const tcDefault = tcDefaults.value.find(tc => tc.key === tcKey);
            if (!tcDefault) {

                const toast = await toastController.create({
                    message: "No default text found!",
                    duration: 1500,
                    position: "top",
                    color: "warning",
                });

                await toast.present();

                return;
            }


            const tcValue = tcDefault.value.find(v => v.lang === lang);
            if (!tcValue) {

                const toast = await toastController.create({
                    message: "No default text found!",
                    duration: 1500,
                    position: "top",
                    color: "warning",
                });

                await toast.present();

                return;
            }


            const tcLang = tc.value.find(v => v.lang === lang);
            if (!tcLang) {

                const toast = await toastController.create({
                    message: "No default text found!",
                    duration: 1500,
                    position: "top",
                    color: "warning",
                });

                await toast.present();

                return;
            }


            tcLang.text = tcValue.text;
        }


        //#region isDirty check for relative simple components
        const savedHash: Ref<number> = ref(0);
        const hashComponentState = computed(() => {
            return stringToHash(JSON.stringify({
                textCustomization: textCustomization.value,
            }));
        });

        const isDirty = computed(() => {
            if (savedHash.value === 0)
                return false;
            return savedHash.value !== hashComponentState.value;
        });
        //#endregion

        expose({ isDirty, save });

        return {
            isDirty, settings, languageOptions,
            save, rebuyLevel, RebuyLevel,
            tcDefaults, textCustomization, revertToDefault
        }
    }
});

