
  import { IonTitle, IonPage, IonList, IonItem, IonGrid, 
    IonRow, IonCol, IonButton, IonContent, IonTextarea, IonInput, loadingController, toastController, IonIcon,
  } from '@ionic/vue';
  import { Ref, computed, defineComponent, onMounted, ref } from 'vue';
  import { useRouter } from 'vue-router';
  import { trashOutline, addOutline, } from 'ionicons/icons';

  import PageHeader from "@/components/PageHeader.vue";
  import PageFooter from "@/components/PageFooter.vue";
  import PageDescription from '@/components/PageDescription.vue';

  import * as moment from 'moment-timezone';
  import { useErrorBox } from '@/components/errorBox';
  import { NotificationFrameDto } from '@/models/notificationService/NotificationFrame';
  import { useNotificationStore } from '@/store/notificationStore';
  import { useAuthStore } from '@/store/authStore';

  export default defineComponent({
    name: 'FrameDetails',
    components: { 
      IonPage,
      IonTitle,
      IonList, IonItem, IonGrid, IonRow, IonCol, IonContent,
      IonButton,
      IonTextarea,
      IonInput,
      IonIcon,
      PageHeader,
      PageFooter,
      PageDescription,
    },
    setup(){
    
      const router = useRouter();
      const notification = useNotificationStore();
      const auth = useAuthStore();
      const { showError } = useErrorBox();

      const momentjs: any = moment;
      moment.locale(auth.locale);
      
      const frameId: Ref<string|undefined> = ref(undefined);
      const frameDetails: Ref<NotificationFrameDto|undefined> = ref(undefined);

      const newSectionName: Ref<string> = ref("");
      const previewHtml: Ref<string> = ref("");

      const loading = async () => {
        const loading = await loadingController.create({
          cssClass: "my-custom-class",
          message: "Processing...",
          duration: undefined,
        });

        await loading.present();
        return loading;
      };

      onMounted( async() => {
        const id = router.currentRoute.value.params["id"].toString();
        const channel = router.currentRoute.value.params["channel"].toString();

        if(id && id !== "00000000-0000-0000-0000-000000000000"){
          frameId.value = id;


          // Show loading dialog while loading
          const l = await loading();
          try {
            frameDetails.value = await notification.getFrame( Number.parseInt(channel), frameId.value);
          } catch (error) {
            showError(error, "Error");
          } finally {
            l.dismiss();
          }          

        } else {
          frameDetails.value = new NotificationFrameDto();
        }
      });

      const addLocalizedString = () => {
        if(!frameDetails.value || !newSectionName.value || newSectionName.value.includes(" ")){
          return;
        }
        if(!frameDetails.value.textSections){
          frameDetails.value.textSections = {};
        }

        frameDetails.value.textSections[newSectionName.value] = '';

        newSectionName.value = "";
      }

      const preview = async () => {
        if(!frameDetails.value){
          return;
        }

        try {
          previewHtml.value = await notification.previewFrame(frameDetails.value);
        } catch (error) {
          showError(error, "Error");
        }

        // const preview = await store.dispatch(ActionTypes.OS_PREVIEW_RETURN, frameDetails.value);
        // console.log(preview);
      }

      const removeLocalizedString = (key: string) => {
        if(!frameDetails.value){
          return;
        }
        if(!frameDetails.value.textSections){
          return;
        }
        delete frameDetails.value.textSections[key];
      }

      const updateText = (key: string, value: string) => {

        if(!frameDetails.value){
          return;
        }
        if(!frameDetails.value.textSections){
          return;
        }
        frameDetails.value.textSections[key] = value;
      }

      const saveAsCopy = async () => {
        if(!frameDetails.value || !auth.channelId){
          return;
        }

        frameDetails.value.channelId = auth.channelId;
        frameDetails.value.id = "00000000-0000-0000-0000-000000000000";
        await save();
      }

      const remove = async () => {
        if(!frameDetails.value)
          return;

          // Save dialog while saving
        const l = await loading();
        try {
        
          await notification.removeFrame(frameDetails.value.channelId,frameDetails.value.id);
        
          // Show toast when saved succesfully
          const toast = await toastController.create({
            message: "Frame removed succesfully.",
            duration: 1500,
            position: "top",
            color: "success",
          });
          await toast.present();
        
        } catch (error) {
          showError(error, "Error removing frame!");
          return;
        
        } finally {
          l.dismiss();
        }

        router.replace({name: "ReturnSettings"});
      }

      const save = async () => {
        if(!frameDetails.value || !auth.channelId)
          return;
        
        // Save as copy
        if(frameDetails.value.channelId != auth.channelId){
          frameDetails.value.channelId = auth.channelId;
          frameDetails.value.id = "00000000-0000-0000-0000-000000000000";
        }

        // Save dialog while saving
        const l = await loading();

        try {

          // Save as new if id is empty
          if(frameDetails.value.id !== "00000000-0000-0000-0000-000000000000"){
            frameDetails.value = await notification.updateFrame(auth.channelId,frameDetails.value);
          } else {
            frameDetails.value = await notification.addFrame(auth.channelId,frameDetails.value);
          }

          // Show toast when saved succesfully
          const toast = await toastController.create({
            message: "Frame saved succesfully.",
            duration: 1500,
            position: "top",
            color: "success",
          });
          await toast.present();
        
        } catch (error) {
          showError(error, "Error saving frame!");
          return;

        } finally {
          l.dismiss();
        }

        // Redirect to new frame
        if(router.currentRoute.value.params["id"].toString() != frameDetails.value.id)
          router.replace({name: "FrameDetails", params: {channel: frameDetails.value.channelId, id: frameDetails.value.id}});
      }

      const saveButtonText = computed(() => {
        if(!frameDetails.value){
          return "";
        } else if(frameDetails.value.id === "00000000-0000-0000-0000-000000000000")
          return "Save as new";
        else if(frameDetails.value.channelId != auth.channelId)
          return "Save as copy";
        else
          return "Save";
        }
      );

      return {frameId, frameDetails, momentjs, addLocalizedString, newSectionName, 
        preview, removeLocalizedString, previewHtml, updateText, save, remove, saveAsCopy, saveButtonText, 
        trashOutline,addOutline, }
    }
  });
