

import { defineComponent, Ref, ref, type PropType, onMounted } from 'vue';
import { IonRadioGroup, IonRadio, IonItem, } from "@ionic/vue";
import { ColumnDefinition } from '@/models/orderService/ReturnsListFilter';

export class SelectOption {
  title: string;
  value: any;
  constructor(title: string, value: any) {
    this.title = title;
    this.value = value;
  }
}

  export default defineComponent({
    name: "RadioSelect",
    props: {
      title: { type: String, required: true },
      filterKey:  { type: String, required: true },
      filter: { type: Object as PropType<ColumnDefinition>, required: true },
    },
    emits: {
      filterChanged: (_event: any, _filter: any, _value: any, _text: any) => true,
    },
    components: {
      IonRadioGroup,
      IonRadio,
      IonItem,
    },
    setup (props, emits) {
      const selectOptions: Ref<Array<SelectOption>> = ref([]);
      const selectedItem: Ref<any> = ref(undefined);

      onMounted ( async() => {
        const entries = Object.entries(props.filter.columnData);
        selectOptions.value = [];
        entries.forEach(e => {
          if (typeof e[1] === 'string')
          {
            selectOptions.value.push(new SelectOption(e[1], e[0]));
          } else if(e[1].title) {
            selectOptions.value.push(new SelectOption(e[1].title, e[0]));
          } else if(e[1].name) {
            selectOptions.value.push(new SelectOption(e[1].name, e[0]));
          }
      });

      const oldValue = (props.filter?.inlineSearchSelectObject as any)?.value;

      if(oldValue){
        // timeout needed so that the value is set after the radio group is rendered
        setTimeout(() => { selectedItem.value = oldValue; }, 100);
      }
    });

    const filterChanged = (event: any) => {
      const selected = selectOptions.value.find(s => s.value == event.detail.value);
      emits.emit('filterChanged', props.filterKey, props.filter, {'value': selected?.value}, selected?.title);
    }

      return { filterChanged,selectOptions,selectedItem }
    }
  });
