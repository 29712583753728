import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5cfacf3e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "tree-item"
}
const _hoisted_2 = {
  key: 0,
  class: "tree-item__content"
}
const _hoisted_3 = {
  key: 0,
  fill: "outline"
}
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = {
  key: 2,
  class: "tree-item__content__description",
  style: {"width":"200px","flex-grow":"0"}
}
const _hoisted_6 = {
  key: 3,
  class: "tree-item__content__description",
  style: {"width":"200px","flex-grow":"0"}
}
const _hoisted_7 = {
  key: 0,
  class: "quantity"
}
const _hoisted_8 = {
  key: 1,
  class: "amount"
}
const _hoisted_9 = {
  key: 2,
  class: "alt-amount"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_tree_item = _resolveComponent("tree-item", true)!

  return (_ctx.source && !_ctx.source.isEmpty())
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (!_ctx.source.isEmpty())
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_ion_button, {
                class: "tree-item__button",
                onClick: _ctx.source?.toggleExpanded,
                disabled: (_ctx.source?.children.length ?? 0) == 0
              }, {
                default: _withCtx(() => [
                  (_ctx.source?.expanded)
                    ? (_openBlock(), _createBlock(_component_ion_icon, {
                        key: 0,
                        icon: _ctx.chevronDownOutline,
                        size: "small"
                      }, null, 8, ["icon"]))
                    : _createCommentVNode("", true),
                  (!_ctx.source?.expanded)
                    ? (_openBlock(), _createBlock(_component_ion_icon, {
                        key: 1,
                        icon: _ctx.chevronForwardOutline,
                        size: "small"
                      }, null, 8, ["icon"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["onClick", "disabled"]),
              (!_ctx.descriptionVisible)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.descriptionVisible = !!_ctx.source?.description)),
                    class: _normalizeClass(["tree-item__content__title", { 'help-click': !!_ctx.source?.description }]),
                    style: {"flex-grow":"1","flex-shrink":"0"}
                  }, [
                    _createElementVNode("p", null, [
                      _createTextVNode(_toDisplayString(_ctx.source?.title) + " ", 1),
                      (_ctx.source?.description && !_ctx.descriptionVisible)
                        ? (_openBlock(), _createElementBlock("a", _hoisted_3, "ⓘ"))
                        : _createCommentVNode("", true)
                    ])
                  ], 2))
                : _createCommentVNode("", true),
              (_ctx.descriptionVisible)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.descriptionVisible=false)),
                    class: "tree-item__content__title help-click",
                    innerHTML: _ctx.source?.description
                  }, null, 8, _hoisted_4))
                : _createCommentVNode("", true),
              (_ctx.dimensionsVisible)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.source?.dimensions, (dimension) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: dimension.dimension,
                        style: {"width":"200px","display":"flex","justify-content":"space-between"}
                      }, [
                        _createElementVNode("div", null, _toDisplayString(dimension.dimension), 1),
                        _createTextVNode(),
                        _createElementVNode("div", null, _toDisplayString(dimension.totalEur.toFixed(2)), 1)
                      ]))
                    }), 128))
                  ]))
                : _createCommentVNode("", true),
              (!_ctx.dimensionsVisible && (_ctx.source?.dimensions.length ?? 0) > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    _createVNode(_component_ion_button, {
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.dimensionsVisible=!_ctx.dimensionsVisible))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Show more")
                      ]),
                      _: 1
                    })
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", {
                style: {"width":"100px","flex-grow":"0","text-align":"right"},
                onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.openDetails && _ctx.openDetails(...args)))
              }, [
                (_ctx.quantity)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(_ctx.quantity) + "  " + _toDisplayString(_ctx.source?.unit ? _ctx.source!.unit : 'x'), 1))
                  : _createCommentVNode("", true),
                (_ctx.quantity)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString(_ctx.amount), 1))
                  : _createCommentVNode("", true),
                (_ctx.quantity)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_9, _toDisplayString(_ctx.altAmount), 1))
                  : _createCommentVNode("", true)
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.source?.expanded)
          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.source?.children, (subItem) => {
              return (_openBlock(), _createElementBlock("div", {
                key: subItem.id,
                class: "tree-item__children"
              }, [
                _createVNode(_component_tree_item, {
                  source: subItem,
                  onOpenDetails: _ctx.forwardOpenDetails
                }, null, 8, ["source", "onOpenDetails"])
              ]))
            }), 128))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}