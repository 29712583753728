import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_button = _resolveComponent("ion-button")!

  return (_ctx.CTA)
    ? (_openBlock(), _createBlock(_component_ion_button, {
        key: 0,
        color: _ctx.color,
        onClick: _ctx.activateAction
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.CTA?.btnText), 1)
        ]),
        _: 1
      }, 8, ["color", "onClick"]))
    : _createCommentVNode("", true)
}