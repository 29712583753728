
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonTitle,
  IonToolbar,
  IonInput,
  IonList,
  IonItem,
  IonText,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/vue";
import { defineComponent, ref, Ref } from "vue";
import { useRouter } from "vue-router";
import { logInOutline } from "ionicons/icons";
import { useErrorBox } from "@/components/errorBox";
import { useI18n } from "vue-i18n";
import { MessageSchema, NumberSchema } from "@/main";

export default defineComponent({
  name: "ResetPassword",
  components: {
    IonBackButton,
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonList,
    IonIcon,
    IonItem,
    IonPage,
    IonTitle,
    IonToolbar,
    IonInput,
    IonText,
    IonGrid,
    IonRow,
    IonCol,
  },
  setup() {
    const router = useRouter();
    const { showError } = useErrorBox();
    const processing: Ref<boolean> = ref(false);
    const email: Ref<string> = ref("");

    const { t, n } = useI18n<{ message: MessageSchema; number: NumberSchema }>({
      useScope: "global",
    });

    const resetNow = (emailStr: string) => {
      processing.value = true;
      if (emailStr.length < 4) {
        showError(t("resetPasswd.emptyErr"), t("global.Error"));
        processing.value = false;
        return;
      }

      // store
      //   .dispatch(ActionTypes.REQUEST_PASSWORD_RESET_TOKEN, emailStr)
      //   .then(() => {
      //     showError(t("resetPasswd.okMsg"), t("global.Note"));
      //   })
      //   .catch((err) => {
      //     showError(err, t("global.Error"));
      //   })
      //   .finally(() => {
      //     processing.value = false;
      //   });
    };

    return {
      router,
      resetNow,
      logInOutline,
      email,
      processing,
      t,
      n,
    };
  },
});
