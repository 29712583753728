
import { computed, defineComponent, ref } from 'vue';
import { IonButton, IonIcon, IonImg } from "@ionic/vue";
import { closeOutline, arrowForwardOutline, saveOutline, } from "ionicons/icons";

export default defineComponent({
  props: {
    photos: {
      type: Array<string>, 
    },
    selectedIndex: {
      type: Number,
      default: 0,
    }
  },
  emits: ["close"],
  components: {
    IonButton,
    IonImg,
    IonIcon,
  },
  name: "PhotoGallery",
  setup(props, emit) {

    const currentIndex = ref(props.selectedIndex ?? 0);

    const photoUrl = computed(() => {
    if(!props.photos || props.photos.length < currentIndex.value + 1) 
      return "/assets/images/icons/20/Package.svg";

      return props.photos[currentIndex.value];
    });

    const nextPhoto = () => {
      if(!props.photos || props.photos.length == 0)
        return;

      currentIndex.value++;
      if (currentIndex.value >= props.photos.length) {
        currentIndex.value = 0;
      }
    }

    const nextAvailable = computed(() => {
      return props.photos && props.photos.length > 1;
    });

    const close = () => {
      emit.emit("close");
    }

    const savePhoto = (url: string) => {
      // open in new tab
      window.open(url, "_blank");
    }

    return {
      photoUrl, nextPhoto, close, nextAvailable,
      closeOutline, arrowForwardOutline, savePhoto, saveOutline,
    }
  }
});

