import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_radio = _resolveComponent("ion-radio")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_radio_group = _resolveComponent("ion-radio-group")!

  return (_openBlock(), _createBlock(_component_ion_radio_group, {
    allowEmptySelection: true,
    onIonChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.filterChanged($event))),
    modelValue: _ctx.selectedItem,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedItem) = $event))
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectOptions, (c) => {
        return (_openBlock(), _createBlock(_component_ion_item, {
          key: c.title
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_radio, {
              value: c.value
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(c.title), 1)
              ]),
              _: 2
            }, 1032, ["value"])
          ]),
          _: 2
        }, 1024))
      }), 128))
    ]),
    _: 1
  }, 8, ["modelValue"]))
}