
import {IonSplitPane, IonApp, IonRouterOutlet } from '@ionic/vue';
import MenuComponent from "@/views/MenuComponent.vue";
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import { useAuthStore } from './store/authStore';

export default defineComponent({
  name: 'App',
  components: { 
    IonSplitPane, 
    IonApp,
    IonRouterOutlet, 
    MenuComponent,
  },
  setup() {
    const router = useRouter();

    const isAuthNeeded = () => {
      return !!router.currentRoute.value.meta.requiresAuth;
    }

    const loginWithRedirect = () => {
      const auth = useAuthStore();
      const currentDomain = location.protocol + "//" + location.hostname + (location.port ? ":" + location.port : "");
      const currentPath = location.pathname; // + location.search + location.hash;      
      const url = auth.myOGO1Domain + "/OAuth/Authorize?response_type=token&redirect_uri=" + currentDomain + "/auth/return&client_id=myOgo2&redir=" + currentPath;

      window.location.replace(url);
    }


    const visibilityChanged = async() => {
      if (document.hidden)
        return;
              
      const auth = useAuthStore();
      const a = await auth.checkAuth();
      if(a) {
        return;
      }

      if(!isAuthNeeded()){
        return;
      }
      
      loginWithRedirect();      
    }

    document.addEventListener("visibilitychange",visibilityChanged);
    return {};
  },
});

