export class serviceDescription{
    endpoint = "";
    name = "";
    value = "";
constructor(endpoint:string, name:string, value:string)
{
    this.endpoint = endpoint;
    this.name = name;
    this.value = value;
}
}
