
import { IonPage, IonContent, IonButton, } from '@ionic/vue';
import PageHeader from "@/components/PageHeader.vue";
import PageFooter from "@/components/PageFooter.vue";
import AdminMenu from "@/views/Admin/AdminMenu.vue";

import { computed, defineComponent, onMounted, ref, Ref} from 'vue';

import PageDescription from '@/components/PageDescription.vue';
import { useAuthStore } from '@/store/authStore';
import { useErrorBox } from "@/components/errorBox";
import { ActionTypes, useStore } from '@/store';
import { WarehouseDto } from '@/models/warehouseService/Warehouse';
import { useRouter } from 'vue-router';
import { useConfigStore } from '@/store/configStore';
import { ProductListItemDto } from '@/models/configService/WarehouseProduct';
import { InvoicingCategoryDto } from '@/models/umbraco/invoicingCategory';
import { SingleResource } from '@/models/configService/Resource';

export default defineComponent({
  name: 'WarehouseProductList',
  components: { 
    PageHeader,
    PageFooter,
    PageDescription,
    IonPage, IonContent, IonButton,
    AdminMenu,
  },
  setup () {

    const store = useStore();
    const auth = useAuthStore();
    const router = useRouter()
    const configStore = useConfigStore();

    const warehouseProductList:Ref<Array<ProductListItemDto>> = ref([]);
    const warehouses: Ref<Array<WarehouseDto>> = ref([]);
    const invoicingCategories: Ref<Array<InvoicingCategoryDto>> = ref([]);
    const resources: Ref<Array<SingleResource>> = ref([]);

    const { showError } = useErrorBox();

    onMounted(async () => {
      try {
     
        if(!auth.filteredRoles.includes("Admins")){
            await showError("Missing admins role", "Missing permission");
            return;
        }

        // Fetch all data parallel
        const results = await Promise.all([
          configStore.getAllWarehouseProducts(),
          store.dispatch(ActionTypes.WS_GET_WAREHOUSES, undefined),
          configStore.getInvoicingCategories(),
          configStore.getResources("InternalProducts"),
        ]);

        // Save data to refs
        warehouseProductList.value = results[0];
        warehouses.value = results[1];
        invoicingCategories.value = results[2];
        resources.value = results[3];

      } catch (error) {
        await showError(error, "Error loading data");
      }
    });

    const sortedList = computed(() => {
      return [...warehouseProductList.value].sort((a,b) => (getCategoryName(a) + '  ' + a.code).localeCompare(getCategoryName(b)+'  '+b.code));
    });

    const getCategoryName = (wp: ProductListItemDto) => {
      if(!wp.categoryId)
        return wp.costCategory ?? "?";

      return invoicingCategories.value.find(i => i.ogoId == wp.categoryId)?.fullName ?? "-" 
    }

    const getTitle = (wp: ProductListItemDto) => {
      return resources.value.find(i => i.key == wp.code)?.resourceValues.find(i=>i.locale === "")?.value ?? "";
    }

    return {
        warehouseProductList, router, warehouses, invoicingCategories,
        getCategoryName, sortedList, getTitle,
    }
  }
});

