
import { computed, defineComponent,Ref,type PropType, ref } from 'vue';
import { IonButton, IonItem, IonContent, IonIcon, IonModal, IonTitle, IonToolbar, createAnimation, } from "@ionic/vue";
import { filterOutline, } from "ionicons/icons";
import DateRangeSelect from "@/views/Table/DateRangeSelect.vue";
import RadioSelect from "@/views/Table/RadioSelect.vue";
import TextSearch from "@/views/Table/TextSearch.vue";
import { ColumnDefinition } from '@/models/orderService/ReturnsListFilter';

  export default defineComponent({
    name: "TableHead",
    props: {
      title: { type: String, required: true },
      filterKey:  { type: String, required: true },
      filter: { type: Object as PropType<ColumnDefinition>, required: true },
    },
    emits: {
      filterChanged: (_filterKey: string, _filter:ColumnDefinition, _value: any, _text: any) => true,
      sortChanged: (_filterKey: string, _filter:ColumnDefinition, _sort: boolean, _sortDesc: boolean) => true,
    },
    components: {
      IonButton,
      DateRangeSelect,
      RadioSelect,
      TextSearch,
      IonToolbar,
      IonItem,
      IonContent,
      IonIcon,
      IonModal,
      IonTitle,
    },
    setup (props, emits){

      const isOpen: Ref<boolean> = ref(false);
      const setOpen = (open: boolean) => {
        isOpen.value = open;

        if(!open)
          return;
      }
      const filterChanged = (_filterKey: string, _filter:ColumnDefinition, filterValue: any, filterTitle: string) => {
        if(!props.filterKey || !props.filter)
          return;
        emits.emit('filterChanged', props.filterKey, props.filter, filterValue, filterTitle);
      }

      const selectColor = () => {
        if(props.filter.inlineSearchSelectObject)
          return 'tertiary';
        return 'light';
      }

      const ascSortActive = computed(() => {
        return !props.filter.sortDesc;
      });

      const isActive = computed(() => {
        return props.filter.sortOrder !== undefined;
      });

      const ascSort = () => {
        emits.emit('sortChanged', props.filterKey, props.filter, true, false);
      }

      const descSort = () => {
        emits.emit('sortChanged', props.filterKey, props.filter, true, true);
      }

      const removeSort = () => {
        emits.emit('sortChanged', props.filterKey, props.filter, false, false);
      }

      const sortClick = () => {
        if(!props.filterKey || !props.filter)
          return;
        if(props.filter.sortOrder === undefined)
          emits.emit('sortChanged', props.filterKey, props.filter, true, false);
        else if(!props.filter.sortDesc)
          emits.emit('sortChanged', props.filterKey, props.filter, true, true);
        else        
          emits.emit('sortChanged', props.filterKey, props.filter, false, false);
      }

      const enterAnimation = (baseEl: HTMLElement) => {
        const root = baseEl.shadowRoot || baseEl; 
  
        const backdropAnimation = createAnimation()
          .addElement(root.querySelector('ion-backdrop')!)
          .fromTo('opacity', '0.01', 'var(--backdrop-opacity)');

        const wrapperAnimation = createAnimation()
          .addElement(root!.querySelector('.modal-wrapper')!)
          .keyframes([
            { offset: 0, opacity: '0', transform: 'scale(0)' },
            { offset: 1, opacity: '0.99', transform: 'scale(1)' },
          ]);

        return createAnimation()
          .addElement(baseEl)
          .easing('ease-out')
          .duration(300)
          .addAnimation([backdropAnimation, wrapperAnimation]);
      };

      const leaveAnimation = (baseEl: HTMLElement) => {
        return enterAnimation(baseEl).direction('reverse');
      };

      return { 
        filterChanged,
        filterOutline,
        selectColor,
        sortClick,
        isActive,

        ascSort,
        descSort,

        removeSort,
        isOpen,
        setOpen,
        ascSortActive,

        leaveAnimation,
        enterAnimation,
      }


    }
  });
