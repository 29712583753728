import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_toggle = _resolveComponent("ion-toggle")!
  const _component_ion_datetime = _resolveComponent("ion-datetime")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!

  return (_openBlock(), _createBlock(_component_ion_grid, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_col, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_toggle, {
                "label-placement": "end",
                modelValue: _ctx.fromFilterEnabled,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.fromFilterEnabled) = $event)),
                onIonChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.filterChanged($event)))
              }, {
                default: _withCtx(() => [
                  _createTextVNode("From date")
                ]),
                _: 1
              }, 8, ["modelValue"]),
              (_ctx.fromFilterEnabled)
                ? (_openBlock(), _createBlock(_component_ion_datetime, {
                    key: 0,
                    "display-format": "DD.MM.YYYY",
                    presentation: "date",
                    onIonChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.filterChanged($event))),
                    modelValue: _ctx.fromFilter,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.fromFilter) = $event))
                  }, null, 8, ["modelValue"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createVNode(_component_ion_col, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_toggle, {
                "label-placement": "end",
                modelValue: _ctx.toFilterEnabled,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.toFilterEnabled) = $event)),
                onIonChange: _cache[5] || (_cache[5] = ($event: any) => (_ctx.filterChanged($event)))
              }, {
                default: _withCtx(() => [
                  _createTextVNode("To date")
                ]),
                _: 1
              }, 8, ["modelValue"]),
              (_ctx.toFilterEnabled)
                ? (_openBlock(), _createBlock(_component_ion_datetime, {
                    key: 0,
                    "display-format": "DD.MM.YYYY",
                    presentation: "date",
                    onIonChange: _cache[6] || (_cache[6] = ($event: any) => (_ctx.filterChanged($event))),
                    modelValue: _ctx.toFilter,
                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.toFilter) = $event))
                  }, null, 8, ["modelValue"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}