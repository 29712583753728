export class Campaign {
    name: string;
    usageKey: string;
    description: string;
    onEvent: string;
    questions: Question[];
    id: string;
    key: string;
    docVersion: number;
    revision: number;
    channelId: number;
    merchantId: string;
    url: string;
    defaults: boolean;

    constructor(){
        this.name = "";
        this.usageKey = "";
        this.description = "";
        this.onEvent = "";
        this.questions = [];
        this.id = "";
        this.key = "";
        this.docVersion = 0;
        this.revision = 0;
        this.channelId = 0;
        this.merchantId = "";
        this.url = "";
        this.defaults = false;
    }
}

export class Translation {
    lang: string;
    value: string;
    constructor(){
        this.lang = "";
        this.value = "";
    }
}

export class Question {
    translations: Translation[];
    children: Question[];
    answers: Answer[];
    id: string;
    key: string;
    minimumRating: number;
    maximumRating: number;
    constructor(){
        this.translations = [];
        this.children = [];
        this.answers = [];
        this.id = "";
        this.key = "";
        this.minimumRating = 0;
        this.maximumRating = 0;
    }
}

export class Answer {
    translations: Translation[];
    //children: any[];
    id: string;
    key: string;
    minimumRating: number;
    maximumRating: number;
    constructor(){
        this.translations = [];
        //this.children = ;
        this.id = "";
        this.key = "";
        this.minimumRating = 0;
        this.maximumRating = 0;

    }
}

export class FeedbackDto {
    id: number;
    customer: CustomerDto;
    campaignId: string;
    merchantId: string;
    channelId: number;
    orderId: string;
    inviteId: string;
    docVersion: number;
    rating: number;
    lang: string;
    createdAt: Date;
    createdBy: string;
    comment: string;
    answers: AnswerDto[];
    extraId: string|undefined;
    constructor(){
        this.id = 0;
        this.customer = new CustomerDto();
        this.campaignId = "";
        this.merchantId = "";
        this.channelId = 0;
        this.orderId = "";
        this.inviteId = "";
        this.docVersion = 0;
        this.rating = 0;
        this.lang = "";
        this.createdAt = new Date();
        this.createdBy = "";
        this.comment = "";
        this.answers = [];
    }
}

export class AnswerDto {
    questionId: string;
    answerId: string;
    content: string;
    constructor(){
        this.questionId = "";
        this.answerId = "";
        this.content = "";
    }
}

export class CampaignWithFeedbackView {
    campaign?: CampaignViewDto;
    feedback?: FeedbackDto;
}

export class CampaignViewDto {
    id?: string;
    key?: string;
    docVersion?: number;
    revision?: number;
    channelId?: number;
    merchantId?: string;
    url?: string;
    defaults?: boolean;
    questions?: QuestionNodeViewDto[];
}

export class QuestionNodeViewDto {
    id?: string;
    key?: string | undefined;
    minimumRating = 0;
    maximumRating = 5;    
    content = "";
    answers: AnswerNodeViewDto[] = [];
    children: QuestionNodeViewDto[] = [];
}

export class AnswerNodeViewDto {
    id?: string;
    key?: string | undefined;
    minimumRating = 0;
    maximumRating = 5;
    content = "";
    children: QuestionNodeViewDto[] = [];
}

export class CustomerDto {
    email: string;
    phone: string;
    name: string;
    company: string;
    address1: string;
    address2: string;
    city: string;
    countryCode: string;
    zip: string;
    constructor(){
        this.email = "";
        this.phone = "";
        this.name = "";
        this.company = "";
        this.address1 = "";
        this.address2 = "";
        this.city = "";
        this.countryCode = "";
        this.zip = "";
    }
}