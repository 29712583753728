
import { defineComponent, Ref, ref, type PropType, onMounted } from 'vue';
import { IonDatetime, IonToggle, IonGrid, IonRow, IonCol, } from "@ionic/vue";
import { filterOutline } from "ionicons/icons";
import { ColumnDefinition } from '@/models/orderService/ReturnsListFilter';

  export default defineComponent({
    name: "DateRangeSelect",
    props: {
      title: { type: String, required: true },
      filterKey:  { type: String, required: true },
      filter: { type: Object as PropType<ColumnDefinition>, required: true },
    },
    emits: {
      filterChanged: (_event: any, _filter: any, _value: any, _text: any) => true,
    },
    components: {
      IonDatetime,
      IonToggle,
      IonGrid, IonRow, IonCol,
    },
    setup (props, {emit}){

      onMounted (() => {
        if((props.filter.inlineSearchSelectObject as any)?.From) {
          fromFilter.value = (props.filter.inlineSearchSelectObject as any).From.toISOString();
          fromFilterEnabled.value = true;
        } else {
          fromFilterEnabled.value = false;        
        }

        if((props.filter.inlineSearchSelectObject as any)?.To) {
          toFilter.value = (props.filter.inlineSearchSelectObject as any).To.toISOString();
          toFilterEnabled.value = true;
        } else {
          toFilterEnabled.value = false;        
        }
    });

    const fromFilter: Ref<string> = ref(new Date().toISOString());
    const toFilter: Ref<string> = ref(new Date().toISOString());

    const fromFilterEnabled: Ref<boolean> = ref(false);
    const toFilterEnabled: Ref<boolean> = ref(false);

    const filterChanged = (_event: any) => {

      const fromDate = new Date(fromFilter.value);
      const toDate = new Date(toFilter.value);

      const f = {
        'From': fromFilterEnabled.value ? fromDate : null,
        'To': toFilterEnabled.value ? toDate : null
        }

      let t = "";
      if(fromFilterEnabled.value) t += "From: " + fromDate.toLocaleDateString();
      if(toFilterEnabled.value) t += " To: " + toDate.toLocaleDateString();

      emit('filterChanged', props.filterKey, props.filter, f, t);
    }

    return { filterChanged,fromFilter, toFilter, fromFilterEnabled, toFilterEnabled,filterOutline,}
    }
  });
