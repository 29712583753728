
import { IonPage,IonTitle, IonContent, IonCard, 
IonCardHeader, IonCardTitle, IonCardContent, 
IonSelect,IonSelectOption, } from '@ionic/vue';
import PageHeader from "@/components/PageHeader.vue";
import PageFooter from "@/components/PageFooter.vue";

import ReturnMenu from "@/views/Return/ReturnMenu.vue";


import { defineComponent, onMounted, ref, Ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import PageDescription from '@/components/PageDescription.vue';
import { ReturnDeliveryTypeDto } from '@/models/orderService/DeliveryTypes';
import { useAuthStore } from '@/store/authStore';
import { useOrderStore } from '@/store/orderStore';

export class ReturnDeliveryTypeViewModel
{
    id: string;
    code: string;
    logo: string;
    title: string;
    returnInstructions: string;
    availableCountries: Array<string>;
    indexNumber: number;

    constructor(dto: ReturnDeliveryTypeDto, locale: string){
        this.id = dto.id ?? "";
        this.code = dto.code;
        this.logo = dto.logo;
        this.title = dto.title.find(l=>l.lang === locale)?.text ?? 
                    dto.title.find(l=>l.lang === "en")?.text ?? 
                    "";
        this.returnInstructions = dto.returnInstructions.find(l=>l.lang === locale)?.text ?? 
                    dto.returnInstructions.find(l=>l.lang === "en")?.text ?? 
                    "";
        this.indexNumber = dto.indexNumber;
        this.availableCountries = dto.availableCountries
        .map(c=>c.countryCode)
        .sort((i,j)=>{
        if (i < j) { return -1; }
        if (i > j) { return 1; }
        return 0;      
      });
    }
}

export default defineComponent({
  name: 'ReturnDeliveryTypes',
  components: { 
    PageHeader,
    PageFooter,
    // IonGrid,
    // IonRow,
    // IonCol, 
    IonPage, 
    IonContent,
    // IonList,
    // IonItem,
    // IonToggle,
    IonTitle,
    IonSelect,
    IonSelectOption,
    // IonImg,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
    // IonText,

    ReturnMenu,
    PageDescription,
  },
  data() {
    return {
      chartOptions: {
        responsive: true
      }
    }
  },
  setup (){
    const router = useRouter();
    const auth = useAuthStore();
    const orderStore = useOrderStore();

    const locale: Ref<string> = ref("en");
    const localeList: Array<string> = ["en","sv", "fi","de", "da", "no"];

    const returnReasons: Ref<Array<ReturnDeliveryTypeViewModel>> = ref([]);

    const updateReturnDeliveryTypes = async (dto: ReturnDeliveryTypeDto[]) => {
      returnReasons.value = dto.map((val)=> {
        return new ReturnDeliveryTypeViewModel(val, locale.value);
      }).sort((i,j)=>{
        if(i.indexNumber !== j.indexNumber)
          return i.indexNumber - j.indexNumber;

        if (i.title < j.title) { return -1; }
        if (i.title > j.title) { return 1; }
        return 0;      
      });
    }

    onMounted(async () => {
      if(!auth.merchantId || !auth.channelId)
        return;

      const a = await orderStore.getReturnDeliveryTypes();
      await updateReturnDeliveryTypes(a);

      //console.log("returnReasons",returnReasons.value);
    });

    watch(locale, async (_newVal, _oldVal) => {
      const a = await orderStore.getReturnDeliveryTypes();
      await updateReturnDeliveryTypes(a);
    });


  return {
    returnReasons,
    router,
    locale,
    localeList,
  }
}
});

