
import {  IonPage, IonContent, IonGrid,IonRow,IonCol,IonButton, IonChip, InfiniteScrollCustomEvent,  
  IonInfiniteScroll, IonInfiniteScrollContent,IonIcon,
  IonList, IonItem, IonLabel,
} from '@ionic/vue';
import PageHeader from "@/components/PageHeader.vue";
import PageFooter from "@/components/PageFooter.vue";
import AdminMenu from "@/views/Admin/AdminMenu.vue";
import TableHead from "@/views/Table/TableHead.vue";

import { computed, defineComponent, onMounted, ref, Ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import PageDescription from '@/components/PageDescription.vue';
import { ColumnDefinition, ListDefinitions, SearchDefinition } from '@/models/orderService/ReturnsListFilter';

import { filterOutline, closeOutline, eyeOutline, } from "ionicons/icons";
import { useAuthStore } from '@/store/authStore';
import { useMerchantStore } from '@/store/merchantStore';
import { RebuyLevel } from '@/models/merchantService/Merchant';

export class ActiveFilter {
  filterId: string;
  filterTitle: string;
  selectObject: any;
  selectTitle: string;

  selectText(){
    return this.selectTitle;
  }

  constructor(filterId: string, filterTitle: string, selectObject: any, selectTitle: string) {
  this.filterId = filterId;
  this.filterTitle = filterTitle;
  this.selectObject = selectObject;
  this.selectTitle = selectTitle;
}
}


export default defineComponent({
  name: 'ChannelList',
  components: { 
    // IonSplitPane,
    PageHeader,
    PageFooter,
    IonGrid,
    IonRow,
    IonCol, 
    IonPage, 
    IonContent,
    IonButton,
    IonChip,    
    IonList,
    IonItem,
    IonIcon,
    IonLabel,

    // MenuComponent,
    AdminMenu,
    PageDescription,
    TableHead,
    IonInfiniteScroll, IonInfiniteScrollContent,
  },
  data() {
    return {
      chartOptions: {
        responsive: true
      }
    }
  },
  setup (){
    const merchantStore = useMerchantStore();
    const router = useRouter();
    const auth = useAuthStore();

    const channelListDefinitions: Ref<ListDefinitions|undefined> = ref(undefined);

    const nextLine:  Ref<number> = ref(0);
    const listResult: Ref<Array<any>> = ref([]);      
    const loadMoreVisible: Ref<boolean> = ref(false);

    onMounted(async () => {
      if(!auth.merchantId || !auth.channelId)
        return;

      // Load definitions
      channelListDefinitions.value = await merchantStore.getChannelDefinitions();
    });

    const activeFilters = computed(() => {
      if(!channelListDefinitions.value)
        return [];

      return Object.entries(channelListDefinitions.value as object)
        .filter(i=>(i[1]).inlineSearchSelectObject)
        .map (i => {
          return new ActiveFilter(i[0], i[0], i[1].inlineSearchSelectObject, i[1].inlineSearchSelectTitle);
        });
    });

    const filterChanged = (filterKey: string, _filter:ColumnDefinition, filterValue: any, filterTitle: string) => {
      if(channelListDefinitions.value) {
        if(filterValue === undefined || filterValue === null || filterValue === "" || filterTitle === undefined || filterTitle === "") {
          channelListDefinitions.value[filterKey].inlineSearchSelectObject = undefined;
          channelListDefinitions.value[filterKey].inlineSearchSelectTitle = undefined;
        } else {
        channelListDefinitions.value[filterKey].inlineSearchSelectObject = filterValue;
        channelListDefinitions.value[filterKey].inlineSearchSelectTitle = filterTitle;
        }
      }  
    }

    const sortChanged = (filterKey: string, _filter:ColumnDefinition, sort: boolean, sortDesc: boolean) => {
      // Reset old sort order
      Object.entries(channelListDefinitions.value as object).forEach(e => {
        if(e[0] !== filterKey) {
          e[1].sortOrder = undefined;
        }
      });
      
      // Update new sort order
      if(channelListDefinitions.value) {
        channelListDefinitions.value[filterKey].sortOrder = sort ? 1 : undefined;
        channelListDefinitions.value[filterKey].sortDesc = sortDesc;
      }
    }

    // Remove filter
    const removeFilter = (_event: CustomEvent, filter: ActiveFilter) => {
      if(channelListDefinitions.value) {
        channelListDefinitions.value[filter.filterId].inlineSearchSelectObject = undefined;
        channelListDefinitions.value[filter.filterId].inlineSearchSelectTitle = undefined;
      }
    }

    watch(channelListDefinitions, async (_newValue, _oldValue) => {
      // reset and reload
      listResult.value = [];
      nextLine.value = 0;
      await loadData(50);
    },{deep: true});


    /// Load data to list
    const loadData = async (take: number) => {
      const search = new SearchDefinition();
      search.limit.skip = nextLine.value;
      search.limit.take = take;

      Object.entries(channelListDefinitions.value as object).forEach(e => {
        const key = e[0] as string;
        const f = e[1] as ColumnDefinition;

        // Update new sort order
        if(f.sortOrder)
          search.sort[key] = !!f.sortDesc;

        // Update new filter
        if(f.inlineSearchSelectObject) {
          search.inlineSearch[key] = f.inlineSearchSelectObject;
        }
      });

      try {
        loadMoreVisible.value = false;
        const r = await merchantStore.getChannels(search);
        r.forEach(row => { listResult.value.push(row); });
        nextLine.value += r.length;

        loadMoreVisible.value = r.length >= take;
      } catch(e) {
        // console.log(e);
        loadMoreVisible.value = false;
      }
    }

    const ionInfinite = async (ev: InfiniteScrollCustomEvent) => {
      await loadData(50);
      ev.target.complete();
    };

    const openMerchant = (merchantId : string) => {
          window.location.assign(auth.myOGO1Domain + `/merchant/edit/${merchantId}`);
        //  location.replace(auth.myOGO1Domain + `/merchant/edit/${merchantId}`);       
      }
      

  return {
    router,returnListDefinitions: channelListDefinitions,filterChanged,sortChanged,activeFilters,removeFilter,
    filterOutline,closeOutline,eyeOutline,listResult,loadData,ionInfinite,
    loadMoreVisible,RebuyLevel, openMerchant
  }
}
});

