import { Ref, ref } from "vue";
import { CostDimensionDto } from "./configService/InvoicingMetrics";

export class TreeItemSource {
    id: string;
    title: string;
    description: string|undefined;
    amount: number;
    altAmount: number;
    quantity: number;
    unit: string|undefined;

    expanded: Ref<boolean> = ref(false);
    children: Array<TreeItemSource>;
    dimensions: Array<CostDimensionDto> = [];
        
        getTotalAmount(): number {
            let total = this.amount;
            if(this.children) {
                this.children.forEach(child => {
                    total += child.getTotalAmount();
                });
            }
            return total;
        }

        getTotalAltAmount(): number {
            let total = this.altAmount;
            if(this.children) {
                this.children.forEach(child => {
                    total += child.getTotalAltAmount();
                });
            }
            return total;
        }

        getTotalQuantity(): number {
            let total = this.quantity;
            if(this.children) {
                this.children.forEach(child => {
                    total += child.getTotalQuantity();
                });
            }
            return total;
        }

        isEmpty = (): boolean => {
            return  !this.getTotalAltAmount() && 
                    !this.getTotalAmount() && 
                    !this.getTotalQuantity();
        }

        getIdList(): string[] {
            const idList = [this.id];
            if(this.children) {
                this.children.forEach(child => {
                    idList.push(...child.getIdList());
                });
            }
            return idList;
        }

        // getTotalDimensions(): Array<CostDimensionDto> {
        //     let total = this.dimensions;
        //     if(this.children) {
        //         this.children.forEach(child => {
        //             if(child.dimensions.
        //             total = total.concat(child.getTotalDimensions());
        //         });
        //     }
        //     return total;
        // }

        toggleExpanded = ()=>  {
            // debugger;
            this.expanded.value = !this.expanded.value;
            // alert("expanded: " + this.expanded.value);
        }

        constructor(id: string, title: string, description: string|undefined, amount: number, children: Array<TreeItemSource>){
            this.id = id;
            this.title = title;
            this.description = description;
            this.amount = amount;
            this.altAmount = 0;
            this.children = children;
            this.quantity = 0;
        }

}