import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3676dfc2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "page-layout" }
const _hoisted_2 = { class: "inner-panel" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_header = _resolveComponent("page-header")!
  const _component_PageDescription = _resolveComponent("PageDescription")!
  const _component_AdminMenu = _resolveComponent("AdminMenu")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_badge = _resolveComponent("ion-badge")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_page_footer = _resolveComponent("page-footer")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_page_header, { pageName: "Return deliverytype list" }),
      _createVNode(_component_ion_content, { fullscreen: true }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            false
              ? (_openBlock(), _createBlock(_component_PageDescription, {
                  key: 0,
                  pageName: "Return delivery type list",
                  pageDescription: "List of return delivery types",
                  breadCrumbs: [{title: 'Admin', active: true, url: '/home'}]
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_AdminMenu),
            _createVNode(_component_ion_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_col, null, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_2, [
                      _createVNode(_component_ion_button, {
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.router.push({name: 'ReturnDeliveryTypeDetails', params: {id: 'create'}}))),
                        class: "ion-float-right"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("New")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_title, null, {
                        default: _withCtx(() => [
                          _createTextVNode("Return delivery types")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_list, { style: {"clear":"both"} }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_item, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => [
                                  _createTextVNode("Title")
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => [
                                  _createTextVNode("Available Countries")
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => [
                                  _createTextVNode("Channels")
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => [
                                  _createTextVNode("Visible")
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.allReturnDeliveryTypes, (dt) => {
                            return (_openBlock(), _createBlock(_component_ion_item, {
                              key: dt.id,
                              class: _normalizeClass({'removed': dt.isRemoved})
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_label, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(dt.title.find(t => t.lang === "en")?.text ?? dt.title.at(0)?.text ?? `Title missing (code: ${dt.code})`), 1)
                                  ]),
                                  _: 2
                                }, 1024),
                                (dt.availableCountries.length > 2)
                                  ? (_openBlock(), _createBlock(_component_ion_label, { key: 0 }, {
                                      default: _withCtx(() => [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(dt.availableCountries, (ac) => {
                                          return (_openBlock(), _createBlock(_component_ion_badge, {
                                            slot: "start",
                                            class: "fized-size",
                                            style: {"margin-right":"5px"},
                                            key: ac.countryCode
                                          }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(ac.countryCode), 1)
                                            ]),
                                            _: 2
                                          }, 1024))
                                        }), 128))
                                      ]),
                                      _: 2
                                    }, 1024))
                                  : _createCommentVNode("", true),
                                (dt.availableCountries.length <= 2)
                                  ? (_openBlock(), _createBlock(_component_ion_label, { key: 1 }, {
                                      default: _withCtx(() => [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(dt.availableCountries, (ac) => {
                                          return (_openBlock(), _createBlock(_component_ion_badge, {
                                            slot: "start",
                                            class: "fized-size",
                                            style: {"margin-right":"5px"},
                                            key: ac.countryCode
                                          }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(_ctx.dtCountryText(ac)), 1)
                                            ]),
                                            _: 2
                                          }, 1024))
                                        }), 128))
                                      ]),
                                      _: 2
                                    }, 1024))
                                  : _createCommentVNode("", true),
                                (dt.channelReferences.length == 0)
                                  ? (_openBlock(), _createBlock(_component_ion_label, { key: 2 }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString("All channels"))
                                      ]),
                                      _: 1
                                    }))
                                  : _createCommentVNode("", true),
                                (dt.channelReferences.length > 0)
                                  ? (_openBlock(), _createBlock(_component_ion_label, { key: 3 }, {
                                      default: _withCtx(() => [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(dt.channelReferences, (cr) => {
                                          return (_openBlock(), _createElementBlock("div", {
                                            key: cr.channelId
                                          }, _toDisplayString(`${cr.channelId} ${cr.name}`), 1))
                                        }), 128))
                                      ]),
                                      _: 2
                                    }, 1024))
                                  : _createCommentVNode("", true),
                                _createVNode(_component_ion_label, null, {
                                  default: _withCtx(() => [
                                    (dt.isVisible)
                                      ? (_openBlock(), _createBlock(_component_ion_icon, {
                                          key: 0,
                                          icon: _ctx.eyeOutline,
                                          size: "small"
                                        }, null, 8, ["icon"]))
                                      : _createCommentVNode("", true),
                                    (!dt.isVisible)
                                      ? (_openBlock(), _createBlock(_component_ion_icon, {
                                          key: 1,
                                          icon: _ctx.eyeOffOutline,
                                          size: "small"
                                        }, null, 8, ["icon"]))
                                      : _createCommentVNode("", true)
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_ion_button, {
                                  onClick: ($event: any) => (_ctx.router.push({name: 'ReturnDeliveryTypeDetails', params: {id: dt.id}}))
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode("Edit")
                                  ]),
                                  _: 2
                                }, 1032, ["onClick"])
                              ]),
                              _: 2
                            }, 1032, ["class"]))
                          }), 128))
                        ]),
                        _: 1
                      })
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_page_footer)
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}