
import { IonPage,IonTitle, IonContent, IonButton, IonIcon, } from '@ionic/vue';
import { trashOutline,pencilOutline, addOutline,} from 'ionicons/icons';
import PageHeader from "@/components/PageHeader.vue";
import PageFooter from "@/components/PageFooter.vue";

import ReturnMenu from "@/views/Return/ReturnMenu.vue";

import { defineComponent, onMounted, ref, Ref } from 'vue';
import { ActionTypes, useStore } from '@/store';
import { useRouter } from 'vue-router';
import PageDescription from '@/components/PageDescription.vue';
import { ReturnRuleDto } from '@/models/merchantService/Returns';
import { useErrorBox } from '@/components/errorBox';
import { ReturnDeliveryTypeViewModel } from './ReturnDeliveryTypes.vue';
import { WarehouseDto } from '@/models/warehouseService/Warehouse';
import { useAuthStore } from '@/store/authStore';
import { useOrderStore } from '@/store/orderStore';
import { useMerchantStore } from '@/store/merchantStore';
import { useOgoEvents } from '@/composables/useOgoEvents';

export default defineComponent({
  name: 'ReturnReasons',
  components: {
    PageHeader,
    PageFooter,
    IonPage, 
    IonContent,
    IonButton,
    IonTitle,
    IonIcon,
    ReturnMenu,
    PageDescription,
  },
  data() {
    return {
      chartOptions: {
        responsive: true
      }
    }
  },
  setup (){
    const store = useStore();
    const router = useRouter();
    const auth = useAuthStore();
    const orderStore = useOrderStore();
    const merchantStore = useMerchantStore();
    const { showError } = useErrorBox();
    
    const returnRules: Ref<Array<ReturnRuleDto>> = ref([]);

    const returnDeliveryTypes: Ref<Map<string,ReturnDeliveryTypeViewModel>|undefined> = ref(undefined);
    const warehouses: Ref<Map<string,WarehouseDto>|undefined> = ref(undefined);

    const initialize = async () => {
        // Validate merchant
        if(!auth.merchantId || !auth.channelId){
            return;
        }

        // Get data
        try {
            returnRules.value = await merchantStore.getMerchantReturnRules(auth.merchantId,auth.channelId);
            
            const dt = await orderStore.getReturnDeliveryTypes();
            returnDeliveryTypes.value = new Map( dt.map((val)=> {
                const vm = new ReturnDeliveryTypeViewModel(val, store.getters.locale ?? "en");
                return [vm.id, vm];        
            }));
            
            const wh = await store.dispatch(ActionTypes.WS_GET_WAREHOUSES,undefined);
            warehouses.value = new Map( wh.map((a)=> { return [a.id, a];}));
            
        } catch (error) {
            showError(error as string, "Error");
        }
        
    };
    onMounted( initialize );
    useOgoEvents({ returnRuleChanged: initialize});

    const editRule = async (rule: ReturnRuleDto) => {
      await router.push({name: "ReturnRuleDetails", params: {id: rule.id}});
    }
    const deleteRule = async (rule: ReturnRuleDto) => {
      await merchantStore.deleteMerchantReturnRule(auth.merchantId ?? "", auth.channelId ?? -1, rule.id);

      returnRules.value = await merchantStore.getMerchantReturnRules(auth.merchantId ?? "",auth.channelId ?? -1);
    }

    const newRule = async () => {
      if(returnDeliveryTypes.value && warehouses.value)
        await router.push({name: "ReturnRuleDetails", params: {id: undefined}});
    }

  return {
    returnRules, router,returnDeliveryTypes,warehouses,editRule,newRule,deleteRule,
    trashOutline,pencilOutline,addOutline,
  }
}
});

