
import { IonGrid, IonRow, IonCol, IonItem, IonLabel, IonButton, IonIcon, IonButtons, } from '@ionic/vue';
import { defineComponent, onMounted, ref, Ref, } from 'vue';
import { AuthBrowserDto } from '@/models/merchantService/UsersModels';
import { useErrorBox } from '@/components/errorBox';
import { useUsersStore } from '@/store/usersStore';
import { trashOutline } from 'ionicons/icons';

import moment from 'moment-timezone';

export default defineComponent({
  name: 'AuthenticatedBrowsersList',
  components: {
    IonGrid,
    IonRow,
    IonCol, 
    IonItem,
    IonLabel,
    IonButtons,
    IonButton,
    IonIcon,
  },
  setup (){
    const userStore = useUsersStore();
    const { showError } = useErrorBox();

    const momentjs = (a: any): moment.Moment => { return moment(a); }

    const browsers: Ref<Array<AuthBrowserDto>> = ref([]);

    const initialize = async ()=> {
      try {
        browsers.value = await userStore.getBrowsers();
      } catch (err) {
        await showError(err, "Error"); 
      }
    }

    onMounted(async () => {
      await initialize();
    });


    const revoke = async( browser: AuthBrowserDto) => {
      try {
        await userStore.revokeBrowser(browser.id);
        await initialize();
      } catch (err) {
        await showError(err, "Error"); 
      }
    }


  return { browsers, initialize, revoke, momentjs, trashOutline,};
}
});

