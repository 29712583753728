

import {
    IonSelect, IonSelectOption, IonGrid, IonRow, IonCol, IonItem, IonToggle, IonInput, toastController
} from '@ionic/vue';
import { computed, defineComponent, onMounted, ref, Ref, } from 'vue';
import { useAuthStore } from '@/store/authStore';
import { useMerchantStore } from '@/store/merchantStore';
import { ChannelUIDto, LanguageSettings, Theme } from '@/models/merchantService/CustomerPortalSettings';
import { useErrorBox } from '@/components/errorBox';
import { stringToHash } from '@/utils/changeTracking';

export default defineComponent({
    name: 'CustomerPortalSettings',
    components: {
        IonItem,
        IonGrid,
        IonRow,
        IonCol,
        // IonLabel,
        IonInput,
        IonToggle,
        IonSelect,
        // IonButton,
        IonSelectOption,
    },
    setup(props,  {expose} ) {
        const auth = useAuthStore();
        const merchantStore = useMerchantStore();
        const settings: Ref<ChannelUIDto | undefined> = ref(undefined);
        const fontOptions = ["Usual", "WorkSans", "Roboto"];
        const buttonVariants = ["default","outlined", "tonal", "text", "plain"];

        const { showError } = useErrorBox();


        const isAdmin = computed(() => { return auth.filteredRoles.includes("Admins"); });

        onMounted(async () => {
            if (!auth.merchantId || !auth.channelId)
                return;

            settings.value = await merchantStore.getCustomerPortalSettings(auth.channelId);

            if (!settings.value.whiteLabelling)
                settings.value.whiteLabelling = new Theme();

            if (!settings.value.whiteLabelling.languageSettings)
                settings.value.whiteLabelling.languageSettings = new LanguageSettings();

            if (!settings.value.whiteLabelling.logoMargin)
                settings.value.whiteLabelling.logoMargin = 40;

            savedHash.value = hashComponentState.value;

        });

        const save = async () => {
            if (!auth.merchantId || !auth.channelId || !settings.value || !settings.value.whiteLabelling)
                return;

            try {

                // Check that one of the languages is enabled.
                if (settings.value.whiteLabelling.languageSettings?.en == false
                    && settings.value.whiteLabelling.languageSettings?.fi == false
                    && settings.value.whiteLabelling.languageSettings?.sv == false
                    && settings.value.whiteLabelling.languageSettings?.de == false)
                    throw ("One of the languages must be enabled");

                settings.value = await merchantStore.updateCustomerPortalSettings(settings.value.uid, settings.value.whiteLabelling);
                savedHash.value = hashComponentState.value;

                const toast = await toastController.create({
                    message: "Settings saved successfully!",
                    duration: 1500,
                    position: "top",
                    color: "primary",
                });

                await toast.present();

            } catch (e) {
                showError(e as string, "Error saving settings!");
            }
        }

        //#region isDirty check for relative simple components
        const savedHash: Ref<number> = ref(0);
        const hashComponentState = computed(() => {
            return stringToHash(JSON.stringify({
                settings: settings.value,
            }));
        });

        const isDirty = computed(() => {
            if(savedHash.value === 0)
                return false;            
            return savedHash.value !== hashComponentState.value;
        });
        //#endregion

        expose({ isDirty, save });

        return { isDirty, settings, fontOptions, save, buttonVariants, isAdmin, }
    }
});

