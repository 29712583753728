

import { useAuthStore } from "@/store/authStore";
import { IonGrid, IonRow, IonCol, IonText } from "@ionic/vue";
import { peopleOutline } from "ionicons/icons";


export class Link
{
  title: string;
  active: boolean;
  url: string;
  role?: string|undefined;

  constructor(){
    this.title = "";
    this.active = false;
    this.url = "";
  }

}


  export default {
    props: {
      pageName: String,
      pageDescription: String,
      breadCrumbs: Array<Link>,
    },
    components: { 
      IonGrid,
      IonRow,
      IonCol,
      IonText,
    },
    name: "PageHeader",
    setup (props: any){
      const auth = useAuthStore();
      let links: Array<Link> = props.breadCrumbs ?? [];

      links.splice(0,0,{title: "Home", active: false, url: "/" });
      links = links
      .filter(value =>{        
        if(!value.role)
          return true;       
        return auth.filteredRoles.includes(value.role);
      });
      
      return {
        peopleOutline,
        links,
      }
    }
  };
