import { defineStore } from 'pinia'
import { prettyResponse, getAnyService } from './index';
import { AxiosResponse } from 'axios';
import { serviceDescription } from '@/models/serviceStatus/ServiceDescription';
import { PackageList } from '@/models/serviceStatus/packageList';


export const ogo2Services:string[] = ["config" , "ws" , "ns" , "ms" , "os" , "ts" , "files", "pps", "fbs", "ps"];

export interface IService{
    value: serviceDescription;
}
export let serviceDict:{[id:string]: IService }

export interface IPackageListDictionary {
    value: PackageList;
}
export let packageList:{[id:string]: IPackageListDictionary}

export const useServiceStatusStore = defineStore('serviceStatus', {
  state: () => ({
    services: serviceDict,
    packageList: packageList,
    //languages: undefined as LanguageDto[] | undefined,
  }),

  getters: {
  }, 

  actions: {
    async getServices() {
        this.services = {};
        try {
            ogo2Services.forEach(async i => {
                this.services[i] = { value: new serviceDescription(i, "", "")};
                const res = await getAnyService(`health`, i, undefined) as AxiosResponse<string>;
                this.services[i] = { value: new serviceDescription(i, "", res.data)};
        })
          return;
      } catch (reason) {
          throw (prettyResponse(reason,undefined));
      }
    },
    async getPackages() {
        this.packageList = {};
        try {
            ogo2Services.forEach(async i => {
                this.packageList[i] = {value: {version: 0, parameters: ""}};
                const res = await getAnyService(`packagelist.json`, i, undefined) as AxiosResponse<PackageList>;
                this.packageList[i] = {value: res.data};
        })
          return;
      } catch (reason) {
          throw (prettyResponse(reason,undefined));
      }
    },

  }
})