

import { CallToActionDto } from "@/models/umbraco/calltoaction";
import { useConfigStore } from "@/store/configStore";
import { IonButton } from "@ionic/vue";
import { peopleOutline, } from "ionicons/icons";
import { defineComponent, onMounted, ref } from "vue";

export type PredefinedColors =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'success'
  | 'warning'
  | 'danger'
  | 'light'
  | 'medium'
  | 'dark';

  export default defineComponent( {
    props: {
      code: { type: String, required: true },
      color: { type: String, default: "primary" as PredefinedColors },
    },
    components: {
      IonButton,
    },
    name: "CTAButton",
    setup (props) {  
      const configStore = useConfigStore();
        const CTA = ref<CallToActionDto|undefined>(undefined);
      onMounted(async () => {
        CTA.value = await configStore.loadCTA(props.code);
      });
      
      const activateAction = () => {
        if(!CTA.value)
            return;
        if(CTA.value?.newWindow)
            window.open(CTA.value.targetUrl);
        else
            window.location.assign(CTA.value.targetUrl);
      }

      return {
        // color: props.color,
        activateAction,
        peopleOutline,
        CTA
      }
    }
  });
