import { defineStore } from 'pinia'
import { useAuthStore } from './authStore';
import { prettyResponse, getNow, deleteNow, ogoServices } from './index';
import { AxiosResponse } from 'axios';
import { MerchantsFileDto } from '@/models/fileService/File';

export const useFileStore = defineStore('file', {
  actions: {
    async getMerchantFiles(merchantId: string, folder: string) {
      const params = new URLSearchParams([['folder', folder]]);

      try {
          const r = await getNow(`merchants/${merchantId}`,ogoServices.files, params) as AxiosResponse<Array<MerchantsFileDto>>;
          return r.data;
      } catch (reason) {
          const authStore = useAuthStore();
          authStore.logoutNeeded(reason);
          throw (prettyResponse(reason,undefined));
      }
    },
    async deleteMerchantFile(merchantId: string, fileId: string) {
      try {
          const r = await deleteNow(`merchants/${merchantId}/${fileId}`,ogoServices.files, undefined) as AxiosResponse<void>;
          return r.data;
      } catch (reason) {
          const authStore = useAuthStore();
          authStore.logoutNeeded(reason);
          throw (prettyResponse(reason,undefined));
      }
    },

  }
})