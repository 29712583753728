

  export default {
    props: {
      headerPrefix: String,
      header: String,
      title: String,
      price: String,
      image: String,
      externalLink: String,
    },
    components: {
    },
    name: "SingleOrder",
  };
