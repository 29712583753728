
import { IonPage, IonContent, IonButton } from '@ionic/vue';
import PageHeader from "@/components/PageHeader.vue";
import PageFooter from "@/components/PageFooter.vue";
import AdminMenu from "@/views/Admin/AdminMenu.vue";

import { defineComponent, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import PageDescription from '@/components/PageDescription.vue';

import { useAuthStore } from '@/store/authStore';
import { useServiceStatusStore, ogo2Services } from '@/store/serviceStatusStore';

//import { useMerchantStore } from '@/store/merchantStore';
import { useErrorBox } from "@/components/errorBox";

export default defineComponent({
  name: 'ServiceStatus',
  components: { 
    // IonSplitPane,
    PageHeader,
    PageFooter,
    // MenuComponent,
    AdminMenu,
    PageDescription,
    IonContent,
    IonPage,
    IonButton,
  },
  data() {
    return {
      chartOptions: {
        responsive: true
      },
      ogo2Services,
    }
  },
  setup (){
    const { showError } = useErrorBox();
    const router = useRouter();
    const auth = useAuthStore();
    const status = useServiceStatusStore();
    const loaded = ref(false); 
    const showPackages = ref(true);

    onMounted(async () => {
        if(!auth.filteredRoles.includes("Admins"))
          await showError("Missing admins role", "Missing permission");
        await status.getServices();
        await status.getPackages();
        loaded.value = true;
    });

    const openMerchant = (merchantId : string) => {
          window.location.assign(auth.myOGO1Domain + `/merchant/edit/${merchantId}`);
        //  location.replace(auth.myOGO1Domain + `/merchant/edit/${merchantId}`);       
      }
      

  return {
    router, openMerchant, status, loaded, showPackages
  }
}
});

