import {
    createStore,
    MutationTree,
    ActionContext,
    ActionTree,
    GetterTree,
    Store as VuexStore,
    CommitOptions,
    DispatchOptions,
    createLogger,
    Dispatch
  } from "vuex"; 
  
import axios, { AxiosError, AxiosResponse } from "axios"
import createPersistedState from "vuex-persistedstate"
import { ShipmentsDashboardFiltersDto, ShipmentsDashboardDataDto } from "@/models/orderService/Dashboard";
import { MerchantDto } from "@/models/merchantService/Merchant";
import { ListDefinitions, SearchDefinition } from "@/models/orderService/ReturnsListFilter";
import { WarehouseDto } from "@/models/warehouseService/Warehouse";
import { useAuthStore } from "./authStore";
import { Campaign } from "@/models/feedbackService/campaign";

  export type UserLogin = {username: string; password: string}

  export type ResetPasswordModel = {
    token: string;
    userName: string;
    password: string;
  }

  export type VerifyEmailModel = {
    token: string;
    userName: string;
  }

  export type State = {
    locale: string|undefined;
    counter: number; 
    favoriteTypes: string[];
  };

  export const enum ogoServices {
    config = "config",
     ws = "ws",
     ns = "ns",
     ms = "ms",
     os = "os",
     ts = "ts",
     files = "files",
     v1 = "v1",
     umbraco = "umbraco",
     fbs = "fbs"
  }

  export const prettyResponse = ( reason: any, parseError: undefined|((message: string) => string) ) => {
    const errorResponse = reason as AxiosError;
    if(!errorResponse.isAxiosError){
      return reason;
    } else {
      let message: any = errorResponse.response?.data;
      
      if(!message || message === "")
        message = errorResponse.message;

      if(message.ExceptionMessage)
        message = message.ExceptionMessage;

      if(message?.type ==="https://tools.ietf.org/html/rfc7231#section-6.5.4"){
        message = message.title;
      } else if(message?.type==="https://tools.ietf.org/html/rfc7231#section-6.5.1"){
          message = message.title + ": " + JSON.stringify(message.errors);
      }

        if(message?.message){ message = message.message; }

        if(parseError)
          return parseError(message as string);

        return(message ?? "");
    }
  }

  export const logoutNeeded = ( err: any, _dispatch: Dispatch ) => {
    const auth = useAuthStore();
    const errorResponse = err as AxiosError;
    if(!errorResponse.isAxiosError){
      return;
    } else {
      if(errorResponse.response?.status == 401)
        auth.logout();
      return;
    }
  }
  
  const state: State = {
    counter: 0,
    locale: undefined,
    favoriteTypes: [],
  };

export function getTrackingDomain(){

  if(location.hostname.includes("beta"))
    return `${process.env.VUE_APP_BETA_TRACKINGSERVER_ADDRESS}`;

  if(location.hostname.includes("localhost") || location.hostname.includes("dev"))
    return `${process.env.VUE_APP_DEV_TRACKINGSERVER_ADDRESS}`;

    return `${process.env.VUE_APP_TRACKINGSERVER_ADDRESS}`;
}

export function getDomain (_service: ogoServices) {

  // only production server for umbraco
  if(_service === "umbraco"){
    return `https://api.ogoship.com`
  }
  
  // Temporary solution for notificaiton service 
  // if(_service === "ns"){
    //return `${process.env.VUE_APP_LOCAL_NS_SERVER}/api`
  // }

  // if(_service === "os"){
  //   return `${process.env.VUE_APP_LOCAL_OS_SERVER}/api`
  // }

    // if(_service === "ts"){
    //  return `${process.env.VUE_APP_LOCAL_TS_SERVER}/api`
    // }

  // if(_service === "ms"){
  //   return `${process.env.VUE_APP_LOCAL_MS_SERVER}/api`
  // }

  //   if(_service === ogoServices.fbs){
  //   return `${process.env.VUE_APP_LOCAL_FEEDBACK_SERVER}/api`
  // }

  // if(_service === "config"){
  //    return `${process.env.VUE_APP_LOCAL_CONFIG_SERVER}/api`
  //  }


  // Beta version uses beta api.
  if(location.hostname.includes("beta"))
    return `${process.env.VUE_APP_BETA_SERVER_ADDRESS}/api`;

  // Dev version uses dev api.
  if(location.hostname.includes("localhost") || location.hostname.includes("dev"))
    return `${process.env.VUE_APP_DEV_SERVER_ADDRESS}/api`;

  return `${process.env.VUE_APP_SERVER_ADDRESS}/api`
}

export function getAnyDomain (_service: string) {
    if(location.hostname.includes("beta"))
    return `${process.env.VUE_APP_BETA_SERVER_ADDRESS}`;

  // Dev version uses dev api.
  if(location.hostname.includes("localhost") || location.hostname.includes("dev"))
    return `${process.env.VUE_APP_DEV_SERVER_ADDRESS}`;

  return `${process.env.VUE_APP_SERVER_ADDRESS}`
}

export const postNow = (url: string, service: ogoServices, data: any, params?: URLSearchParams|undefined) => {
  const auth = useAuthStore();
  return axios.post(`${getDomain(service)}/${service}/${url}`, data, 
  { headers: { 
    Authorization: 'Bearer ' + auth.authToken, 
    Locale: store.state.locale ?? "en", 
    "Accept-Language": store.state.locale ?? "en" },params});
}

export const getNow = (url: string, service: ogoServices, params: URLSearchParams|undefined) => {
  const auth = useAuthStore();
  return axios.get(`${getDomain(service)}/${service}/${url}`, 
  { headers: { 
    Authorization: 'Bearer ' + auth.authToken, 
    Locale: store.state.locale ?? "en", 
    "Accept-Language": store.state.locale ?? "en" }
    ,params});
}

export const getFileNow = (url: string, service: ogoServices, params: URLSearchParams|undefined) => {
  const auth = useAuthStore();
  return axios.get(`${getDomain(service)}/${service}/${url}`, 
  { headers: { 
    Authorization: 'Bearer ' + auth.authToken, 
    Locale: store.state.locale ?? "en", 
    "Accept-Language": store.state.locale ?? "en" }, responseType: 'arraybuffer'
    ,params});
}

export const deleteNow = (url: string, service: ogoServices, params: URLSearchParams|undefined) => {
  const auth = useAuthStore();
  return axios.delete(`${getDomain(service)}/${service}/${url}`, 
  { headers: { 
    Authorization: 'Bearer ' + auth.authToken, 
    Locale: store.state.locale ?? "en", 
    "Accept-Language": store.state.locale ?? "en" }
    ,params});
}

export const patchNow = (url: string, service: ogoServices, data: any) => {
  const auth = useAuthStore();
  return axios.patch(`${getDomain(service)}/${service}/${url}`, data, 
  { headers: { 
    Authorization: 'Bearer ' + auth.authToken, 
    Locale: store.state.locale ?? "en", 
    'Content-Type': 'application/json',
    "Accept-Language": store.state.locale ?? "en" }});
}

export const putNow = (url: string, service: ogoServices, data: any) => {
  const auth = useAuthStore();
  return axios.put(`${getDomain(service)}/${service}/${url}`, data, 
  { headers: { 
    Authorization: 'Bearer ' + auth.authToken, 
    Locale: store.state.locale ?? "en", 
    "Accept-Language": store.state.locale ?? "en" }});
}
export const getAnyService = (url: string, service: string, params: URLSearchParams|undefined) => {
    const auth = useAuthStore();
    return axios.get(`${getDomain(service as any)}/${service}/${url}`, 
    { headers: { 
      Authorization: 'Bearer ' + auth.authToken, 
      Locale: store.state.locale ?? "en", 
      "Accept-Language": store.state.locale ?? "en" }
      ,params});
  }
  
  // mutations and action enums
  export enum MutationTypes {
    UPDATE_MERCHANT = "UPDATE_MERCHANT",
    SET_LOCALE = "SET_LOCALE",
  }
    
  export type Mutations<S = State> = {
    [MutationTypes.SET_LOCALE](state: S, payload: string|undefined): void;
  };
  
  const mutations: MutationTree<State> & Mutations = {
    [MutationTypes.SET_LOCALE](state: State, payload: string|undefined) {
      state.locale = payload;
},
};
  
  type AugmentedActionContext = {
    commit<K extends keyof Mutations>(
      key: K,
      payload: Parameters<Mutations[K]>[1]
    ): ReturnType<Mutations[K]>;
  } & Omit<ActionContext<State, State>, "commit">;

   //actions
   export enum ActionTypes {
    OS_SHIPMENT_FILTERS = "OS_SHIPMENT_FILTERS",
    OS_SHIPMENT_DASHBOARD = "OS_SHIPMENT_DASHBOARD",
    OS_RETURNS_SEARCH = "OS_RETURNS_SEARCH",
    OS_RETURNS_SEARCH_DEFINITION = "OS_RETURNS_SEARCH_DEFINITION",

    MS_GET_MERCHANT = "MS_GET_MERCHANT",

    WS_GET_WAREHOUSES = "WS_GET_WAREHOUSES",

    FBS_CAMPAIGN_SEARCH = "FBS_CAMPAIGN_SEARCH",

  }   

  export interface Actions {
    [ActionTypes.OS_SHIPMENT_FILTERS]( { commit }: AugmentedActionContext): Promise<ShipmentsDashboardFiltersDto>;
    [ActionTypes.OS_SHIPMENT_DASHBOARD]( { commit }: AugmentedActionContext, payload: {timeFrame: string|undefined, warehouseId: string|undefined, ShippingMethod: string|undefined, destinationCountry: string|undefined }): Promise<ShipmentsDashboardDataDto>;
    [ActionTypes.OS_RETURNS_SEARCH_DEFINITION]( { commit }: AugmentedActionContext): Promise<ListDefinitions>;
    [ActionTypes.OS_RETURNS_SEARCH]( { commit }: AugmentedActionContext, payload: SearchDefinition): Promise<Array<any>>;

    [ActionTypes.MS_GET_MERCHANT]( { commit }: AugmentedActionContext, payload: string): Promise<MerchantDto>;
    
    [ActionTypes.WS_GET_WAREHOUSES]( { commit }: AugmentedActionContext): Promise<Array<WarehouseDto>>;

    //[ActionTypes.FBS_CAMPAIGN_SEARCH_DEFINITION]( { commit }: AugmentedActionContext, payload: {merchantId: string, channelId: number}): Promise<CampaignListDefinition>;
    [ActionTypes.FBS_CAMPAIGN_SEARCH]( { commit }: AugmentedActionContext, payload: {merchantId: string, channelId: number}): Promise<Array<Campaign>>;
  }
  
  export const actions: ActionTree<State, State> & Actions = {

[ActionTypes.MS_GET_MERCHANT]({dispatch}, payload: string){
  return new Promise((resolve, reject) => {
    getNow(`${payload}`, ogoServices.ms, undefined)
      .then((response: AxiosResponse<MerchantDto>) => {
          resolve(response.data);
      })
      .catch(reason => {
        logoutNeeded(reason, dispatch);
        reject(prettyResponse(reason,undefined));
      });
  });
},


[ActionTypes.WS_GET_WAREHOUSES](){
  return new Promise((resolve, reject) => {
    getNow(`all`,ogoServices.ws, undefined)
      .then((response: AxiosResponse<Array<WarehouseDto>>) => {
          resolve(response.data);
      })
      .catch(reason => {
        reject(prettyResponse(reason,undefined));
      });
  });
},

[ActionTypes.OS_SHIPMENT_FILTERS]({dispatch}) {
  return new Promise((resolve, reject) => {
    getNow(`shipment/filters`, ogoServices.os, undefined)
      .then((response: AxiosResponse<ShipmentsDashboardFiltersDto>) => {
          resolve(response.data);
      })
      .catch(reason => {
        logoutNeeded(reason, dispatch);       
        reject(prettyResponse(reason,undefined));
      });
  });
},
[ActionTypes.OS_RETURNS_SEARCH_DEFINITION]({dispatch}) {
  return new Promise((resolve, reject) => {
    getNow(`Returns/definitions`,ogoServices.os, undefined)
      .then((response: AxiosResponse<ListDefinitions>) => {
          resolve(response.data);
      })
      .catch(reason => {
        logoutNeeded(reason, dispatch);       
        reject(prettyResponse(reason,undefined));
      });
  });
},


[ActionTypes.OS_SHIPMENT_DASHBOARD]({dispatch}, payload: {timeFrame: string|undefined, warehouseId: string|undefined, ShippingMethod: string|undefined, destinationCountry: string|undefined }) {
  return new Promise((resolve, reject) => {
    const params = new URLSearchParams();

    if(payload.timeFrame){ params.append('timeFrame',payload.timeFrame); }
    if(payload.warehouseId){ params.append('warehouseId',payload.warehouseId); }
    if(payload.ShippingMethod){ params.append('ShippingMethod',payload.ShippingMethod); }
    if(payload.destinationCountry){ params.append('destinationCountry',payload.destinationCountry); }

    getNow(`shipment/dashboard`, ogoServices.os, params)
      .then((response: AxiosResponse<ShipmentsDashboardDataDto>) => {
          resolve(response.data);
      })
      .catch(reason => {
        logoutNeeded(reason, dispatch);
        reject(prettyResponse(reason,undefined));
      });
  });
},
[ActionTypes.OS_RETURNS_SEARCH]({dispatch}, payload: SearchDefinition) {
  return new Promise((resolve, reject) => {
    postNow(`Returns/search`, ogoServices.os, payload)
      .then((response: AxiosResponse<Array<any>>) => {
          resolve(response.data);
      })
      .catch(reason => {
        logoutNeeded(reason, dispatch);       
        reject(prettyResponse(reason,undefined));
      });
  });
},

  [ActionTypes.FBS_CAMPAIGN_SEARCH]({dispatch}, payload: {merchantId: string, channelId: number}) {
    return new Promise((resolve, reject) => {
      getNow(`campaign/${payload.merchantId}/${payload.channelId}`,ogoServices.fbs, undefined)
        .then((response: AxiosResponse<Array<Campaign>>) => {
            resolve(response.data);
        })
        .catch(reason => {
          logoutNeeded(reason, dispatch);       
          reject(prettyResponse(reason,undefined));
        });
    });
  },
  


  };
  


  // Getters types
  export type Getters = {
    locale(state: State): string | undefined;
    //merchantInfo(state: State): MerchantState | undefined;
  };
  
  export const getters: GetterTree<State, State> & Getters = {
    locale: state => {
      return state.locale;
    },
    // merchantInfo: state => {
    //   return state.merchant;
    // },
  };
  

  //setup store type
  export type Store = Omit<VuexStore<State>, "commit" | "getters" | "dispatch"> & {
    commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
      key: K,
      payload: P,
      options?: CommitOptions
    ): ReturnType<Mutations[K]>;
  } & {
    getters: {
      [K in keyof Getters]: ReturnType<Getters[K]>;
    };
  } & {
    dispatch<K extends keyof Actions>(
      key: K,
      payload: Parameters<Actions[K]>[1],
      options?: DispatchOptions
    ): ReturnType<Actions[K]>;
  };


  export const store = createStore({
    state,
    mutations,
    actions,
    getters,
    plugins: [createLogger(),createPersistedState()]
  });
  
  export function useStore() {
    return store as Store;
  }