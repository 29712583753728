
  import { IonTitle, IonPage, IonItem, IonGrid, IonTextarea,
    IonRow, IonCol, IonButton, IonLabel, IonContent, loadingController, IonInput, IonSelect, IonSelectOption
  } from '@ionic/vue';
  import { Ref, computed, defineComponent, onMounted, ref, } from 'vue';
  import { useStore } from '@/store';
  import { useRouter } from 'vue-router';

  import PageHeader from "@/components/PageHeader.vue";
  import PageFooter from "@/components/PageFooter.vue";
  import PageDescription from '@/components/PageDescription.vue';

  import * as moment from 'moment-timezone';
  import { useErrorBox } from '@/components/errorBox';
  import { useAuthStore } from '@/store/authStore';
  import { stringToHash } from '@/utils/changeTracking';
  import { Campaign, Question, Translation, FeedbackDto, } from '@/models/feedbackService/campaign';
  import { useFeedbackStore } from '@/store/feedbackStore';
  import { LanguageDto } from '@/models/configService/Languages';
  import { useConfigStore } from '@/store/configStore';

  export default defineComponent({
    name: 'CampaignDetails',
    components: { 
      // IonSplitPane, 
      IonPage,
      IonTitle,
      IonItem, IonGrid, IonRow, IonCol, IonContent,
      IonButton, IonInput, IonTextarea,
      IonLabel, IonSelect, IonSelectOption,
      //IonSelect, IonSelectOption,
      // MenuComponent,
      PageHeader,
      PageFooter,
      PageDescription,
    },
    setup(){
    
      const store = useStore();
      const auth = useAuthStore();
      const router = useRouter();
      const feedbackStore = useFeedbackStore();
      const configStore = useConfigStore();
      const { showError } = useErrorBox();

      const momentjs: any = moment;
      moment.locale(store.state.locale ?? "en");

      const campaignId: Ref<string|undefined> = ref(undefined);
      const campaignDetails: Ref<Campaign|undefined> = ref(undefined);

      const allLanguages: Ref<Array<LanguageDto>> = ref([]);
      const currentLanguages: Ref<Array<string>> = ref([]);

      const isAdmin: Ref<boolean> = ref(false);

      const feedbackList = ref<Array<FeedbackDto>>([]);
    
      const loading = async () => {
        const loading = await loadingController.create({
          cssClass: "my-custom-class",
          message: "Processing...",
          duration: undefined,
        });

        await loading.present();
        return loading;
      };

      onMounted( async() => {

        if(auth.filteredRoles.includes("Admins"))
            isAdmin.value = true;

        if((!auth.merchantId || !auth.channelId) && !isAdmin.value)
            return;

        const idParam = router.currentRoute.value.params["id"]?.toString();
        const channelIdParam = router.currentRoute.value.params["channelId"]?.toString();
        const revisionParam = router.currentRoute.value.params["revision"]?.toString();

        if (!idParam || !channelIdParam) {
            router.back();
            return;
        }

        campaignId.value = idParam;

          const l = await loading();
          try {
            allLanguages.value = await configStore.getLanguages();
            if(idParam !== "create")
            {
                const revision = revisionParam ? Number.parseInt(revisionParam): undefined;                
                campaignDetails.value = await feedbackStore.getCampaign(auth.merchantId!, auth.channelId!, campaignId.value, revision);
                currentLanguages.value = campaignDetails.value.questions.map(q => q.translations.map(t => t.lang)).flat();
            } else {
              campaignDetails.value = new Campaign();
              campaignDetails.value.questions = [];
              campaignDetails.value.channelId = auth.channelId!;
              campaignDetails.value.merchantId = auth.merchantId!;            
            }
            savedHash.value = hashComponentState.value;
          } catch (error) {
            showError(error as string, "Error");
            router.back();
          } finally {
            await l.dismiss();
          }

          try {            
            feedbackList.value = await feedbackStore.getFeedback(auth.merchantId!, campaignId.value);
          } catch (error) {
            showError(error as string, "Error");
          } 
      
      });

      const addQuestion = async () => {
        if(!campaignDetails.value) return;
        
          const newQuestion = new Question;
          newQuestion.translations = [];
          if(currentLanguages.value.length > 0)
          {
            currentLanguages.value.forEach(currentLanguageCode => {
              const newLang = new Translation();
              newLang.lang = currentLanguageCode;
              newLang.value = "";
              newQuestion.translations.push(newLang);
            });
          } else {
            const newLang = new Translation();
            newLang.lang = "en";
            newLang.value = "";
            newQuestion.translations.push(newLang);
          }
          campaignDetails.value.questions.push(newQuestion);
        
      };

      const updateLanguages = async () => {
      if (!campaignDetails.value || campaignDetails.value.questions.length == 0)
        return;

        // remove duplicates
        currentLanguages.value = [...new Set(currentLanguages.value)];
//        console.log("updateLanguages",currentLanguages.value);

        currentLanguages.value.forEach(currentLanguageCode => {
          campaignDetails.value?.questions?.forEach(q => {
            if(q.translations.filter(t=> t.lang === currentLanguageCode).length == 0) {
                const newLang = new Translation();
                newLang.lang = currentLanguageCode;
                newLang.value = "";
                q.translations.push(newLang);
            }
          });
        });

    };

    const removeQuestionLocalization = async (locale:string) => {
        currentLanguages.value.splice(currentLanguages.value.findIndex(l => l === locale), 1);
        campaignDetails.value?.questions?.forEach(q => {
            if(q.translations.filter(t=> t.lang === locale).length > 0) {
                q.translations.splice(q.translations.findIndex(t=> t.lang === locale), 1);
            }
        });
        updateLanguages();
    };

    const saveAsNew = computed(() => {
      return campaignDetails.value?.channelId != auth.channelId && campaignDetails.value?.merchantId != auth.merchantId;
    });
  

      //#region isDirty check for relative simple components
      const savedHash: Ref<number> = ref(0);
      const hashComponentState = computed(() => {
        return stringToHash(JSON.stringify( {
        return: campaignDetails.value,
      }));
    });

    const isDirty = computed(() => {
      return saveAsNew.value || savedHash.value !== hashComponentState.value;
    });
    //#endregion


    // watch(currentLanguages, async (_newVal, _oldVal) => {
    //   await updateLanguages();
    // });


    const save = async () => {

      debugger;
      if(!campaignDetails.value || !auth.channelId || !auth.merchantId)
        return;

      const l = await loading();
      try {
        campaignDetails.value = !campaignDetails.value.id 
          ? await feedbackStore.createCampaign(auth.merchantId, auth.channelId, campaignDetails.value)
        :   await feedbackStore.updateCampaign(auth.merchantId, auth.channelId, campaignDetails.value.id, campaignDetails.value);

        if(!campaignDetails.value)
          return;

        // reload if campaign was created for another merchant or channel
        if( campaignDetails.value.channelId != auth.channelId && 
            campaignDetails.value.merchantId != auth.merchantId &&
            campaignDetails.value.id != campaignId.value) {
          showError("Campaign was created, reloading...", "Info");
          router.replace({name: 'CampaignDetails', params: {id: campaignDetails.value.id, channelId: campaignDetails.value.channelId}});
          return;
          }

        savedHash.value = hashComponentState.value;
      } catch (error) {
        showError(error as string, "Error");
      } finally {
        await l.dismiss();
      }
    }

      return {campaignId, campaignDetails: campaignDetails, momentjs, saveAsNew,
        isDirty,save, allLanguages, currentLanguages, addQuestion, removeQuestionLocalization, updateLanguages,
      }
    }
  });
