
import {  IonPage, IonContent, IonFabButton, IonFab, IonIcon, IonCard, IonButton,} from '@ionic/vue';
import { defineComponent } from 'vue';
import { usePhotoGallery, } from "@/composables/usePhotoGallery";
import { cameraOutline, trashOutline } from 'ionicons/icons';

export default defineComponent({
  name: 'CameraSamplePage',
  components: { 
    IonPage, 
    IonContent,
    IonFabButton,
    IonFab,
    IonIcon,
    // IonImg,
    IonCard,
    IonButton,
  },
  setup (){
    const { photos, removePicture, takePhoto } = usePhotoGallery();
    return {
      takePhoto, photos,removePicture,
      cameraOutline,trashOutline,
    }
  }
});

