
import { IonGrid, IonRow, IonCol, IonItem, IonLabel, } from '@ionic/vue';
import { defineComponent, onMounted, ref, Ref, } from 'vue';
import { AuthLogDto } from '@/models/merchantService/UsersModels';
import { useErrorBox } from '@/components/errorBox';
import { useUsersStore } from '@/store/usersStore';
import { trashOutline } from 'ionicons/icons';

import moment from 'moment-timezone';

export default defineComponent({
  name: 'LatestLoginsList',
  components: {
    IonGrid,
    IonRow,
    IonCol, 
    IonItem,
    IonLabel,
  },
  setup (){
    const userStore = useUsersStore();
    const { showError } = useErrorBox();

    const momentjs = (a: any): moment.Moment => { return moment(a); }

    const browsers: Ref<Array<AuthLogDto>> = ref([]);

    const initialize = async ()=> {
      try {
        browsers.value = await userStore.getLatestLogins();
      } catch (err) {
        await showError(err, "Error"); 
      }
    }

    onMounted(async () => {
      await initialize();
    });


  return { browsers, initialize, momentjs, trashOutline,};
}
});

