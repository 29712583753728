

import { peopleOutline, } from "ionicons/icons";

  export default {
    props: {
      pageName: String,
    },
    name: "PageFooter",
    setup (){
      return {
        peopleOutline,
      }
    }
  };
