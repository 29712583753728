
import { IonPage, IonContent, IonTitle, IonLabel, IonItem, IonButton, IonList, IonCol, IonRow, IonBadge, IonIcon } from '@ionic/vue';
import PageHeader from "@/components/PageHeader.vue";
import PageFooter from "@/components/PageFooter.vue";
import AdminMenu from "@/views/Admin/AdminMenu.vue";

import { defineComponent, onMounted, ref, Ref} from 'vue';

import PageDescription from '@/components/PageDescription.vue';
import { useAuthStore } from '@/store/authStore';
import { useErrorBox } from "@/components/errorBox";
import { ReturnCountryInfoDto, ReturnDeliveryTypeDto } from '@/models/orderService/DeliveryTypes';
import { useOrderStore } from '@/store/orderStore';
import { ActionTypes, useStore } from '@/store';
import { WarehouseDto } from '@/models/warehouseService/Warehouse';
import { useRouter } from 'vue-router';
import { eyeOutline, eyeOffOutline } from "ionicons/icons";

export default defineComponent({
  name: 'ReturnDeliveryTypeList',
  components: { 
    PageHeader,
    PageFooter,
    PageDescription,
    IonPage, IonContent, IonButton, IonTitle, IonLabel, IonRow, IonCol, IonList, IonItem,
    //IonGrid,
    AdminMenu,
    IonBadge,
    IonIcon
  },
  setup () {

    const store = useStore();
    const auth = useAuthStore();
    const router = useRouter()
    const orderStore = useOrderStore();
    const allReturnDeliveryTypes:Ref<Array<ReturnDeliveryTypeDto>> = ref([]);
    const warehouses: Ref<Array<WarehouseDto>> = ref([]);
    const { showError } = useErrorBox();
    onMounted(async () => {
        if(!auth.filteredRoles.includes("Admins")){
            await showError("Missing admins role", "Missing permission");
            return;
        }
        allReturnDeliveryTypes.value = await orderStore.getAllReturnDeliveryTypes(true, true);

        warehouses.value = await store.dispatch(ActionTypes.WS_GET_WAREHOUSES, undefined);
    });

    const dtCountryText = (countryData: ReturnCountryInfoDto) => {
      let countryAndWarehouseText = `${countryData.countryCode} (`;
      countryData.warehouses.forEach(w => {
        const warehouseCode = warehouses.value.find(i => i.id == w)?.code ?? "?";
        countryAndWarehouseText += `${warehouseCode}, `;
      });
      countryAndWarehouseText = countryAndWarehouseText.slice(0, -2);
      countryAndWarehouseText += ")";

      return countryAndWarehouseText;
    }

    return {
        allReturnDeliveryTypes, dtCountryText, router, eyeOutline, eyeOffOutline
    }
  }
});

