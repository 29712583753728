import { NotificationTemplateDto } from "@/models/notificationService/NotificationFrame";
import mitt, { Emitter } from "mitt";
import { onUnmounted } from "vue";
import { Campaign } from '@/models/feedbackService/campaign';
import { ReturnDetailedViewDto } from '@/models/orderService/Return';
import { ReturnOrderDto } from "@/models/orderService/ReturnOrder";
import { ReturnRuleDto } from "@/models/merchantService/Returns";

// type Handler<T = unknown> = (event: T) => void;
type EventHandlers<T extends Record<string, unknown>> = {
  [K in keyof T]: (event: T[K]) => void;
};

export function useMittEvents<T extends Record<string, unknown>>(
  mitt: Emitter<T>,
  handlers: EventHandlers<T>
) {
  for (const key of Object.keys(handlers)) {
    mitt.on(key, handlers[key]);
  }
  function cleanup() {
    for (const key of Object.keys(handlers)) {
      mitt.off(key, handlers[key]);
    }
  }
  onUnmounted(cleanup);
  return cleanup;
}




type EventBus = {
    foo?: string,
    bar?: number,
    templateChanged?: NotificationTemplateDto,
    campaignChanged?: Campaign,
    returnChanged?: ReturnDetailedViewDto | ReturnOrderDto,
    returnRuleChanged?: ReturnRuleDto,
  };

  export const eventBus = mitt<EventBus>();
//   //
//   useMittEvents(eventBus, {
//     foo(str) { // `str` is string
//       console.log("valid", str);
//     },
//     bar(num) { // `num` is number
//       console.log("valid", num);
//     },
//     baz(v) { // error!
//       console.log("invalid", v);
//     },
//   });



export function useOgoEvents(handlers: EventHandlers<EventBus>) {
    const keys = Object.keys(handlers) as Array<keyof EventBus>;
    for (const key of keys) {
      eventBus.on(key, handlers[key] as never);
    }
    const cleanup = () => {
      for (const key of keys) {
        eventBus.off(key, handlers[key] as never);
      }
    };
    onUnmounted(cleanup);
    return cleanup;
  }