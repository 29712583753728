
import { IonPage, IonContent, IonTitle, IonLabel, IonSelect, IonItem, IonButton, IonList, IonCol, IonRow, IonSelectOption, IonBadge } from '@ionic/vue';
import PageHeader from "@/components/PageHeader.vue";
import PageFooter from "@/components/PageFooter.vue";
import AdminMenu from "@/views/Admin/AdminMenu.vue";

import { defineComponent, onMounted, ref, Ref} from 'vue';

import PageDescription from '@/components/PageDescription.vue';
import { ReturnReasonDto } from '@/models/configService/ReturnReasons';
import { useAuthStore } from '@/store/authStore';
import { useConfigStore } from '@/store/configStore';
import { useErrorBox } from "@/components/errorBox";
import { useRouter } from 'vue-router';
import { LanguageDto } from '@/models/configService/Languages';

export default defineComponent({
  name: 'ReturnReasonsAdmin',
  components: { 
    PageHeader,
    PageFooter,
    PageDescription,
    IonPage, IonContent, IonButton, IonTitle, IonLabel, IonRow, IonCol, IonSelect, IonList, IonItem, IonSelectOption, IonBadge,
    //IonGrid,
    AdminMenu,
  },
  data() {
    return {
      chartOptions: {
        responsive: true
      },
    }
    },
  setup () {
    const loaded = ref(false); 
    const auth = useAuthStore();
    const configStore = useConfigStore();
    const router = useRouter();
    const allReturnReasons:Ref<Array<ReturnReasonDto>> = ref([]);
    const allLanguages:Ref<Array<LanguageDto>> = ref([]);
    const lang:Ref<string> = ref("en");
    const { showError } = useErrorBox();
    onMounted(async () => {
        if(!auth.filteredRoles.includes("Admins")){
            await showError("Missing admins role", "Missing permission");
            return;
        }
        allLanguages.value = await configStore.getLanguages();
        allReturnReasons.value = await configStore.getAllReturnReasons();
        loaded.value = true;
    });

    return {
        loaded, allReturnReasons, lang, allLanguages, router
    }
  }
});

