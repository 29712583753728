import seq from 'seq-logging';


export function useLogger() {

    // let Logger = require('./seq_logger')(Blob, fetch, typeof AbortController !== 'undefined' ? AbortController : require('abort-controller'));

    const logger = new seq.Logger({ 
        serverUrl: 'https://ogodev.ddns.net',
        apiKey:"7xVI7LDs6LhbLKiYGIfa", 
        onError: (e: Error) => console.log(e)});

    const logInfo = (message: string, properties?: any) => {

        try {

        
        console.log(message, properties);
        properties = properties || {};
        properties["location"] = window.location.href;

        logger.emit({
            timestamp: new Date(),
            level: 'Information',
            messageTemplate: message,
            properties: properties
        });
    } catch (e) {
        console.log("logging failed", e);
    }
    };

    const logError = (message: string, properties?: any, exception?: string ) => {

        try{

            console.log(message, properties);
            properties = properties || {};
            properties["location"] = window.location.href;
            
            logger.emit({
                timestamp: new Date(),
                level: 'Error',
                messageTemplate: message,
                properties: properties,
                exception: exception
            });
        } catch (e) {
            console.log("logging failed", e);
        }
    };

    return { logInfo, logError };
}