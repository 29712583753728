import { TreeItemSource } from "../components";

export class CategoryWithCostsDto {
    public id = "";
    public name = "";
    public cost = 0;
    public purchaseCost = 0;
    public quantity = 0;
    public description: string|undefined;
    public unit: string|undefined;
    public subCategories: CategoryWithCostsDto[] = [];
    public dimensions: CostDimensionDto[] = [];


    public static getTreeItemSource(dto: CategoryWithCostsDto): TreeItemSource {
        const subCategories: TreeItemSource[] = [];
        dto.subCategories.forEach(subCat => {
          subCategories.push(CategoryWithCostsDto.getTreeItemSource(subCat));
        });
        const t = new TreeItemSource(dto.id, dto.name, dto.description, dto.cost ?? 0, subCategories);
        t.altAmount = dto.purchaseCost ?? 0;
        t.dimensions = dto.dimensions;
        t.quantity = dto.quantity;
        t.unit = dto.unit;
        return t;
    }
}


export class CostDimensionDto {
    public dimension = "";
    public totalEur = 0;
    public purchaseTotal = 0;
}




export class CostSearchFilter {
    public from: Date;
    public to: Date;
    public channelIdList: number[];
    public warehouseId?: string;
    public merchantId?: string;

    constructor() {
        this.from = new Date();
        this.to = new Date();
        this.channelIdList = [];
    }
}

export class RowSearchFilter extends CostSearchFilter {
    public categoryList: string[];

    constructor() {
        super();
        this.categoryList = [];
    }
}

export class InvoiceRowDetailed {
    public id: string;
    public invoiceId: string;
    public description = "";
    public total: number;
    public tags = "";
    public unitPrice?: number;
    public quantity?: number;
    public costCode = "";
    public discountP?: number;
    public warehouseId?: string;
    public rowNumber?: number;
    public currency = "";
    public totalEur?: number;
    public purchaseTotal?: number;
    public purchaseEur?: number;
    public purchaseNumber = "";
    public categoryId?: string;
    public dimension = "";

    // public invoice: Invoice;

    constructor() {
        this.id = "";
        this.invoiceId = "";
        this.total = 0;
    }
}