
import { ChannelReference } from '@/models/orderService/DeliveryTypes';
import { Ref, computed, defineComponent, onMounted, ref } from 'vue';
import { IonButton, IonList, IonItem, IonInput, IonLabel, IonIcon } from "@ionic/vue";
import { useMerchantStore } from '@/store/merchantStore';
import { SearchDefinition } from '@/models/orderService/ReturnsListFilter';
import { useErrorBox } from './errorBox';
import { ChannelWithSettingsView } from '@/models/merchantService/Merchant';
import { trashOutline } from 'ionicons/icons';

export default defineComponent({
  props: {
    currentChannels: Array<ChannelReference>,
  },
  emits: {
    channelAdded: (_channelId: number, _channelName: string) => true,
    channelRemoved: (_channelId: number) => true,
  },
  components: {
    IonButton,
    IonItem,
    IonList,
    IonInput,
    IonLabel,
    IonIcon
  },
  name: "ChannelSelect",
  setup(props, emits) {

    const merchantStore = useMerchantStore();
    const currentChannels2 = computed(() => props.currentChannels);
    const allChannels: Ref<Array<ChannelWithSettingsView>> = ref([])
    const newChannel = ref("");
    const { showError } = useErrorBox();
    onMounted(async () => {
      const search = new SearchDefinition();
      search.limit.skip = 0
      search.limit.take = 0;

      allChannels.value = await merchantStore.getChannels(search);

    });

    const addChannel = (idString: string) => {
      const channelId = parseInt(idString);

      if (currentChannels2.value?.find(i => i.channelId == channelId)) {
        showError("", "Channel already added.");
        return;
      }

      const channel = allChannels.value.find((i: { id: number; }) => i.id == channelId);

      if (channel == undefined) {
        showError("", "Channel not found.");
        return;
      }

      emits.emit('channelAdded', channel.id, channel.name);

    }

    return {
      currentChannels2, newChannel, addChannel, trashOutline
    }
  }
});

