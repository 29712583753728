import { LocalizedStringDto } from "./ReturnReasons";

export class CountryDto {
    code: string;
    name: string;
    localizations: Array<LocalizedStringDto>;

    constructor(){
        this.code = "";
        this.name = "";
        this.localizations = [];
    }
}
