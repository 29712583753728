import { NotificationSettingDto, RebuyLevel } from "./Merchant";

export class ChannelUIDto {
    uid = 0;
    language = '';
    merchantId?: string | undefined;
    rebuyLevel?: RebuyLevel | undefined
    company?: CompanyDto | undefined;
    notificationSettings: NotificationSettingDto[] = [];
    socialLinks: LinkDto[] = [];
    widgets: WidgetDto[] = [];
    textCustomizations: TextCustomizationDto[] = [];
    whiteLabelling?: Theme;
    isPublished = false;
    loginNotRequired = false;
    inMaintenance = false;
}
export class CompanyDto {
    name = "";
    logo = "";
}
export class LinkDto {
    title = "";
    to = "";
}
export class TextCustomizationDto {
    key = "";
    value: TextCustomizationValue[] = [];
}

export class TextCustomizationValue {
    text = "";
    lang = "";
}
export class WidgetDto {
    type = "";
    size: number | undefined;
    props: BasicWidgetProps|HighlightedProductWidgetProps|LinksWidgetProps|undefined = undefined;
    lang = "";
    hidden = false;
}

export class BasicWidgetProps {
    mainText = "";
    subText = "";
}

export class HighlightedProductWidgetProps {
    text = "";
    mainText = "";
    image = "";
    buttonText = "";
    url = "";
    isFullBackground = false;
}

export class LinksWidgetProps {
    links: WidgetLink[] = [new WidgetLink()]
}

export class WidgetLink {
    title = "";
    to = "";
}

export class Theme {
    colorPalette: ColorPalette | undefined;
    font: string | undefined;
    box: Style | undefined;
    button: Style | undefined;
    logoHeight: number | undefined;
    logoMargin: number | undefined;
    languageSettings: LanguageSettings | undefined;
    companyName: string | undefined;
    companyLogo: string | undefined;
    storeUrl: string | undefined;
    privacyPolicyUrl: string | undefined;
    socialIcons: SocialIcons | undefined;
    conditionsUrl: string | undefined;
}

export class ColorPalette {
    primary: string | undefined;
    secondary: string | undefined;
    hyperlink: string | undefined;
    icon: string | undefined;
    heading: string | undefined;
    text: string | undefined;
    softText: string | undefined;
    buttonPrimaryColor: string | undefined;
    buttonPrimaryBg: string | undefined;
    buttonSecondaryColor: string | undefined;
    buttonSecondaryBg: string | undefined;
    buttonTransparentColor: string | undefined;
}

export class Style {
    borderRadius: number | undefined;
    variant: string | undefined;
}

export class SocialIcons {
    bg: string | undefined;
    color: string | undefined;
}
export class LanguageSettings {
    de = false;
    en = true;
    fi = false;
    sv = false;

    no = false;
    da = false;
    // detectionMode = 0;
}