
import {IonSelect, IonSelectOption, IonGrid, IonRow, IonCol, IonItem, IonToggle,
IonInput, IonButton, toastController, IonText, } from '@ionic/vue';

import { computed, defineComponent, onMounted, ref, Ref, } from 'vue';
import { ChannelNotificationsSettingsDto, NotificationProviderSettingsDto, ChannelSettingsDto, UiDataField, SendTestDto, ChannelNotificationsSettingsUpdateDto } from '@/models/notificationService/Providers';
import { useErrorBox } from '@/components/errorBox';
import { useAuthStore } from '@/store/authStore';
import { useNotificationStore } from '@/store/notificationStore';
import { stringToHash } from '@/utils/changeTracking';
import { useMerchantStore } from '@/store/merchantStore';
import { RebuyLevel } from '@/models/merchantService/Merchant';

export default defineComponent({
  name: 'ProviderSettings',
  components: {
    IonGrid,
    IonRow,
    IonCol, 
    IonItem,
    IonSelect,
    IonSelectOption,
    IonToggle,
    IonInput,
    IonButton,
    IonText,
  },
  setup (){
    const auth = useAuthStore();
    const notifications = useNotificationStore();
    const merchantStore = useMerchantStore();

    const { showError } = useErrorBox();

    const emailSelected: Ref<string|undefined> = ref(undefined);
    const smsSelected: Ref<string|undefined> = ref(undefined);
    const showSettings = ref(false);

    const emailProviders: Ref<Record<string,Array<UiDataField>>> = ref({});
    const smsProviders: Ref<Record<string,Array<UiDataField>>> = ref({});

    const newSettings: Ref<ChannelNotificationsSettingsDto|undefined> = ref(undefined);
    const settings: Ref<ChannelSettingsDto|undefined> = ref(undefined);

    const emailFields = computed(() => {
      if(!settings.value?.emailProviderKey) return [];
      return emailProviders.value[settings.value.emailProviderKey];
    });

    const smsFields = computed(() => {
      if(!settings.value?.smsProviderKey) return [];
      return smsProviders.value[settings.value.smsProviderKey];
    });

    const loadSettings = async ()=> {

      if(!auth.merchantId || !auth.channelId){
        return;
      }

      newSettings.value = await notifications.getChannelNotificationSettings(auth.channelId);

      if(!newSettings.value){
        settings.value = new ChannelSettingsDto();
        settings.value.channelId = 0;
      }
      else {
        settings.value = new ChannelSettingsDto();
        MapNewSettingsToOldSettings();
      }

      try {

        const res = await notifications.getAllProviders();

        const availableEmailProviders = res.emailProviders.filter(i => i.minimumLevel <= (merchantStore.currentMerchant?.level ?? RebuyLevel.Free));
        const availableSMSProviders = res.smsProviders.filter(i => i.minimumLevel <= (merchantStore.currentMerchant?.level ?? RebuyLevel.Free));

        if(!availableEmailProviders.find(t => t.key == settings.value?.emailProviderKey)){
          const oldEmailProvider = res.emailProviders.find(t => t.key == settings.value?.emailProviderKey);
          if(oldEmailProvider)
            availableEmailProviders.push(oldEmailProvider);
        }

        if(!availableSMSProviders.find(t => t.key == settings.value?.smsProviderKey)){
          const oldSMSProvider = res.smsProviders.find(t => t.key == settings.value?.smsProviderKey);
          if(oldSMSProvider)
            availableSMSProviders.push(oldSMSProvider);
        }

        if(availableEmailProviders.length != 0 && availableSMSProviders.length != 0)
          showSettings.value = true;

        emailProviders.value = {};
        for(let i = 0; i < availableEmailProviders.length; i++){
          const key = availableEmailProviders[i].key;
          emailProviders.value[key] = await notifications.getProviderParameters(key); 
        }

        smsProviders.value = {};
        for(let i = 0; i < availableSMSProviders.length; i++){
          const key = availableSMSProviders[i].key;
          smsProviders.value[key] = await notifications.getProviderParameters(key);
        }
    
        const dejsonSmsProviders = !settings.value || !settings.value.smsSettingsJson || settings.value.smsSettingsJson?.length  < 3 
        ? {} 
        : JSON.parse(settings.value.smsSettingsJson);

        const dejsonEmailProviders = !settings.value || !settings.value.emailSettingJson || settings.value?.emailSettingJson?.length < 3 
        ? {} 
        : JSON.parse(settings.value.emailSettingJson)

        if(settings.value?.emailProviderKey){
          emailProviders.value[settings.value?.emailProviderKey]
            .forEach(f => { f.dataValue = dejsonEmailProviders[f.fieldID]; });

          setTimeout(() => { emailSelected.value = settings.value?.emailProviderKey; }, 100);
        }

        if(settings.value?.smsProviderKey){
          smsProviders.value[settings.value?.smsProviderKey]
            .forEach(f => { f.dataValue = dejsonSmsProviders[f.fieldID]; });

          setTimeout(() => { smsSelected.value = settings.value?.smsProviderKey; }, 100);
        }

        savedHash.value = hashComponentState.value;
      } catch (err) {
        await showError(err, "Error"); 
      }
    }

    onMounted(async () => {
      loadSettings();
    });

    const save = async() => {
      if(!settings.value)
        return;

      try {


/*         const saveData:CreateOrUpdateSettingsModel =  {...settings.value};

        const emailSettings: any = {};
        emailProviders.value[settings.value.emailProviderKey].forEach(f => { emailSettings[f.fieldID] = f.dataValue; });
        saveData.emailSettingJson = JSON.stringify(emailSettings);

        const smsSettings: any = {};
        smsProviders.value[settings.value.smsProviderKey].forEach(f => { smsSettings[f.fieldID] = f.dataValue; });
        saveData.smsSettingsJson = JSON.stringify(smsSettings); */

        
        if(settings.value.channelId === 0){
          MapOldSettingsToNewSettings();

          if(!settings.value || !newSettings.value){
            await showError("Error while saving settings.", "Error");
            return;
          }          

          newSettings.value = await notifications.createChannelNotificationSettings(newSettings.value.channelId, newSettings.value);
          MapNewSettingsToOldSettings();
        }
        else {
          const updateModel: ChannelNotificationsSettingsUpdateDto = MapOldSettingsToUpdateModel();
          newSettings.value = await notifications.updateChannelNotificationSettings(settings.value.channelId, updateModel);
          MapNewSettingsToOldSettings();
        }

        //settings.value = await notifications.updateChannelNotificationSettings(saveData);

        savedHash.value = hashComponentState.value;
        const toast = await toastController.create({
            message: "Return settings saved successfully!",
            duration: 1500,
            position: "top",
            color: "primary",
          });

        await toast.present();

      } catch (e) {
          showError(e, "Error saving settings!");
      }      
    }

    //#region isDirty check for relative simple components
    const savedHash: Ref<number> = ref(0);
    const hashComponentState = computed(() => {
      return stringToHash(JSON.stringify( {
        emailFields: emailFields.value,
        smsFields: smsFields.value,
        newSettings: newSettings.value,
        settings: settings.value,
      }));
    });

    const isDirty = computed(() => {
      if(savedHash.value === 0)
                return false;
      return savedHash.value !== hashComponentState.value;
    });
    //#endregion

    const sendTestEmail = async() => {
      if(!settings.value)
        return;

      if(!settings.value.emailProviderKey){
        await showError("Please select email provider", "Error");
        return;
      }

      if(!settings.value.testEmail){
        await showError("Please input test email address", "Error");
        return;
      }

      try {
        const testData: SendTestDto = {
        recipients: [settings.value.testEmail],
        notificationChannel: "email",
        channelId: settings.value.channelId
      };

        await notifications.sendTestMessage(testData);

        const toast = await toastController.create({
            message: "Test email send.",
            duration: 1500,
            position: "top",
            color: "primary",
          });

        await toast.present();
      }
      catch(err){
        await showError(err as string, "Error"); 
      }

    }

    const sendTestSMS = async() => {
      if(!settings.value)
        return;

      if(!settings.value.smsProviderKey){
        await showError("Please select SMS provider", "Error");
        return;
      }

      if(!settings.value.testPhone){
        await showError("Please input test phone number", "Error");
        return;
      }

      try {
        const testData: SendTestDto = {
        recipients: [settings.value.testPhone],
        notificationChannel: "sms",
        channelId: settings.value.channelId
      };

        await notifications.sendTestMessage(testData);

        const toast = await toastController.create({
            message: "Test SMS send.",
            duration: 1500,
            position: "top",
            color: "primary",
          });

        await toast.present();
      }
      catch(err){
        await showError(err as string, "Error"); 
      }

    }

    const removeSettings = (type:string) => {
      if(!settings.value)
        return;

      if(type == "Email"){
        settings.value.emailProviderKey = "";
        settings.value.emailSettingJson = "";
        settings.value.testEmail = "";
        settings.value.isEmailTest = false;
      }
      if(type == "SMS"){
        settings.value.smsProviderKey = "";
        settings.value.smsSettingsJson = "";
        settings.value.testPhone = "";
        settings.value.isSMSTest = false;
      }
    }


  return { isDirty, emailProviders, smsProviders, emailFields, smsFields, settings, save, sendTestEmail, sendTestSMS, removeSettings, showSettings }

function MapOldSettingsToUpdateModel() {
  const updateModel: ChannelNotificationsSettingsUpdateDto = new ChannelNotificationsSettingsUpdateDto();
  if(!settings.value)
      return updateModel;
  updateModel.notificationProviderSettings = [];

  if(settings.value.emailProviderKey != "") {
    const emailSettings: NotificationProviderSettingsDto = new NotificationProviderSettingsDto();
    emailSettings.type = "Email";
    emailSettings.key = settings.value.emailProviderKey;
    emailSettings.testAddress = settings.value.testEmail;
    emailSettings.testMode = settings.value.isEmailTest;

    const emailJsonSettings: any = {};
    emailProviders.value[settings.value.emailProviderKey].forEach(f => { emailJsonSettings[f.fieldID] = f.dataValue; });
    emailSettings.settingsJSON = JSON.stringify(emailJsonSettings);

    updateModel.notificationProviderSettings.push(emailSettings);
  }

  if(settings.value.smsProviderKey != ""){
    const smsSettings: NotificationProviderSettingsDto = new NotificationProviderSettingsDto();
    smsSettings.type = "SMS";
    smsSettings.key = settings.value.smsProviderKey;
    smsSettings.testAddress = settings.value.testPhone;
    smsSettings.testMode = settings.value.isSMSTest;

    const smsJsonSettings: any = {};
    smsProviders.value[settings.value.smsProviderKey].forEach(f => { smsJsonSettings[f.fieldID] = f.dataValue; });
    smsSettings.settingsJSON = JSON.stringify(smsJsonSettings);

    updateModel.notificationProviderSettings.push(smsSettings);
  }

  return updateModel;
}

function MapOldSettingsToNewSettings() {
  if(!settings.value || !newSettings.value || !auth.channelId)
    return;
  newSettings.value = new ChannelNotificationsSettingsDto();
  newSettings.value.channelId = auth.channelId;
  newSettings.value.defaultLanguage = settings.value.defaultLanguage;
  newSettings.value.languages = settings.value.languages;

  const emailSettings: NotificationProviderSettingsDto = new NotificationProviderSettingsDto();
  emailSettings.type = "Email";
  emailSettings.key = settings.value.emailProviderKey;
  emailSettings.testAddress = settings.value.testEmail;
  emailSettings.testMode = settings.value.isEmailTest;

  const emailJsonSettings: any = {};
  emailProviders.value[settings.value.emailProviderKey].forEach(f => { emailJsonSettings[f.fieldID] = f.dataValue; });
  emailSettings.settingsJSON = JSON.stringify(emailJsonSettings);

  newSettings.value.notificationProviderSettings.push(emailSettings);

  const smsSettings: NotificationProviderSettingsDto = new NotificationProviderSettingsDto();
  smsSettings.type = "SMS";
  smsSettings.key = settings.value.smsProviderKey;
  smsSettings.testAddress = settings.value.testPhone;
  smsSettings.testMode = settings.value.isSMSTest;

  const smsJsonSettings: any = {};
  smsProviders.value[settings.value.smsProviderKey].forEach(f => { smsJsonSettings[f.fieldID] = f.dataValue; });
  smsSettings.settingsJSON = JSON.stringify(smsJsonSettings);

  newSettings.value.notificationProviderSettings.push(smsSettings);
}

function MapNewSettingsToOldSettings() {
  if(!settings.value || !newSettings.value)
    return;
  settings.value.channelId = newSettings.value.channelId;
  settings.value.defaultLanguage = newSettings.value.defaultLanguage;
  settings.value.languages = newSettings.value.languages;

  settings.value.emailProviderKey = newSettings.value.notificationProviderSettings.find(s => s.type === "Email")?.key ?? "";
  settings.value.emailSettingJson = newSettings.value.notificationProviderSettings.find(s => s.type === "Email")?.settingsJSON ?? "";
  settings.value.testEmail = newSettings.value.notificationProviderSettings.find(s => s.type === "Email")?.testAddress ?? "";
  settings.value.isEmailTest = newSettings.value.notificationProviderSettings.find(s => s.type === "Email")?.testMode ?? false;

  settings.value.smsProviderKey = newSettings.value.notificationProviderSettings.find(s => s.type === "SMS")?.key ?? "";
  settings.value.smsSettingsJson = newSettings.value.notificationProviderSettings.find(s => s.type === "SMS")?.settingsJSON ?? "";
  settings.value.testPhone = newSettings.value.notificationProviderSettings.find(s => s.type === "SMS")?.testAddress ?? "";
  settings.value.isSMSTest = newSettings.value.notificationProviderSettings.find(s => s.type === "SMS")?.testMode ?? false;
  }
}
});

