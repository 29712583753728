export class ShipmentMetrics {
        shippedDate: Date;
        status: ShipmentTrackingStatus;
        deliveryType: string;
        quantity: number;
        stopped: number;
        info: string;
        merchantOpenIssues: number;
        ogoOpenIssues: number;
        isLate: number;
        constructor(shippedDate: Date, status: ShipmentTrackingStatus, deliveryType: string, quantity: number, stopped: number, info: string, merchantOpenIssues: number, ogoOpenIssues: number, isLate: number) {
            this.shippedDate = shippedDate;
            this.status = status;
            this.deliveryType = deliveryType;
            this.quantity = quantity;
            this.stopped = stopped;
            this.info = info;
            this.merchantOpenIssues = merchantOpenIssues;
            this.ogoOpenIssues = ogoOpenIssues;
            this.isLate = isLate;
        }
}

export enum ShipmentTrackingStatus {
    OrderCancelled = -5,
    OrderCollecting = -4,
    OrderPending = -3,
    OrderReserved = -2,
    OrderNew = -1,
    NotSet = 0,
    NoTracking = 3,
    NotImplemented = 4,
    InvalidTrackingCode = 5,
    NotFound = 10,
    Error = 20,
    Webhooks = 25,
    Booked = 30,
    InTransit = 40,
    Exception = 45,
    WithCourier = 50,
    PickUpReady = 60,
    DeliveryAttempted = 70,
    Delivered = 80,
    Returned = 90,
    ReturnInTransit = 95,
    ReturnDelivered = 100,
    ReturnCompleted = 110
}
