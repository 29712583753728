import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-61d202dc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "page-layout" }
const _hoisted_2 = { class: "card-container" }
const _hoisted_3 = { class: "img-container" }
const _hoisted_4 = ["src"]
const _hoisted_5 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_header = _resolveComponent("page-header")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ReturnMenu = _resolveComponent("ReturnMenu")!
  const _component_PageDescription = _resolveComponent("PageDescription")!
  const _component_ion_card_title = _resolveComponent("ion-card-title")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_page_footer = _resolveComponent("page-footer")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_page_header, { pageName: "Return delivery types" }),
      _createVNode(_component_ion_content, { fullscreen: true }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_ReturnMenu, null, {
              default: _withCtx(() => [
                (_ctx.localeList)
                  ? (_openBlock(), _createBlock(_component_ion_select, {
                      key: 0,
                      style: {"width":"200px","min-height":"42px"},
                      fill: "outline",
                      interface: "popover",
                      modelValue: _ctx.locale,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.locale) = $event)),
                      "ok-text": "select",
                      "cancel-text": "cancel"
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localeList, (c) => {
                          return (_openBlock(), _createBlock(_component_ion_select_option, {
                            key: c,
                            value: c
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(c), 1)
                            ]),
                            _: 2
                          }, 1032, ["value"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["modelValue"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }),
            _createVNode(_component_PageDescription, {
              pageName: "Return delivery types",
              pageDescription: "All available delivery types",
              breadCrumbs: [{title: 'Dashboard', active: true, url: '/home'}]
            }),
            _createElementVNode("div", _hoisted_2, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.returnReasons, (reason) => {
                return (_openBlock(), _createBlock(_component_ion_card, {
                  key: reason.id
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_3, [
                      (reason.logo && reason.logo !== '')
                        ? (_openBlock(), _createElementBlock("img", {
                            key: 0,
                            src: reason.logo
                          }, null, 8, _hoisted_4))
                        : _createCommentVNode("", true)
                    ]),
                    _createVNode(_component_ion_card_header, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_card_title, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(reason.title), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_ion_card_content, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_title, null, {
                          default: _withCtx(() => [
                            _createTextVNode("Return instructions")
                          ]),
                          _: 1
                        }),
                        _createElementVNode("div", {
                          innerHTML: reason.returnInstructions
                        }, null, 8, _hoisted_5),
                        _createVNode(_component_ion_title, null, {
                          default: _withCtx(() => [
                            _createTextVNode("Available countries")
                          ]),
                          _: 1
                        }),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(reason.availableCountries, (country) => {
                          return (_openBlock(), _createElementBlock("span", { key: country }, _toDisplayString(country) + ", ", 1))
                        }), 128))
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _createVNode(_component_page_footer)
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}