import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-68dd6767"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "page-layout" }
const _hoisted_2 = { class: "inner-panel" }
const _hoisted_3 = { class: "inner-panel" }
const _hoisted_4 = { class: "ion-padding" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_page_header = _resolveComponent("page-header")!
  const _component_PageDescription = _resolveComponent("PageDescription")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_textarea = _resolveComponent("ion-textarea")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_page_footer = _resolveComponent("page-footer")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, {
    class: "ion-page",
    id: "main"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_page_header, {
        pageName: "Campaign details",
        backButton: true,
        hilight: _ctx.isDirty
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_button, {
            disabled: !_ctx.isDirty,
            color: "tertiary",
            onClick: _ctx.save
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.saveAsNew ? 'Save as new' : 'Save'), 1)
            ]),
            _: 1
          }, 8, ["disabled", "onClick"])
        ]),
        _: 1
      }, 8, ["hilight"]),
      _createVNode(_component_ion_content, { fullscreen: true }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_PageDescription, {
              pageName: "Campaign details",
              pageDescription: "See overview of campaign here",
              breadCrumbs: [{title: 'Campaign list', active: false, url: '/feedback/campaignlist'},
                       {title: 'Details', active: true, url: ''}]
            }),
            (_ctx.campaignDetails)
              ? (_openBlock(), _createBlock(_component_ion_grid, {
                  key: 0,
                  style: {"width":"100%"}
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_col, { size: "5" }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_2, [
                              _createVNode(_component_ion_title, { class: "no-padding" }, {
                                default: _withCtx(() => [
                                  _createTextVNode("Campaign Details")
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_item, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_input, {
                                    modelValue: _ctx.campaignDetails.name,
                                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.campaignDetails.name) = $event)),
                                    "label-placement": "stacked",
                                    label: "Name"
                                  }, null, 8, ["modelValue"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_item, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_input, {
                                    modelValue: _ctx.campaignDetails.usageKey,
                                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.campaignDetails.usageKey) = $event)),
                                    "label-placement": "stacked",
                                    label: "Usage key"
                                  }, null, 8, ["modelValue"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_item, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_select, {
                                    label: "Select languages",
                                    multiple: true,
                                    "label-placement": "floating",
                                    onIonChange: _ctx.updateLanguages,
                                    modelValue: _ctx.currentLanguages,
                                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.currentLanguages) = $event))
                                  }, {
                                    default: _withCtx(() => [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.allLanguages, (lang) => {
                                        return (_openBlock(), _createBlock(_component_ion_select_option, {
                                          key: lang.code,
                                          value: lang.code
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(lang.name), 1)
                                          ]),
                                          _: 2
                                        }, 1032, ["value"]))
                                      }), 128))
                                    ]),
                                    _: 1
                                  }, 8, ["onIonChange", "modelValue"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_item, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_textarea, {
                                    modelValue: _ctx.campaignDetails.description,
                                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.campaignDetails.description) = $event)),
                                    "label-placement": "stacked",
                                    label: "Description",
                                    rows: 5
                                  }, null, 8, ["modelValue"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_item, { lines: "none" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_label, null, {
                                    default: _withCtx(() => [
                                      _createTextVNode("Revision " + _toDisplayString(_ctx.campaignDetails.revision), 1)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_col, { size: "7" }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_3, [
                              _createVNode(_component_ion_button, {
                                style: {"float":"right"},
                                onClick: _ctx.addQuestion
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode("new question")
                                ]),
                                _: 1
                              }, 8, ["onClick"]),
                              _createVNode(_component_ion_title, { class: "no-padding" }, {
                                default: _withCtx(() => [
                                  _createTextVNode("Questions")
                                ]),
                                _: 1
                              }),
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.campaignDetails.questions, (q, index) => {
                                return (_openBlock(), _createElementBlock("div", {
                                  key: q.id
                                }, [
                                  _createElementVNode("h4", _hoisted_4, "question " + _toDisplayString(index+1), 1),
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(q.translations, (loc) => {
                                    return (_openBlock(), _createBlock(_component_ion_item, {
                                      key: loc.lang,
                                      style: {"clear":"both"}
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_ion_input, {
                                          modelValue: loc.value,
                                          "onUpdate:modelValue": ($event: any) => ((loc.value) = $event),
                                          style: {"margin-top":"10px"},
                                          "label-placement": "stacked",
                                          label: loc.lang
                                        }, null, 8, ["modelValue", "onUpdate:modelValue", "label"]),
                                        _createVNode(_component_ion_button, {
                                          slot: "end",
                                          onClick: ($event: any) => (_ctx.removeQuestionLocalization(loc.lang))
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode("remove")
                                          ]),
                                          _: 2
                                        }, 1032, ["onClick"])
                                      ]),
                                      _: 2
                                    }, 1024))
                                  }), 128))
                                ]))
                              }), 128))
                            ])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_page_footer)
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}