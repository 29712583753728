export class LocalizedStringDto
{
    lang: string;
    context: string;

    constructor(){
        this.lang = "";
        this.context = "";
    }
}

export class ChannelReferenceDto
{
    channelId: number;
    name: string;

    constructor(){
        this.channelId = 0;
        this.name = "";
    }
}

export class ReturnReasonDto  
{
    id: string;
    code: string;
    warehouseReasonCode: string;

    localizations: Array<LocalizedStringDto>;
    channelReferences: Array<ChannelReferenceDto>;

    isRemoved: boolean;

    constructor(){
        this.id = "";
        this.code = "";
        this.localizations = [];
        this.channelReferences = [];
        this.warehouseReasonCode = "";
        this.isRemoved = false;
    }
}