

import {
  peopleOutline,
} from "ionicons/icons";

import qs from "qs";
import { onMounted } from 'vue';
import router from '@/router';
import { useAuthStore } from "@/store/authStore";
import { useLogger } from "@/utils/logging";
import { IonPage } from "@ionic/vue";

  export default {
    props: {
      pageName: String,
    },
    components: {
      IonPage,
    },
    name: "ToPage",
    setup (){
      const auth = useAuthStore();
      const {logInfo, logError} = useLogger();

      onMounted(async () => {
        // debugger;
        const hash = window.location.search.substring(1);
        const parsed = qs.parse(hash);
        const mid = parsed.mid as string;
        const cid = parsed.cid as string;
        const redir = parsed.redir as string;

        // console.log(decoded);
        logInfo("Jump to mid:{mid} cid{cid} url{redir}", {mid, cid, redir});

        try {
          // mid & cid are required
          if(!mid || !cid){
            console.log("mid or cid missing");
            router.replace("/");
            return;
          }
          
          const cidNum = parseInt(cid);
          if(isNaN(cidNum)){
            console.log("cid is not a number");
            router.replace("/");
            return;
          }

          // athentication ok, no need to reauth
          if(auth.merchantId == mid && auth.channelId == cidNum && auth.checkAuth()){
            console.log("already logged in");
            router.replace(redir);
            return;
          }

          // try silent login
          try {
            await auth.loginToChannel(mid, cidNum);
            router.replace(redir);
            return;
          } catch (err){
            // silent login failed, try full login
          }

          console.log("to: loginWithRedirect");
          const currentDomain = location.protocol + "//" + location.hostname + (location.port ? ":" + location.port : "");
          const url = auth.myOGO1Domain + "/OAuth/Authorize?merchantId=" + mid + "&channelId=" + cidNum + "&response_type=token&redirect_uri=" + currentDomain + "/auth/return&client_id=myOgo2&redir=" + redir;
          window.location.replace(url);

        } catch (err){          
          logError("ReturnPage auth error:{err} url:{url}", {err, url:window.location});          
          router.replace("/");
        }        
    });


      return {
        peopleOutline,
      }
    }
  };
