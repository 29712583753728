
import { IonPage, IonContent, IonButton, IonIcon, } from '@ionic/vue';
import PageHeader from "@/components/PageHeader.vue";
import PageFooter from "@/components/PageFooter.vue";

import ReturnMenu from "@/views/Return/ReturnMenu.vue";

import { Ref, ref, defineComponent, onMounted, computed, } from 'vue';
import PageDescription from '@/components/PageDescription.vue';

import ProviderSettings from '@/views/Notification/ProviderSettings.vue';
import TemplateList from '@/views/Notification/TemplateList.vue';
import PostPurchaseSettings from '@/views/PostPurchase/PostPurchaseSettings.vue';

import ReturnSettings from '@/views/Return/ReturnSettings.vue';
import ReturnReasons from '@/views/Return/ReturnReasons.vue';

import { useAuthStore } from '@/store/authStore';
import { cloudDoneOutline, } from 'ionicons/icons';

export default defineComponent({
  name: 'PostPurchaseSettingsPage',
  components: {
    PageHeader,
    PageFooter,
    IonPage, 
    IonContent,
    IonButton,
    IonIcon,
    ReturnMenu,
    PageDescription,
    ProviderSettings,
    TemplateList,
    PostPurchaseSettings,
    ReturnReasons,
    ReturnSettings,
  },
  setup (){
    const auth = useAuthStore();
    const ppsSettings: Ref<null|typeof PostPurchaseSettings> = ref(null);
    const returnSettings: Ref<null|typeof ReturnSettings> = ref(null);
    const providerSettings: Ref<null|typeof ProviderSettings> = ref(null);
    const templateList: Ref<null|typeof TemplateList> = ref(null);

    onMounted(async () => {
      if(!auth.merchantId || !auth.channelId)
        return;      
    });

    const save = async () => {
      if(ppsSettings.value?.isDirty)
        await ppsSettings.value?.save();

      if(returnSettings.value?.isDirty)
        await returnSettings.value?.save();

      if(providerSettings.value?.isDirty)
        await providerSettings.value?.save();

      if(templateList.value?.isDirty)
        await templateList.value?.save();
    }

    const isDirty = computed(() => {
      return ppsSettings.value?.isDirty  || returnSettings.value?.isDirty || 
        providerSettings.value?.isDirty || templateList.value?.isDirty || false;
    });

  return { ppsSettings, returnSettings, providerSettings, templateList, save, isDirty, cloudDoneOutline, }
}
});

