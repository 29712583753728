export interface MessageDto {
    uid: string;
    channelId: number;
    content: MessageContentDto;
    externalId?: string;
    contextId?: string;
    sender: EMessageSource;
    receiver: EMessageReceiver;
    messageType: EMessageType;
    createTime: Date;
    deliveryTime?: Date;
    readTime?: Date;
    errorMessage?: string;
    metaData?: { [key: string]: string };
    deleted: boolean;
}

export enum EMessageSource {
    NotDefined = 0,
    Consumer,
    Merchant,
    OgoSupport,
    ChatBot,
    Notification,
}

export enum EMessageReceiver {
    NotDefined = 0,
    Consumer,
    Merchant,
    OgoSupport,
    ChatBot,
}

export enum EMessageType {
    Email,
    Sms,
    Chat,
}

export interface MessageContentDto {
    language?: string;
    content: string;
    subject?: string;
    contentType: EContentType;
}

export enum EContentType {
    Text,
    Html,
    Markdown,
}