import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6ae6d2ea"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "inner-panel dashboard-panel" }
const _hoisted_2 = { class: "inner-panel dashboard-panel" }
const _hoisted_3 = {
  key: 0,
  class: "ui-CountryList"
}
const _hoisted_4 = { class: "country-list-upper" }
const _hoisted_5 = { class: "country-list-upper-left" }
const _hoisted_6 = { class: "country-list-upper-right" }
const _hoisted_7 = { class: "country-list-lower" }
const _hoisted_8 = { class: "ui-ChartLine" }
const _hoisted_9 = { class: "ui-CountryList-render-right" }
const _hoisted_10 = { class: "inner-panel dashboard-panel" }
const _hoisted_11 = {
  key: 0,
  class: "ui-CountryList"
}
const _hoisted_12 = { class: "country-list-upper" }
const _hoisted_13 = { class: "country-list-upper-left" }
const _hoisted_14 = { class: "country-list-upper-right" }
const _hoisted_15 = { class: "country-list-lower" }
const _hoisted_16 = { class: "ui-ChartLine" }
const _hoisted_17 = { class: "ui-CountryList-render-right" }
const _hoisted_18 = { class: "inner-panel dashboard-panel" }
const _hoisted_19 = {
  key: 0,
  class: "ui-CountryList"
}
const _hoisted_20 = { class: "country-list-upper" }
const _hoisted_21 = { class: "country-list-upper-left" }
const _hoisted_22 = { class: "country-list-upper-right" }
const _hoisted_23 = { class: "country-list-lower" }
const _hoisted_24 = { class: "ui-ChartLine" }
const _hoisted_25 = { class: "ui-CountryList-render-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_datetime_button = _resolveComponent("ion-datetime-button")!
  const _component_ion_datetime = _resolveComponent("ion-datetime")!
  const _component_ion_modal = _resolveComponent("ion-modal")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_CTAButton = _resolveComponent("CTAButton")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_InfoGraphic = _resolveComponent("InfoGraphic")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_Doughnut = _resolveComponent("Doughnut")!
  const _component_ion_grid = _resolveComponent("ion-grid")!

  return (_openBlock(), _createBlock(_component_ion_grid, { style: {"margin-top":"20px"} }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_col, { class: "ui-daterange" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_datetime_button, {
                datetime: "datetime1",
                color: "transparent"
              }),
              _createVNode(_component_ion_modal, { "keep-contents-mounted": true }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_datetime, {
                    id: "datetime1",
                    "display-format": "DD.MM.YYYY",
                    presentation: "date",
                    value: _ctx.startDate.toISOString(),
                    onIonChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.startDate = new Date($event.detail.value)))
                  }, null, 8, ["value"])
                ]),
                _: 1
              }),
              _createTextVNode("   -   "),
              _createVNode(_component_ion_datetime_button, { datetime: "datetime2" }),
              _createVNode(_component_ion_modal, { "keep-contents-mounted": true }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_datetime, {
                    id: "datetime2",
                    "display-format": "DD.MM.YYYY",
                    presentation: "date",
                    value: _ctx.endDate.toISOString(),
                    onIonChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.endDate = new Date($event.detail.value)))
                  }, null, 8, ["value"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_col, { size: "auto" }, {
            default: _withCtx(() => [
              (!_ctx.returnsEnabled)
                ? (_openBlock(), _createBlock(_component_CTAButton, {
                    key: 0,
                    code: "ctaReturns",
                    color: "tertiary"
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createVNode(_component_ion_col, {
            size: "auto",
            style: {"text-align":"right"}
          }, {
            default: _withCtx(() => [
              (_ctx.data?.statistics?.returns?.value ?? 0 > 0)
                ? (_openBlock(), _createBlock(_component_ion_button, {
                    key: 0,
                    disabled: _ctx.loadingReport,
                    onClick: _ctx.getReport
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, { icon: _ctx.gridOutline }, null, 8, ["icon"]),
                      _createTextVNode(" " + _toDisplayString(_ctx.loadingReport ? 'Please wait' : 'Report'), 1)
                    ]),
                    _: 1
                  }, 8, ["disabled", "onClick"]))
                : _createCommentVNode("", true),
              (_ctx.checkReportLink && !_ctx.loadingReport)
                ? (_openBlock(), _createBlock(_component_ion_button, {
                    key: 1,
                    color: "secondary",
                    href: _ctx.checkReportLink,
                    download: "ReturnsReport.xlsx"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, { icon: _ctx.downloadOutline }, null, 8, ["icon"]),
                      _createTextVNode(" Save ")
                    ]),
                    _: 1
                  }, 8, ["href"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_col, null, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_ion_row, null, {
                  default: _withCtx(() => [
                    (_ctx.data?.statistics)
                      ? (_openBlock(), _createBlock(_component_ion_col, { key: 0 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_InfoGraphic, {
                              title: "Returns",
                              value: _ctx.data.statistics.returns.value,
                              comparison: _ctx.data.statistics.returns.comparison
                            }, null, 8, ["value", "comparison"])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.data?.statistics?.returnValue)
                      ? (_openBlock(), _createBlock(_component_ion_col, { key: 1 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_InfoGraphic, {
                              title: "Average returns value",
                              "unit-type": _ctx.data.statistics.returnValue.currency,
                              value: _ctx.data.statistics.returnValue.value,
                              comparison: _ctx.data.statistics.returnValue.comparison
                            }, null, 8, ["unit-type", "value", "comparison"])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                })
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_col, null, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_ion_title, null, {
                  default: _withCtx(() => [
                    _createTextVNode("Top 5 returned products")
                  ]),
                  _: 1
                }),
                (_ctx.data?.topProducts)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                      _createElementVNode("ol", null, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.topProducts, (topProduct, index) => {
                          return (_openBlock(), _createElementBlock("li", { key: index }, [
                            _createElementVNode("div", _hoisted_4, [
                              _createElementVNode("div", _hoisted_5, [
                                _createElementVNode("span", null, _toDisplayString(index + 1) + ".", 1),
                                _createElementVNode("p", null, _toDisplayString(topProduct.title), 1)
                              ]),
                              _createElementVNode("div", _hoisted_6, [
                                _createElementVNode("p", null, _toDisplayString(topProduct.percentage) + "%", 1)
                              ])
                            ]),
                            _createElementVNode("div", _hoisted_7, [
                              _createElementVNode("div", _hoisted_8, [
                                _createElementVNode("div", {
                                  class: "percentage-line",
                                  style: _normalizeStyle({
                            width: topProduct.percentage + '%',
                            backgroundColor: _ctx.getBackgroundColor(index)
                          })
                                }, null, 4)
                              ])
                            ])
                          ]))
                        }), 128))
                      ]),
                      _createElementVNode("div", _hoisted_9, [
                        _createVNode(_component_Doughnut, {
                          options: { responsive: true, maintainAspectRatio: false, plugins: { legend: { display: false } } },
                          data: {
                              labels: _ctx.data.topProducts.map(entry => entry.title),
                              datasets: [{
                                data: _ctx.data.topProducts.map(entry => entry.percentage),
                                backgroundColor: _ctx.data.topProducts.map((entry, index) => _ctx.getBackgroundColor(index)),
                                hoverOffset: 4
                              }]
                            }
                        }, null, 8, ["data"])
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_ion_col, null, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_10, [
                _createVNode(_component_ion_title, null, {
                  default: _withCtx(() => [
                    _createTextVNode("Top 5 return reasons")
                  ]),
                  _: 1
                }),
                (_ctx.data?.topProducts)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                      _createElementVNode("ol", null, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.topReasons, (topProduct, index) => {
                          return (_openBlock(), _createElementBlock("li", { key: index }, [
                            _createElementVNode("div", _hoisted_12, [
                              _createElementVNode("div", _hoisted_13, [
                                _createElementVNode("span", null, _toDisplayString(index + 1) + ".", 1),
                                _createElementVNode("p", null, _toDisplayString(topProduct.title), 1)
                              ]),
                              _createElementVNode("div", _hoisted_14, [
                                _createElementVNode("p", null, _toDisplayString(topProduct.percentage) + "%", 1)
                              ])
                            ]),
                            _createElementVNode("div", _hoisted_15, [
                              _createElementVNode("div", _hoisted_16, [
                                _createElementVNode("div", {
                                  class: "percentage-line",
                                  style: _normalizeStyle({
                                        width: topProduct.percentage + '%',
                                        backgroundColor: _ctx.getBackgroundColor(index)
                                    })
                                }, null, 4)
                              ])
                            ])
                          ]))
                        }), 128))
                      ]),
                      _createElementVNode("div", _hoisted_17, [
                        _createVNode(_component_Doughnut, {
                          options: { responsive: true, maintainAspectRatio: false, plugins: { legend: { display: false } } },
                          data: {
                                labels: _ctx.data.topReasons.map(entry => entry.title),
                                datasets: [{
                                    data: _ctx.data.topReasons.map(entry => entry.percentage),
                                    backgroundColor: _ctx.data.topReasons.map((entry, index) => _ctx.getBackgroundColor(index)),
                                    hoverOffset: 4
                                }]
                            }
                        }, null, 8, ["data"])
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_ion_col, null, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_18, [
                _createVNode(_component_ion_title, null, {
                  default: _withCtx(() => [
                    _createTextVNode("Top 5 return countries")
                  ]),
                  _: 1
                }),
                (_ctx.data?.topProducts)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                      _createElementVNode("ol", null, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.topCountries, (topProduct, index) => {
                          return (_openBlock(), _createElementBlock("li", { key: index }, [
                            _createElementVNode("div", _hoisted_20, [
                              _createElementVNode("div", _hoisted_21, [
                                _createElementVNode("span", null, _toDisplayString(index + 1) + ".", 1),
                                _createElementVNode("p", null, _toDisplayString(topProduct.title), 1)
                              ]),
                              _createElementVNode("div", _hoisted_22, [
                                _createElementVNode("p", null, _toDisplayString(topProduct.percentage) + "%", 1)
                              ])
                            ]),
                            _createElementVNode("div", _hoisted_23, [
                              _createElementVNode("div", _hoisted_24, [
                                _createElementVNode("div", {
                                  class: "percentage-line",
                                  style: _normalizeStyle({
                                        width: topProduct.percentage + '%',
                                        backgroundColor: _ctx.getBackgroundColor(index)
                                    })
                                }, null, 4)
                              ])
                            ])
                          ]))
                        }), 128))
                      ]),
                      _createElementVNode("div", _hoisted_25, [
                        _createVNode(_component_Doughnut, {
                          options: { responsive: true, maintainAspectRatio: false, plugins: { legend: { display: false } } },
                          data: {
                                labels: _ctx.data.topCountries.map(entry => entry.title),
                                datasets: [{
                                    data: _ctx.data.topCountries.map(entry => entry.percentage),
                                    backgroundColor: _ctx.data.topCountries.map((entry, index) => _ctx.getBackgroundColor(index)),
                                    hoverOffset: 4
                                }]
                            }
                        }, null, 8, ["data"])
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}