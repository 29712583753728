import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "page-layout" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_header = _resolveComponent("page-header")!
  const _component_PageDescription = _resolveComponent("PageDescription")!
  const _component_AuthenticatedBrowsersList = _resolveComponent("AuthenticatedBrowsersList")!
  const _component_LatestLoginsList = _resolveComponent("LatestLoginsList")!
  const _component_page_footer = _resolveComponent("page-footer")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_page_header, { pageName: "User usage history" }),
      _createVNode(_component_ion_content, { fullscreen: true }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_PageDescription, {
              pageName: "User usage history",
              pageDescription: "See overview of logins and browsers here",
              breadCrumbs: [{title: 'Usage', active: true, url: '/home'}]
            }),
            _createVNode(_component_AuthenticatedBrowsersList),
            _createVNode(_component_LatestLoginsList),
            _createVNode(_component_page_footer)
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}