import { LocalizedStringDto } from "./DeliveryTypes";

export class ReturnsListMerchant {
    id: string;
    merchantCompany: string;
    merchantName: string;
    constructor() {
        this.id = "";
        this.merchantCompany = "";
        this.merchantName = "";
    }
}

export class ReturnsListWarehouse {
    id: string;
    title: string;
    constructor() {
        this.id = "";
        this.title = "";
    }
}

export class ReturnsListProduct {
    id: string;
    title: string;
    quantity: number;
    receivedQuantity: number;
    reason: string;
    price: number;
    sku: string;
    constructor() {
        this.id = "";
        this.title = "";
        this.quantity = 0;
        this.reason = "";
        this.price = 0;
        this.receivedQuantity = 0;
        this.sku = "";
    }
}

export enum RefundStatusEnum {
    NotAvailable = "NotAvailable",
    Validating = "Validating",
    Waiting = "Waiting",
    Refunded = "Refunded",
    Rejected = "Rejected",
}

export enum RefundTypeEnum {
    NotSelected = "NotSelected",
    Refund = "Refund",
    StoreCredit = "StoreCredit",
}

export enum ReturnSourceEnum {
    Unknown = "Unknown",
    Warehouse = "Warehouse",
    Api = "Api",
    Manual = "Manual",
    CustomerCreated = "CustomerCreated"
}


 export class ReturnsListViewRowDto {
    id: string;
    createTime: Date;
    returnedTime: Date | undefined;
    senderCountry: string | undefined;
    returnStatus: string | undefined;
    editTime: Date;
    merchant: ReturnsListMerchant;
    orderExternalId: string | undefined;
    orderId: string | undefined;
    orderCreateTime: Date | undefined;
    senderName: string | undefined;
    deliveryType: string | undefined;
    warehouse: ReturnsListWarehouse;
    serviceType: string | undefined;
    totalProducts: number | undefined;
    skus: number | undefined;
    returnProducts: ReturnsListProduct[];
    refundStatus: RefundStatusEnum | undefined;
    cost: number | undefined;
    customerComments: string | undefined;
    returnTotalValue: number | undefined;
    priceCurrency: string | undefined;
    deliveryTypeId: string | undefined;
    deliveryTypeTitle: LocalizedStringDto[];
    constructor() {
        this.id = "";
        this.createTime = new Date();
        this.returnedTime = new Date();
        this.senderCountry = "";
        this.returnStatus = "";
        this.editTime = new Date();
        this.merchant = new ReturnsListMerchant();
        this.orderExternalId = "";
        this.orderId = "";
        this.orderCreateTime = new Date();
        this.senderName = "";
        this.deliveryType = "";
        this.warehouse = new ReturnsListWarehouse();
        this.serviceType = "";
        this.totalProducts = 0;
        this.skus = 0;
        this.returnProducts = [];
        this.refundStatus = RefundStatusEnum.NotAvailable;
        this.cost = 0;
        this.customerComments = "";
        this.returnTotalValue = 0;
        this.priceCurrency = "";
        this.deliveryTypeId = "";
        this.deliveryTypeTitle = [];
    }
}


export class ReturnDetailedViewDto extends ReturnsListViewRowDto {
    trackingLink: string;
    trackingNumber: string;
    senderPhone: string;
    senderEmail: string;
    returnFeeCollected: number;
    refundValue: number;
    photos: string;
    statusPercentage: number;
    source: ReturnSourceEnum;
    constructor() {
        super();
        this.trackingLink = "";
        this.trackingNumber = "";
        this.senderPhone = "";
        this.senderEmail = "";
        this.returnFeeCollected = 0;
        this.refundValue = 0;
        this.photos = "";
        this.statusPercentage = 0;
        this.source = ReturnSourceEnum.Unknown;
    }
}


export class ReturnDeliveryTypeDto {
    id: string;
    code: string;
    status: string;
    pickupType: string;
    provider: string;
    logo: string;
    availableCountries: string[];
    title: LocalizedStringDto[];
    returnInstructions: LocalizedStringDto[];
    isRemoved: boolean;
    isVisible: boolean;
    indexNumber: number;
    tags: string[];
    channelReferences: ChannelReference[];
    constructor() {
        this.id = "";
        this.code = "";
        this.status = "";
        this.pickupType = "";
        this.provider = "";
        this.logo = "";
        this.availableCountries = [];
        this.title = [];
        this.returnInstructions = [];
        this.isRemoved = false;
        this.isVisible = false;
        this.indexNumber = 0;
        this.tags = [];
        this.channelReferences = [];
    }
}


export class ChannelReference{
    channelId: number;
    constructor() {
        this.channelId = 0;
    }
}

