
import { IonPage, IonContent, IonGrid, IonRow, IonCol, IonButton, } from '@ionic/vue';
import PageHeader from "@/components/PageHeader.vue";
import PageFooter from "@/components/PageFooter.vue";

import { defineComponent, onMounted, ref, Ref, } from 'vue';

import PageDescription from '@/components/PageDescription.vue';
import { useNotificationStore } from '@/store/notificationStore';
import { WebHookRedisListModel, WebHookStatus } from '@/models/notificationService/WebHooks';
import { useErrorBox } from '@/components/errorBox';
import router from '@/router';

import moment from 'moment-timezone';

export default defineComponent({
  name: 'UserUsagePage',
  components: { 
    PageHeader,
    PageFooter,
    PageDescription,
    IonPage, 
    IonContent,
    IonGrid,
    IonRow,
    IonCol,
    IonButton,
  },
  setup () {
    const notificationStore = useNotificationStore();
    const messages: Ref<Array<WebHookRedisListModel>> = ref([]);
    const webHookStats: Ref<WebHookStatus|undefined> = ref(undefined);

    const momentjs = (a: any): moment.Moment => { return moment(a); }
    const { showError } = useErrorBox();

    const initialize = async ()=> {
      try {
        const id = router.currentRoute.value.params["id"].toString();
        messages.value = await notificationStore.getWebHookMessages(id);
        webHookStats.value = await notificationStore.getWebHookStats(id);
      } catch (err) {
        await showError(err, "Error"); 
      }
    }

    onMounted(async () => {
      await initialize();
    });

    const removeOneFromList = async () => {
      try {
        const id = router.currentRoute.value.params["id"].toString();
        messages.value = await notificationStore.removeWebHookMessage(id);
      } catch (err) {
        await showError(err, "Error"); 
      }
    }

    const resendWebhooks = async () => {
      try {
        const id = router.currentRoute.value.params["id"].toString();
        await notificationStore.resendWebHook(id);
      } catch (err) {
        await showError(err, "Error"); 
      }
    }

    return { 
      webHookStats, messages, removeOneFromList, resendWebhooks, momentjs,
    }
  }
});

