
  import { IonPage, IonContent } from '@ionic/vue';
  import PageHeader from "@/components/PageHeader.vue";
  import PageFooter from "@/components/PageFooter.vue";
  import ReturnDashboardComponent from "@/views/Return/ReturnDashboardComponent.vue";
  import { defineComponent } from 'vue';
  import PageDescription from '@/components/PageDescription.vue';
  import DashboardMenu from '@/views/Dashboard/DashboardMenu.vue';
  
  
  export default defineComponent({
    name: 'ReturnDashboardPage',
    components: { 
      PageHeader,
      PageFooter,
      IonPage, 
      IonContent,
      DashboardMenu,
      ReturnDashboardComponent,
      PageDescription,
    },
    data() {
      return {
        chartOptions: {
          responsive: true
        }
      }
    },
    setup (){
        return {}
      }
  });
  
  