import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_img = _resolveComponent("ion-img")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createVNode(_component_ion_button, {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.close()))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_icon, { src: _ctx.closeOutline }, null, 8, ["src"]),
          _createTextVNode(" Close")
        ]),
        _: 1
      }),
      _createVNode(_component_ion_button, {
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.savePhoto(_ctx.photoUrl)))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_icon, { src: _ctx.saveOutline }, null, 8, ["src"]),
          _createTextVNode(" Save")
        ]),
        _: 1
      }),
      (_ctx.nextAvailable)
        ? (_openBlock(), _createBlock(_component_ion_button, {
            key: 0,
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.nextPhoto()))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_icon, { src: _ctx.arrowForwardOutline }, null, 8, ["src"]),
              _createTextVNode(" Next")
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_ion_img, { src: _ctx.photoUrl }, null, 8, ["src"])
    ])
  ], 64))
}