
  import { IonTitle, IonPage, IonList, IonItem, IonGrid, 
    IonRow, IonCol, IonButton, IonContent, loadingController, IonLabel, IonText, IonSelect, IonSelectOption, IonInput, toastController, IonToggle, IonModal, IonHeader, IonToolbar, IonButtons,
  } from '@ionic/vue';
  import { computed, defineComponent, onMounted, ref, Ref } from 'vue';
  import { useRouter } from 'vue-router';
  import { trashOutline, addOutline, } from 'ionicons/icons';

  import PageHeader from "@/components/PageHeader.vue";
  import PageFooter from "@/components/PageFooter.vue";
  import PageDescription from '@/components/PageDescription.vue';

  import * as moment from 'moment-timezone';
  import { useErrorBox } from '@/components/errorBox';
  import { useAuthStore } from '@/store/authStore';
  import { useTrackingStore } from '@/store/trackingStore';
  import { MerchantIssueUpdateDto, ShipmentDto, ShipmentIssueEnum, OgoIssueUpdateDto, TrackingEventDto } from '@/models/trackingService/Shipment';
  import { stringToHash } from '@/utils/changeTracking';
  import { ShipmentTrackingStatus } from '@/models/trackingService/ShipmentMetrics';
import { IsLateUpdateDto } from '../../models/trackingService/Shipment';
import { useNotificationStore } from '@/store/notificationStore';
import { MessageDto,EMessageReceiver } from '@/models/notificationService/Messaging';

  export default defineComponent({
    name: 'TrackingDetails',
    components: { 
      IonPage,
      IonTitle,
      IonList, IonItem, IonGrid, IonRow, IonCol, IonContent, IonSelect, IonSelectOption,
      IonModal, IonHeader, IonToolbar, IonButtons,
      IonButton,
      IonInput,
      // IonTextarea,  
      // IonIcon,
      IonText,
      IonLabel,
      PageHeader,
      PageFooter,
      PageDescription,
      IonToggle,
    },
    setup(){
    
      const router = useRouter();
      const authStore = useAuthStore();
      const trackingStore = useTrackingStore();
      const auth = useAuthStore();
      const notificationStore = useNotificationStore();
      const { showError } = useErrorBox();
      const trackingEventSelected = ref<ShipmentTrackingStatus>(ShipmentTrackingStatus.Delivered);
      const trackingEventMessage = ref<string>();
      const isLateToggleValue = ref<boolean>(false);

      const emailPreviewOpen = ref<boolean>(false);
      const previewMessage = ref<MessageDto|undefined>(undefined);

      const shipmentDetails = ref<ShipmentDto|undefined>(undefined);
      const messages = ref<Array<MessageDto>>([]);


      const issueOptions =  [ 
        {id: ShipmentIssueEnum.None, title: "None"},
        {id: ShipmentIssueEnum.NewIssue, title: "Unhandled issue"},
        {id: ShipmentIssueEnum.Investigate, title: "Investigate"},
        {id: ShipmentIssueEnum.Solved, title: "Solved"},
        {id: ShipmentIssueEnum.Ignore, title: "Ignore"},
      ];

      const trackingEventOptions =  [ 
        {id: ShipmentTrackingStatus.InTransit, title: "In transit"},
        {id: ShipmentTrackingStatus.WithCourier, title: "With courier"},
        {id: ShipmentTrackingStatus.PickUpReady, title: "Pick up ready"},
        {id: ShipmentTrackingStatus.DeliveryAttempted, title: "Delivery attempted"},
        {id: ShipmentTrackingStatus.Delivered, title: "Delivered"},
        {id: ShipmentTrackingStatus.Returned, title: "Returned"},
        {id: ShipmentTrackingStatus.ReturnInTransit, title: "Return in transit"},
        {id: ShipmentTrackingStatus.ReturnDelivered, title: "Return delivered"},
        {id: ShipmentTrackingStatus.ReturnCompleted, title: "Return completed"},
        {id: ShipmentTrackingStatus.Exception, title: "Exception"},
      ];

      const isAdmin = computed(() => { return auth.filteredRoles.includes("Admins"); });

      const momentjs: any = moment;
      moment.locale(auth.locale);
      
      const loading = async () => {
        const loading = await loadingController.create({
          cssClass: "my-custom-class",
          message: "Processing...",
          duration: undefined,
        });

        await loading.present();
        return loading;
      };

      onMounted( async() => {
        const id = router.currentRoute.value.params["id"].toString();
        const channel = router.currentRoute.value.params["channel"].toString();

        if(!id) {
          router.back();
          return;
        } 
          
        // Show loading dialog while loading
        const l = await loading();
        try {
          shipmentDetails.value = await trackingStore.getShipment( id, Number.parseInt(channel));
          savedHash.value = hashComponentState.value;
          adminSavedHash.value = adminHashComponentState.value;

          messages.value = await notificationStore.getMessages(Number.parseInt(channel),id);

        } catch (error) {
          showError(error, "Error");
        } finally {
          l.dismiss();
        }          

      });

      const save = async () => {
      if(!auth.merchantId || !auth.channelId || !shipmentDetails.value)
        return;

        try {
          
          const issue = new MerchantIssueUpdateDto(shipmentDetails.value.merchantIssue);

          await trackingStore.saveMerchantIssue(shipmentDetails.value.channelId, shipmentDetails.value.uid, issue);      

          savedHash.value = hashComponentState.value;

          const toast = await toastController.create({
            message: "Tracking issue saved successfully!",
            duration: 1500,
            position: "top",
            color: "primary",
          });

          await toast.present();

        } catch (e) {
          showError(e as string, "Error saving settings!");
        }
    }

    const adminSave = async () => {
      if(!auth.merchantId || !auth.channelId || !shipmentDetails.value)
        return;

        try {
          
          const issue = new OgoIssueUpdateDto();
          issue.internalComment = shipmentDetails.value.internalComment;
          issue.ogoIssue = shipmentDetails.value.ogoIssue;

          await trackingStore.saveOgoIssue(shipmentDetails.value.channelId, shipmentDetails.value.uid, issue);      

          adminSavedHash.value = adminHashComponentState.value;

          const toast = await toastController.create({
            message: "Admin tracking issue saved successfully!",
            duration: 1500,
            position: "top",
            color: "primary",
          });

          await toast.present();

        } catch (e) {
          showError(e, "Error saving settings!");
        }
    }

      //#region isDirty check for relative simple components
      const savedHash: Ref<number> = ref(0);
      const hashComponentState = computed(() => {
        return stringToHash(JSON.stringify( {
          merchantIssue: shipmentDetails.value?.merchantIssue,
        }));
      });

      const isDirty = computed(() => {
        return savedHash.value !== hashComponentState.value;
      });

      // admin section isDirty
      const adminSavedHash: Ref<number> = ref(0);
      const adminHashComponentState = computed(() => {
        return stringToHash(JSON.stringify( {
          ogoIssue: shipmentDetails.value?.ogoIssue,
          internalComment: shipmentDetails.value?.internalComment,
        }));
      });

      const adminIsDirty = computed(() => {
        return adminSavedHash.value !== adminHashComponentState.value;
      });


      const myOgoLink = computed(() => {
        if(shipmentDetails.value)
          return `${authStore.myOGO1Domain}/order/edit/${shipmentDetails.value.uid}`;
        return "";
      });


      const openMyOgo = (e:any) => {
        if(myOgoLink.value)
        {
            if(e.ctrlKey)
                window.open(myOgoLink.value);
            else
                window.location.assign(myOgoLink.value);
        }
      }

      const refresh = async () => {
        if(!shipmentDetails.value)
          return;
        const l = await loading();
        try {
          await trackingStore.getTracking( shipmentDetails.value.uid, shipmentDetails.value.channelId);
          shipmentDetails.value = await trackingStore.getShipment( shipmentDetails.value.uid, shipmentDetails.value.channelId);
        } catch (error) {
          showError(error, "Error");
        } finally {
          l.dismiss();
        }          
      }

      const addEvent = async () => {
        if(!shipmentDetails.value || !trackingEventSelected.value)
          return;

        const l = await loading();
        try {
          const tEvent = new TrackingEventDto();

          tEvent.eventType = trackingEventSelected.value;
          tEvent.timeStamp = new Date(Date.now());
          tEvent.location = "-";
          tEvent.message = trackingEventMessage.value;

          shipmentDetails.value = await trackingStore.addTrackingEvent(shipmentDetails.value.channelId, shipmentDetails.value.uid, tEvent);

        } catch (error) {
          showError(error, "Error");
        } finally {
          l.dismiss();
        } 
        
      }

      const setIsLate = async () => {
        if(!shipmentDetails.value || !trackingEventSelected.value)
          return;

        const l = await loading();
        try {
          const isLateUpdate = new IsLateUpdateDto();
          isLateUpdate.isLate = isLateToggleValue.value;

          shipmentDetails.value = await trackingStore.setIsLate(shipmentDetails.value.channelId, shipmentDetails.value.uid, isLateUpdate);

        } catch (error) {
          showError(error, "Error");
        } finally {
          l.dismiss();
        } 
        
      }

      return {
        openMyOgo,
        momentjs,
        trashOutline,
        addOutline,
        shipmentDetails,
        issueOptions,
        refresh,
        save,
        adminSave,
        isDirty,
        adminIsDirty,
        isAdmin,
        trackingEventOptions,
        trackingEventSelected,
        trackingEventMessage,
        addEvent,
        isLateToggleValue,
        setIsLate,
        messages,
        emailPreviewOpen, previewMessage, EMessageReceiver,
      }
    }
  });
