
import { computed, defineComponent, ref } from 'vue';
import { TreeItemSource } from '@/models/components';
import { chevronDownOutline, chevronForwardOutline, } from 'ionicons/icons';
import { IonButton, IonIcon } from '@ionic/vue';


 export default defineComponent({
  name: "TreeItem",
  props: {
    source: TreeItemSource,
  },
  components: {
    IonButton,
    IonIcon,
  },
  emits: {
      openDetails: ( idList:Array<string>, dimension: string|undefined) => true,
    },
  setup (props, emits){

    const formatter = new Intl.NumberFormat('fi-FI', { style: 'currency', currency: 'EUR' });

    const dimensionsVisible = ref(false);
    const descriptionVisible = ref(false);

    const amount = computed (() => {
      if(!props.source)
        return formatter.format(0);

      if(props.source.expanded)
        return formatter.format(props.source.amount);

      return formatter.format(props.source.getTotalAmount());
      });

      const altAmount = computed (() => {
        if(!props.source)
          return formatter.format(0);

        if(props.source.expanded)
            return formatter.format(props.source.altAmount);

        return formatter.format(props.source.getTotalAltAmount());
      });

      const quantity = computed (() => {
        if(!props.source)
          return 0;

        if(props.source.expanded)
            return props.source.quantity;

        return props.source.getTotalQuantity();
      });

      const openDetails = () => {

        // debugger;
        if(!props.source)
          return;

        emits.emit('openDetails', !props.source.expanded ? props.source.getIdList() : [props.source.id], undefined);
      }

      const forwardOpenDetails = (idList:Array<string>, dimension: string|undefined) => {
        emits.emit('openDetails', idList, dimension);
      }


      return {
        amount, altAmount, dimensionsVisible, quantity,
        chevronDownOutline, chevronForwardOutline,
        formatter, descriptionVisible,
        openDetails, forwardOpenDetails,
      }
    }
});
