import { CountryDto } from "../configService/Country";

export class LocalizedStringDto
{
    lang: string;
    text: string;

    constructor(){
        this.lang = "";
        this.text = "";
    }
}

export enum ReturnDeliveryTypeStatus {
    Draft = 0,
    Active,
    Cancelled,
}

export enum ReturnDeliveryPickupType
{
	Home = 0,
	ServicePoint,
}

export enum ReturnPickupTimePickerType
{
	None = 0,
    NextBusinessDay = 1,
}

export class ReturnCountryInfoDto {
    countryCode: string;
    warehouses: Array<string>;
    constructor() {
        this.countryCode = "";
        this.warehouses = [];
    }
}

export class ChannelReference {
    channelId = 0;
    name = "";
}


export class ReturnDeliveryTypeDto {
    id: string | undefined;
    docVersion: number;
    code: string;
    status: ReturnDeliveryTypeStatus;
    pickupType: ReturnDeliveryPickupType;
    pickupTimeType: ReturnPickupTimePickerType;
    provider: string;
    logo: string;
    availableCountries: Array<ReturnCountryInfoDto>;
    title: Array<LocalizedStringDto>;
    returnInstructions: Array<LocalizedStringDto>;
    pickupSelectInstructions: Array<LocalizedStringDto>;
    isRemoved: boolean;
    isVisible: boolean;
    indexNumber: number;
    tags: Array<string>;
    channelReferences: Array<ChannelReference>;
    constructor() {
        this.id = undefined;
        this.docVersion = 0;
        this.code = "";
        this.status = ReturnDeliveryTypeStatus.Draft;
        this.pickupType = ReturnDeliveryPickupType.Home;
        this.pickupTimeType = ReturnPickupTimePickerType.None;
        this.provider = "";
        this.logo = "";
        this.availableCountries = [];
        this.title = [];
        this.returnInstructions = [];
        this.pickupSelectInstructions = [];
        this.isRemoved = false;
        this.isVisible = false;
        this.indexNumber = 0;
        this.tags = [];
        this.channelReferences = [];
    }
}

export class ReturnDeliveryTypeUpdateDto {
    code: string | undefined;
    status: ReturnDeliveryTypeStatus | undefined;
    pickupType: ReturnDeliveryPickupType | undefined;
    pickupTimeType: ReturnPickupTimePickerType | undefined;
    provider: string | undefined;
    logo: string | undefined;
    availableCountries: Array<ReturnCountryInfoDto> | undefined;
    title: Array<LocalizedStringDto> | undefined;
    returnInstructions: Array<LocalizedStringDto> | undefined;
    pickupSelectInstructions: Array<LocalizedStringDto> | undefined;
    isRemoved: boolean | undefined;
    isVisible: boolean | undefined;
    indexNumber: number | undefined;
    tags: string[] | undefined;
    channelReferences: Array<ChannelReference> | undefined;
}

export class ReturnDeliveryTypeEditorCountryInfo {
    country: CountryDto = new CountryDto();
    warehouses: Array<ReturnDeliveryTypeEditorWarehouse> = []
}

export class ReturnDeliveryTypeEditorWarehouse {
    id = "";
    code = "";
    enabled = false;
    
}