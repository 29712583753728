
import { defineComponent, Ref, ref, type PropType, onMounted } from 'vue';
import { IonInput, } from "@ionic/vue";
import { filterOutline } from "ionicons/icons";
import { ColumnDefinition } from '@/models/orderService/ReturnsListFilter';

export default defineComponent({
  name: "TextSearch",
  props: {
    title: { type: String, required: true },
    filterKey:  { type: String, required: true },
    filter: { type: Object as PropType<ColumnDefinition>, required: true },
  },
  emits: {
    filterChanged: (_event: any, _filter: any, _value: any, _text: any) => true,
  },
  components: {
    IonInput,
  },
  setup (props, emits){

    const text: Ref<string> = ref("");    
    onMounted (() => {
      const oldValue = (props.filter?.inlineSearchSelectObject as any)?.value;

      if(oldValue)
        text.value = oldValue;
      else
        text.value = "";
    });

    const filterChanged = (event: any) => {
      emits.emit('filterChanged', props.filterKey, props.filter, {'value': event.detail.value}, event.detail.value as string);
    }

    return { filterChanged,filterOutline,text}
  }
});
