
import {
  IonPage, IonContent, IonGrid, IonRow, IonCol, IonInput, IonTitle,
  IonItem, IonSelect, IonSelectOption, IonButton, IonIcon, loadingController, IonModal, IonDatetime,
  alertController, IonLabel, IonHeader, IonToolbar, IonButtons,
} from '@ionic/vue';
import PageHeader from "@/components/PageHeader.vue";
import PageFooter from "@/components/PageFooter.vue";

import AdminMenu from "@/views/Admin/AdminMenu.vue";

import { computed, defineComponent, onMounted, ref, Ref } from 'vue';
import PageDescription from '@/components/PageDescription.vue';

import { useAuthStore } from '@/store/authStore';
import { useConfigStore } from '@/store/configStore';
import { useRouter } from 'vue-router';

//import { save } from 'ionicons/icons';
import { useErrorBox } from '@/components/errorBox';
import { stringToHash } from '@/utils/changeTracking';
import { cloudDoneOutline, closeOutline } from 'ionicons/icons';
import { NewCostCodeDto, NewCostDto, ProductAdminDto, ProductAdminUpdateDto } from '@/models/configService/WarehouseProduct';
import { WarehouseDto } from '@/models/warehouseService/Warehouse';
import { ActionTypes, useStore } from '@/store';
import { InvoicingCategoryDto } from '@/models/umbraco/invoicingCategory';
import { CurrencyOption } from '@/models/configService/Currency';

import moment from 'moment-timezone';

export default defineComponent({
  name: 'WarehouseProductDetailsPage',
  components: {
    PageHeader,
    PageFooter,
    IonPage,
    IonContent,
    IonGrid, IonRow, IonCol, IonInput, IonTitle, IonItem, IonLabel,
    IonIcon,
    IonButton,
    PageDescription,
    AdminMenu,
    IonSelect,
    IonSelectOption,
    IonModal,
    IonDatetime,
    IonHeader, IonToolbar, IonButtons,
  },
  setup() {
    const auth = useAuthStore();
    const store = useStore();
    const configStore = useConfigStore();
    const warehouseProductDetails: Ref<ProductAdminDto> = ref(new ProductAdminDto);
    const router = useRouter();
    const loaded = ref(false);
    const { showError } = useErrorBox();
    const warehouses: Ref<Array<WarehouseDto>> = ref([]);
    const invoicingCategories: Ref<Array<InvoicingCategoryDto>> = ref([]);
    const currencies: Ref<Array<CurrencyOption>> = ref([]);
    const newDiscountGroup: Ref<string> = ref("");
    const activeWarehouses: Ref<Array<string>> = ref([]);
    const newCostItem: Ref<NewCostDto> = ref(new NewCostDto);
    const editorModalOpen = ref(false);
    const originalCode = ref("");

    const category1List = ["Warehousing", "PickAndPack", "Logistics", "Services"]

    // const momentjs = moment;
    const momentjs = (a: any): moment.Moment => { return moment(a); }

    const loading = async () => {
      const loading = await loadingController.create({
        cssClass: "my-custom-class",
        message: "Processing...",
        duration: undefined,
      });

      await loading.present();
      return loading;
    };

    onMounted(async () => {
      if (!auth.filteredRoles.includes("Admins"))
        return;

      const l = await loading();
      try {
        currencies.value = await configStore.getCurrencies();
        invoicingCategories.value = await configStore.getInvoicingCategories();
        warehouses.value = await store.dispatch(ActionTypes.WS_GET_WAREHOUSES, undefined);

        originalCode.value = router.currentRoute.value.params["code"]?.toString();
        if (originalCode.value != null && originalCode.value != "") {
          warehouseProductDetails.value = await configStore.getWarehouseProduct(originalCode.value);
          activeWarehouses.value = warehouseProductDetails.value.warehouses.filter(t => t.warehouseId !== undefined).map(i => i.warehouseId!);
        }

        // Load title from resources
        const resource = (await configStore.getResources("InternalProducts")).find(i => i.key === originalCode.value);
        if (resource != undefined) {
          warehouseProductDetails.value.title = resource.resourceValues.find(i => i.locale === "")?.value ?? "";
        }

      } catch (error) {
        await showError(error, "Error loading warehouse product details");
      } finally {
        await l.dismiss();
      }

      loaded.value = true;
      savedHash.value = hashComponentState.value;

    });

    const selectDate = (message: CustomEvent) => {
      // console.log("saving", newCostItem.value.from, message.detail.value,momentjs(message.detail.value).utc(true).toISOString(), momentjs(message.detail.value).utc(true).startOf('day').toISOString());
      // force to utc and midnight to keep the date the same
      newCostItem.value.from = momentjs(message.detail.value).utc(true).startOf('day').toISOString();
    };


    const addCostModalOpen = (warehouse: string) => {
      newCostItem.value = new NewCostDto();
      newCostItem.value.from = momentjs(new Date()).utc(true).startOf('month').toISOString();
      newCostItem.value.id = warehouse;
      editorModalOpen.value = true; 
    }
  
    const deleteCostAlert = async (costCodeId: string, from: string) => {
      if (!auth.filteredRoles.includes("Admins") || costCodeId == undefined || costCodeId == "")
        return;
    
      const alert = await alertController.create({
        header: 'Delete cost',
        message: 'Are you sure you want to delete this cost?',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
          }
          , {
            text: 'Delete',
            handler: () => {
              removeCostFromWarehouse(costCodeId, from);
            }
          }
        ]});

      await alert.present();
    };

    // Delete warehouse product alert
    const deleteWarehouseProductAlert = async (costCodeId: string) => {
      if (!auth.filteredRoles.includes("Admins") || costCodeId == undefined || costCodeId == "")
        return;

      const alert = await alertController.create({
        header: 'Delete warehouse',
        message: 'Are you sure you want to delete this warehouse from the product?',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
          }
          , {
            text: 'Delete',
            handler: () => {
              removeWarehouse(costCodeId);
            }
          }
        ]});

      await alert.present();
    };

    const save = async () => {

      if (!auth.filteredRoles.includes("Admins"))
        return;

      if (warehouseProductDetails.value.warehouses.length == 0) {
        await showError("Must have at least 1 warehouse.", "Error saving warehouse product");
        return;
      }

      const l = await loading();
      try {
        // save title to resources
        const title = warehouseProductDetails.value.title;
        if(title != undefined && title != "")
          await configStore.updateResourceValue("InternalProducts", warehouseProductDetails.value.code, "", title);

        const updateModel = new ProductAdminUpdateDto();
        updateModel.code = warehouseProductDetails.value.code;
        updateModel.discountGroups = warehouseProductDetails.value.discountGroups;
        updateModel.costCategory = warehouseProductDetails.value.costCategory;
        updateModel.categoryId = warehouseProductDetails.value.categoryId;
        warehouseProductDetails.value = await configStore.updateWarehouseProduct(originalCode.value, updateModel);

        // Return title from resources
        warehouseProductDetails.value.title = title;

        savedHash.value = hashComponentState.value;

        if (originalCode.value != warehouseProductDetails.value.code) {
          router.replace("/admin/warehouseProducts/edit/" + warehouseProductDetails.value.code);
          originalCode.value = warehouseProductDetails.value.code;
        }
      } catch (error) {
        await showError(error, "Error saving warehouse product");
      } finally {
        await l.dismiss();
      }
    };

    const removeWarehouse = async (costCodeId: string) => {
      if (!auth.filteredRoles.includes("Admins") || costCodeId == undefined || costCodeId == "")
        return;
      try {
        warehouseProductDetails.value = await configStore.removeWarehouseProduct(costCodeId);
        activeWarehouses.value = warehouseProductDetails.value.warehouses.filter(t => t.warehouseId !== undefined).map(i => i.warehouseId!);
      } catch (error) {
        await showError(error, "Error removing warehouse");
      }
    };

    const removeCostFromWarehouse = async (costCodeId: string, from: string) => {
      if (!auth.filteredRoles.includes("Admins") || costCodeId == undefined || costCodeId == "")
        return;
      try {
        warehouseProductDetails.value = await configStore.removeCostFromWarehouseProduct(costCodeId, from);
      } catch (error) {
        await showError(error, "Error removing cost from warehouse");
      }
    }

    const removeDiscountGroup = (discount: string) => {
      warehouseProductDetails.value.discountGroups = warehouseProductDetails.value.discountGroups.filter(i => i !== discount);
    }

    const addDiscountGroup = (newDiscount: string) => {
      const splitDiscounts = newDiscount.split(",");
      splitDiscounts.forEach(i => {
        if (i.trim() !== "" && !warehouseProductDetails.value.discountGroups.includes(i.trim()))
          warehouseProductDetails.value.discountGroups.push(i.trim());
      });
    }

    const addWarehouse = async (warehouseIds: Array<string>) => {
      if (warehouseIds == undefined || warehouseIds.length == 0)
        return;

      for (const warehouseId of warehouseIds) {
        if (warehouseId != undefined && warehouseId != "" && warehouseProductDetails.value.warehouses.find(i => i.warehouseId == warehouseId) == undefined) {
          const newCostCode: NewCostCodeDto = {
            warehouseId: warehouseId, code: warehouseProductDetails.value.code,
            discountGroups: warehouseProductDetails.value.discountGroups, costCategory: warehouseProductDetails.value.costCategory,
            categoryId: warehouseProductDetails.value.categoryId
          };
          warehouseProductDetails.value = await configStore.addWarehouseProduct(warehouseProductDetails.value.code, newCostCode);
          // Added new warehouse product
          if (originalCode.value != warehouseProductDetails.value.code) {
            router.replace("/admin/warehouseProducts/edit/" + warehouseProductDetails.value.code);
            originalCode.value = warehouseProductDetails.value.code;
          }
        }
      }

    }

    const addCost = async () => {

      if (newCostItem.value.from == undefined || newCostItem.value.id == undefined)
        return;

      try {
        warehouseProductDetails.value = await configStore.addWarehouseCost(newCostItem.value);
        editorModalOpen.value = false;
      } catch (error) {
        await showError(error, "Error adding new cost for warehouse");
      }

    }

    //#region isDirty check for relative simple components
    const savedHash: Ref<number> = ref(0);
    const hashComponentState = computed(() => {

      return stringToHash(JSON.stringify({
        code: warehouseProductDetails.value.code,
        title: warehouseProductDetails.value.title,
        discountGroups: warehouseProductDetails.value.discountGroups,
        costCategory: warehouseProductDetails.value.costCategory,
        categoryId: warehouseProductDetails.value.categoryId,          
      }));
    });

    const isDirty = computed(() => {
      if (savedHash.value === 0)
        return false;
      return savedHash.value !== hashComponentState.value;
    });
    //#endregion

    return {
      isDirty, cloudDoneOutline,
      loaded, warehouseProductDetails, save, warehouses, invoicingCategories, currencies,
      removeDiscountGroup, addDiscountGroup, newDiscountGroup, addWarehouse, activeWarehouses, removeCostFromWarehouse, newCostItem,
      editorModalOpen, category1List, removeWarehouse, addCost, originalCode, deleteCostAlert, deleteWarehouseProductAlert,
      momentjs, selectDate, addCostModalOpen,
      closeOutline,
    }

  }
});

