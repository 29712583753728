
import { IonPage, IonContent, } from '@ionic/vue';
import PageHeader from "@/components/PageHeader.vue";
import PageFooter from "@/components/PageFooter.vue";

import { defineComponent, } from 'vue';

import PageDescription from '@/components/PageDescription.vue';
import AuthenticatedBrowsersList from '@/views/Auth/AuthenticatedBrowsersList.vue';
import LatestLoginsList from '@/views/Auth/LatestLoginsList.vue';

export default defineComponent({
  name: 'UserUsagePage',
  components: { 
    PageHeader,
    PageFooter,
    PageDescription,
    IonPage, 
    IonContent,
    AuthenticatedBrowsersList,
    LatestLoginsList,
  },
  setup () {
    return {
    }
  }
});

