
export class LimitConfig{
    skip: number;
    take: number;
    constructor() {
        this.skip = 0;
        this.take = 0;
    }
}

export class ReturnsListViewFilter {
    sort: { [id: string]: boolean; }
    globalSearch: string;
    limit: LimitConfig;

    constructor() {
        this.sort = {};
        this.globalSearch = "";
        this.limit = new LimitConfig();
    }
}

export class ColumnDefinition {
    inlineSearch: string;
    inlineSearchSelectTitle: string | undefined;
    inlineSearchSelectObject: object | undefined;
    isSortable: boolean;
    isVisible: boolean | undefined;
    sortOrder: number | undefined;
    sortDesc: boolean | undefined;
    columnData: {[id: string]: any };
    constructor() {
        this.inlineSearch = "";
        this.isSortable = false;
        this.columnData = {};
    }
}

export class ColumnSavedState {
    title: string | undefined;
    data: string;
    sortOrder: number | undefined;
    sortDesc: boolean | undefined;
    visible: boolean | undefined;
    constructor() {
        this.data = "";
        this.visible = undefined;
    }
}
export class SavedColumns  {
    [id: string]: ColumnSavedState;
}

export class ListDefinitions  {
    [id: string]: ColumnDefinition;
}

export class SearchDefinition{
        inlineSearch: { [id: string]: any; };
        limit: LimitConfig;
        globalSearch: string| undefined;
        sort: { [id: string]: boolean; }
        constructor () {
            this.limit = new LimitConfig();           
            this.sort = {};
            this.inlineSearch = {};
        }
}

export class ListViewState {
    columnState: SavedColumns;
    constructor() {
        this.columnState = {};
    }

    stateFrom(definitions: ListDefinitions) {
        this.columnState = {};

        Object.entries(definitions)
        .filter(e => e[1].sortOrder !== undefined || e[1].inlineSearchSelectObject !== undefined)
        .forEach(e => {

            const col = new ColumnSavedState();            
            if(e[1].sortOrder !== undefined) {
                col.sortOrder = e[1].sortOrder;
                col.sortDesc = e[1].sortDesc;
            }

            if(e[1].inlineSearchSelectObject !== undefined){
                col.data = JSON.stringify(e[1].inlineSearchSelectObject);
                col.title = e[1].inlineSearchSelectTitle;
            }

            this.columnState[e[0]] = col;
        });
    }

    static stateTo(savedState: ListViewState, definitions: ListDefinitions) {
        Object.entries(definitions)
        .filter(e => savedState.columnState[e[0]] !== undefined)
        .forEach(e => {
            e[1].sortOrder = savedState.columnState[e[0]].sortOrder;
            e[1].sortDesc = savedState.columnState[e[0]].sortDesc;
            e[1].inlineSearchSelectTitle = savedState.columnState[e[0]].title;

            if(savedState.columnState[e[0]].data)
                e[1].inlineSearchSelectObject = JSON.parse(savedState.columnState[e[0]].data);
        });
    }
}



// {
//   "inlineSearch": {
//   },
//   "limit": {
//     "skip": 0,
//     "take": 50
//   },
//   "sort": {
//   },
//   "globalSearch": "",
//   "custom": {}
// }