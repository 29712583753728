
import { IonPage, IonRow, IonGrid, IonCol, IonItem, IonLabel, IonModal, toastController, 
IonTitle,IonIcon,IonButton,IonList,IonButtons,IonToolbar,IonHeader,IonContent, } from '@ionic/vue';
import { images, documentOutline, copyOutline, trashOutline, downloadOutline } from 'ionicons/icons';
import { defineComponent, onMounted, ref, Ref } from 'vue';
import PageDescription from '@/components/PageDescription.vue';
import PageFooter from "@/components/PageFooter.vue";
import PageHeader from "@/components/PageHeader.vue";
import { MerchantsFileDto } from '@/models/fileService/File';
import * as moment from 'moment-timezone';
import { useErrorBox } from '@/components/errorBox';
import { useAuthStore } from '@/store/authStore';
import { useFileStore } from '@/store/fileStore';

export default defineComponent({
  name: 'ReportsList',
  components: { 
    IonPage,
    IonRow,
    IonGrid,
    IonCol,
    IonItem,
    IonModal,
    PageDescription,
    PageFooter,
    PageHeader,
    IonLabel,
    IonTitle,
    IonIcon,
    IonButton,
    IonList,
    IonButtons,
    IonToolbar,
    IonHeader,
    IonContent,
  },
  setup(){
  
    const auth = useAuthStore();
    const fileStore = useFileStore();

    const reportsList: Ref<Array<MerchantsFileDto>> = ref([]);
    const momentjs: any = moment;
    const isModalOpen = ref(false);
    const deleteId = ref("");
    const deleteName = ref("");
    const { showError } = useErrorBox();

    onMounted( async() => {
        if(!auth.merchantId || !auth.channelId) {
          return;
        }

        try {
          reportsList.value = await fileStore.getMerchantFiles(auth.merchantId, "reports");
        } catch (e) {
          showError(e, "Error while getting reports!");
        }

    });

    const copyToClipboard = async (text: string) => {
      navigator.clipboard.writeText(text);

      const toast = await toastController.create({
            message: "Url copied to clipboard",
            duration: 1500,
            position: "top",
            color: "primary",
          });
          await toast.present();

    }

    const setModalOpen = (open: boolean, Id: string, Name: string) => {
      isModalOpen.value = open;
      if(Name != ''){
        deleteId.value = Id;
        deleteName.value = Name;
      }
        
    } 

    const deleteFile = async (fileId: string) => {
      if(!auth.merchantId || !auth.channelId)
          return;

      isModalOpen.value = false;
      try {
        await fileStore.deleteMerchantFile(auth.merchantId, fileId);
        reportsList.value = await fileStore.getMerchantFiles(auth.merchantId, "reports");
        
        const toast = await toastController.create({
          message: "Report deleted.",
          duration: 1500,
          position: "top",
          color: "primary",
        });
        await toast.present();
        
      } catch (e) {
        showError(e, "Error while deleting report!");
      }
    }

    return {images, reportsList, documentOutline, copyOutline, trashOutline, downloadOutline, copyToClipboard, momentjs, isModalOpen, setModalOpen, deleteId, deleteName, deleteFile}
  }
});

