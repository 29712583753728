import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "com-SingleOrder" }
const _hoisted_2 = { className: "image-container" }
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 1,
  src: "/assets/images/icons/20/Package.svg"
}
const _hoisted_5 = { className: "right-side" }
const _hoisted_6 = { class: "ui-SpaceBetween" }
const _hoisted_7 = ["href"]
const _hoisted_8 = ["src"]
const _hoisted_9 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      ($props.image)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            src: $props.image
          }, null, 8, _hoisted_3))
        : _createCommentVNode("", true),
      (!$props.image)
        ? (_openBlock(), _createElementBlock("img", _hoisted_4))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        ($props.externalLink)
          ? (_openBlock(), _createElementBlock("a", {
              key: 0,
              href: $props.externalLink,
              target: "_blank"
            }, [
              _createElementVNode("h4", null, _toDisplayString($props.title), 1),
              _createElementVNode("img", {
                src: _ctx.Icons.OpenNew
              }, null, 8, _hoisted_8)
            ], 8, _hoisted_7))
          : _createCommentVNode("", true),
        (!$props.externalLink)
          ? (_openBlock(), _createElementBlock("h4", _hoisted_9, _toDisplayString($props.title), 1))
          : _createCommentVNode("", true),
        _createElementVNode("h4", null, [
          _createElementVNode("span", null, _toDisplayString($props.header), 1)
        ])
      ]),
      _createElementVNode("p", null, _toDisplayString($props.price), 1),
      _renderSlot(_ctx.$slots, "default")
    ])
  ]))
}