
import {  IonPage, IonContent, IonButton, InfiniteScrollCustomEvent,  
  IonInfiniteScroll, IonInfiniteScrollContent, IonIcon, IonSpinner
} from '@ionic/vue';
import PageHeader from "@/components/PageHeader.vue";
import PageFooter from "@/components/PageFooter.vue";
//import TableHead from "@/views/Table/TableHead.vue";
import ReturnMenu from "@/views/Return/ReturnMenu.vue";

import { defineComponent, onMounted, ref, Ref, } from 'vue';
import { ActionTypes, useStore } from '@/store';
import { useRouter } from 'vue-router';
import PageDescription from '@/components/PageDescription.vue';
// import { ListViewState, CampaignSearchDefinition, CampaignsListViewFilter } from '@/models/feedbackService/CampaignListFilter';

import * as moment from 'moment-timezone';
import { filterOutline, closeOutline, eyeOutline, addOutline } from "ionicons/icons";
import { useAuthStore } from '@/store/authStore';
import { useErrorBox } from '@/components/errorBox';
// import { useFeedbackStore } from '@/store/feedbackStore';
import { Campaign } from '@/models/feedbackService/campaign';
import { useOgoEvents } from '@/composables/useOgoEvents';

export class ActiveFilter {
  filterId: string;
  filterTitle: string;
  selectObject: any;
  selectTitle: string;

  selectText(){
    return this.selectTitle;
  }

  constructor(filterId: string, filterTitle: string, selectObject: any, selectTitle: string) {
  this.filterId = filterId;
  this.filterTitle = filterTitle;
  this.selectObject = selectObject;
  this.selectTitle = selectTitle;
  }
}


export default defineComponent({
  name: 'CampaignList',
  components: { 
    PageHeader,
    PageFooter,
    IonPage, 
    IonContent,
    IonButton,
    IonIcon,
    IonSpinner,
    ReturnMenu,
    // MenuComponent,
    PageDescription,
    //TableHead,
    IonInfiniteScroll, IonInfiniteScrollContent,
  },
  setup (){
    const store = useStore();
    const router = useRouter();
    const auth = useAuthStore();
    // const feedbackStore = useFeedbackStore();

    const { showError } = useErrorBox();
    
    const momentjs: any = moment;
    moment.locale(store.state.locale ?? "en");

    //const campaignListDefinitions: Ref<string[id: string]|undefined> = ref(undefined);

    const nextLine:  Ref<number> = ref(0);
    const listResult: Ref<Array<Campaign>> = ref([]);      
    const loadMoreVisible: Ref<boolean> = ref(false);
    const processing: Ref<boolean> = ref(false);

    const loaded: Ref<boolean> = ref(false);
    
    const currentChannel: Ref<number> = ref(0);
    const isAdmin: Ref<boolean> = ref(false);

    const init = async () =>{
        listResult.value = [];
        if(auth.filteredRoles.includes("Admins"))
        {
            isAdmin.value = true;
        }
            
        if((!auth.merchantId || !auth.channelId) && isAdmin.value === false)
            return;
        
        processing.value = true;
        try {
            
            currentChannel.value = auth.channelId ?? 0;
            
            await loadData(50);
            loaded.value = true;
        } 
        catch(error) {
            showError(error, "Error");
        }
        finally {
            processing.value = false;
        }
    };

    onMounted(init);
    useOgoEvents({ campaignChanged: init });

    
    // const activeFilters = computed(() => {
    //   if(!campaignListDefinitions.value)
    //     return [];

    //   return Object.entries(campaignListDefinitions.value as object)
    //     .filter(i=>(i[1]).inlineSearchSelectObject)
    //     .map (i => {
    //       return new ActiveFilter(i[0], i[0], i[1].inlineSearchSelectObject, i[1].inlineSearchSelectTitle);
    //     });
    // });

    // const filterChanged = (filterKey: string, _filter:Campaign, filterValue: any, filterTitle: string) => {
    //   if(campaignListDefinitions.value) {
    //     if(filterValue === undefined || filterValue === null || filterValue === "" || filterTitle === undefined || filterTitle === "") {
    //       campaignListDefinitions.value[filterKey].inlineSearchSelectObject = undefined;
    //       campaignListDefinitions.value[filterKey].inlineSearchSelectTitle = undefined;
    //     } else {
    //       campaignListDefinitions.value[filterKey].inlineSearchSelectObject = filterValue;
    //       campaignListDefinitions.value[filterKey].inlineSearchSelectTitle = filterTitle;
    //     }
    //   }  
    // }

    // const sortChanged = (filterKey: string, _filter:ColumnDefinition, sort: boolean, sortDesc: boolean) => {
    //   // Reset old sort order
    //   Object.entries(campaignListDefinitions.value as object).forEach(e => {
    //     if(e[0] !== filterKey) {
    //       e[1].sortOrder = undefined;
    //     }
    //   });
      
    //   // Update new sort order
    //   if(campaignListDefinitions.value) {
    //     campaignListDefinitions.value[filterKey].sortOrder = sort ? 1 : undefined;
    //     campaignListDefinitions.value[filterKey].sortDesc = sortDesc;
    //   }
    // }

    // // Remove filter
    // const removeFilter = (_event: CustomEvent, filter: ActiveFilter) => {
    //   if(campaignListDefinitions.value) {
    //     campaignListDefinitions.value[filter.filterId].inlineSearchSelectObject = undefined;
    //     campaignListDefinitions.value[filter.filterId].inlineSearchSelectTitle = undefined;
    //   }
    // }

    // const saveListViewState = () => {
    //   if(campaignListDefinitions.value) {
    //     const savedState = new ListViewState();
    //     savedState.stateFrom(campaignListDefinitions.value);
    //     feedbackStore.campaignListPage = savedState;
    //     console.log("saveListViewState",feedbackStore.campaignListPage);
    //     console.log("saveListViewState",JSON.stringify(feedbackStore.campaignListPage));
    //   }
    // }

    // const loadListViewState = () => {
    //   if(campaignListDefinitions.value) {
    //     console.log(feedbackStore.campaignListPage);
    //     ListViewState.stateTo(feedbackStore.campaignListPage, campaignListDefinitions.value);
    //   }
    //   console.log("loadListViewState",feedbackStore.campaignListPage);
    // }

    // watch(campaignListDefinitions, async (_newValue, _oldValue) => {
    //   // reset and reload
    //   listResult.value = [];
    //   nextLine.value = 0;
    //   await loadData(50);

    //   saveListViewState();
    // },{deep: true});

    /// Load data to list
    const loadData = async (take: number) => {
        if((!auth.merchantId || !auth.channelId) && !isAdmin.value)
            return;
        
      processing.value = true;
      try {
        loadMoreVisible.value = false;
        const r = await store.dispatch(ActionTypes.FBS_CAMPAIGN_SEARCH, {merchantId: auth.merchantId ?? "", channelId: auth.channelId!});
        r.forEach(row => { listResult.value.push(row); });
        nextLine.value += r.length;

        loadMoreVisible.value = r.length >= take;
      } catch(error) {
        showError(error, "Error");
        loadMoreVisible.value = false;
      } finally {
        processing.value = false;
      }
    }

    const ionInfinite = async (ev: InfiniteScrollCustomEvent) => {
      await loadData(50);
      ev.target.complete();
    };
      

  return {
    router,//campaignListDefinitions,filterChanged,sortChanged,activeFilters,removeFilter,
    filterOutline,closeOutline,eyeOutline,listResult,loadData,ionInfinite,
    momentjs,loadMoreVisible, isAdmin, currentChannel,
    processing, loaded, addOutline
  }
}
});

