import { defineStore } from 'pinia'
import { RemovableRef, StorageSerializers, useLocalStorage } from '@vueuse/core'
import { ChannelDto, ChannelUpdateDto, ChannelWithSettingsView, MerchantDto, MerchantUpdateModel, NotificationSettingDto, RebuyLevel } from '@/models/merchantService/Merchant';
import { useAuthStore } from './authStore';
import { prettyResponse, getNow, patchNow, putNow, postNow, deleteNow, ogoServices } from './index';
import { AxiosResponse } from 'axios';
import { ListDefinitions, SearchDefinition } from "@/models/orderService/ReturnsListFilter";
import { ChannelUIDto, TextCustomizationDto, Theme, WidgetDto } from '@/models/merchantService/CustomerPortalSettings';
import { ReturnRuleDto, ReturnSettingsDto } from '@/models/merchantService/Returns';
import { eventBus } from '@/composables/useOgoEvents';

interface MerchantState {
  currentMerchant: RemovableRef<CurrentMerchantState|null>;
  test: RemovableRef<string>;
}

interface CurrentMerchantState {
  id: string|undefined; 
  name: string|undefined;
  channels: Array<{id: number, name: string}>;
  level: RebuyLevel;
  co2Enabled: boolean;
  }

export const useMerchantStore = defineStore('merchant', {
  state: ():MerchantState => ({
    currentMerchant: useLocalStorage("currentMerchant", null, { serializer: StorageSerializers.object,  mergeDefaults: true  }),
    test: useLocalStorage("test", "",{ serializer: StorageSerializers.object,  mergeDefaults: true  }),
  }),

  getters: {
    currentChannel: (state) => {
      const authStore = useAuthStore();
      const c = state.currentMerchant?.channels?.find(c => c.id == authStore.channelId);
      return c;
    },
  }, 

  actions: {
    updateCurrentMerchant(merchant: CurrentMerchantState|undefined, currentChannel: number|undefined) {
      const authStore = useAuthStore();
      authStore.channelId = currentChannel;

      if(merchant === undefined || currentChannel === undefined){
        authStore.channelId = undefined;
        this.currentMerchant = null;
        this.test = "";
      } else {
        authStore.channelId = currentChannel;
        this.currentMerchant = merchant;
        this.test = merchant.name || "";
      }
    }, 

    async updateMerchant(merchantId: string, model: MerchantUpdateModel) {
      try {
          const r = await putNow(`${merchantId}`,ogoServices.ms, model) as AxiosResponse<MerchantDto>;
          if(r.data.uid === this.currentMerchant?.id){
            this.currentMerchant.level = r.data.services.rebuyService.level;
            this.currentMerchant.name = r.data.companyName;
            this.currentMerchant.co2Enabled = r.data.services.co2Service?.enabled ?? false;
          }
            
          return r.data;
      } catch (reason) {
          throw (prettyResponse(reason,undefined));
      }      
    },

    async updateChannel(merchantId: string, channelId: number,  model: ChannelUpdateDto) {
      try {
          const r = await putNow(`${merchantId}/channels/${channelId}`,ogoServices.ms, model) as AxiosResponse<ChannelDto>;
          return r.data;
      } catch (reason) {
          throw (prettyResponse(reason,undefined));
      }      
    },

    async getMerchant(merchantId: string) {
      try {
          const r = await getNow(`${merchantId}`,ogoServices.ms, undefined) as AxiosResponse<MerchantDto>;
          return r.data;
      } catch (reason) {
          const authStore = useAuthStore();
          authStore.logoutNeeded(reason);
          throw (prettyResponse(reason,undefined));
      }
    },

    async getEnabledReturnReasons(merchantId: string, channelId: number) {
      try {
          const r = await getNow(`${merchantId}/channels/${channelId}/ReturnReasons`,ogoServices.ms, undefined) as AxiosResponse<Array<string>>;
          return r.data;
      } catch (reason) {
          const authStore = useAuthStore();
          authStore.logoutNeeded(reason);
          throw (prettyResponse(reason,undefined));
      }      
    },

    async updateEnabledReturnReasons(merchantId: string, channelId: number, idList: Array<string>) {
      try {
          const r = await patchNow(`${merchantId}/channels/${channelId}/ReturnReasons`,ogoServices.ms, idList) as AxiosResponse<Array<string>>;
          return r.data;
      } catch (reason) {
          const authStore = useAuthStore();
          authStore.logoutNeeded(reason);
          throw (prettyResponse(reason,undefined));
      }      
    },

    async getNotificationSettings(merchantId: string, channelId: number) {
      try {
          const r = await getNow(`${merchantId}/channels/${channelId}/NotificationSettings`,ogoServices.ms, undefined) as AxiosResponse<Array<NotificationSettingDto>>;
          return r.data;
      } catch (reason) {
          const authStore = useAuthStore();
          authStore.logoutNeeded(reason);
          throw (prettyResponse(reason,undefined));
      }      
    },

    async updateNotificationSettings(merchantId: string, channelId: number, idList: Array<NotificationSettingDto>) {
      try {
          const r = await patchNow(`${merchantId}/channels/${channelId}/NotificationSettings`,ogoServices.ms, idList) as AxiosResponse<Array<NotificationSettingDto>>;
          return r.data;
      } catch (reason) {
          const authStore = useAuthStore();
          authStore.logoutNeeded(reason);
          throw (prettyResponse(reason,undefined));
      }      
    },

    async getReturnSettings(merchantId: string, channelId: number) {
      try {
          const r = await getNow(`${merchantId}/channels/${channelId}/ReturnSettings`,ogoServices.ms, undefined) as AxiosResponse<ReturnSettingsDto>;
          return r.data;
      } catch (reason) {
          const authStore = useAuthStore();
          authStore.logoutNeeded(reason);
          throw (prettyResponse(reason,undefined));
      }      
    },

    async updateReturnSettings(merchantId: string, channelId: number, settings: ReturnSettingsDto) {
      try {
          const r = await putNow(`${merchantId}/channels/${channelId}/ReturnSettings`,ogoServices.ms, settings) as AxiosResponse<ReturnSettingsDto>;
          return r.data;
      } catch (reason) {
          const authStore = useAuthStore();
          authStore.logoutNeeded(reason);
          throw (prettyResponse(reason,undefined));
      }      
    },

    async getChannels(payload: SearchDefinition) {
      try {
          const r = await postNow(`ui/search`,ogoServices.ms, payload) as AxiosResponse<Array<ChannelWithSettingsView>>;
          return r.data;
      } catch (reason) {
          const authStore = useAuthStore();
          authStore.logoutNeeded(reason);
          throw (prettyResponse(reason,undefined));
      }      
    },

    async getChannelDefinitions() {
      try {
          const r = await getNow(`definitions`,ogoServices.ms, undefined) as AxiosResponse<ListDefinitions>;
          return r.data;
      } catch (reason) {
          const authStore = useAuthStore();
          authStore.logoutNeeded(reason);
          throw (prettyResponse(reason,undefined));
      }      
    },

    async getCustomerPortalSettings(channelId: number) {
      try {
          const r = await getNow(`services/ruim/${channelId}`,ogoServices.ms, undefined) as AxiosResponse<ChannelUIDto>;
          return r.data;
      } catch (reason) {
          const authStore = useAuthStore();
          authStore.logoutNeeded(reason);
          throw (prettyResponse(reason,undefined));
      }      
    },

    async updateCustomerPortalSettings(channelId: number, whitelabeling: Theme) {
      try {
          const r = await putNow(`services/ruim/${channelId}/white-labeling`,ogoServices.ms, whitelabeling) as AxiosResponse<ChannelUIDto>;
          return r.data;
      } catch (reason) {
          const authStore = useAuthStore();
          authStore.logoutNeeded(reason);
          throw (prettyResponse(reason,undefined));
      }      
    },

    async getMerchantReturnRule(merchantId: string, channelId: number, ruleId: string) {
      try {
          const r = await getNow(`${merchantId}/channels/${channelId}/ReturnRules/${ruleId}`, ogoServices.ms, undefined) as AxiosResponse<ReturnRuleDto>;  
          return r.data;
      } catch (reason) {
          const authStore = useAuthStore();
          authStore.logoutNeeded(reason);
          throw (prettyResponse(reason,undefined));
      }      
    },

    async getMerchantReturnRules(merchantId: string, channelId: number) {
      try {
          const r = await getNow(`${merchantId}/channels/${channelId}/ReturnRules`, ogoServices.ms, undefined) as AxiosResponse<Array<ReturnRuleDto>>;  
          return r.data;
      } catch (reason) {
          const authStore = useAuthStore();
          authStore.logoutNeeded(reason);
          throw (prettyResponse(reason,undefined));
      }      
    },

    async addMerchantReturnRule(merchantId: string, channelId: number, rule: ReturnRuleDto) {
      try {
          const r = await postNow(`${merchantId}/channels/${channelId}/ReturnRules`, ogoServices.ms, rule) as AxiosResponse<ReturnRuleDto>;  
          eventBus.emit('returnRuleChanged', r.data);
          return r.data;
      } catch (reason) {
          const authStore = useAuthStore();
          authStore.logoutNeeded(reason);
          throw (prettyResponse(reason,undefined));
      }      
    },

    async updateMerchantReturnRule(merchantId: string, channelId: number, rule: ReturnRuleDto) {
      try {
          const r = await patchNow(`${merchantId}/channels/${channelId}/ReturnRules/${rule.id}`, ogoServices.ms, rule) as AxiosResponse<ReturnRuleDto>;  
          eventBus.emit('returnRuleChanged', r.data);
          return r.data;
      } catch (reason) {
          const authStore = useAuthStore();
          authStore.logoutNeeded(reason);
          throw (prettyResponse(reason,undefined));
      }      
    },

    async deleteMerchantReturnRule(merchantId: string, channelId: number, ruleId: string) {
      try {
          const r = await deleteNow(`${merchantId}/channels/${channelId}/ReturnRules/${ruleId}`, ogoServices.ms, undefined);  
          eventBus.emit('returnRuleChanged', r.data);
          return r.data;
      } catch (reason) {
          const authStore = useAuthStore();
          authStore.logoutNeeded(reason);
          throw (prettyResponse(reason,undefined));
      }      
    },

    async updateWidgetSettings(channelId: number, widgets: WidgetDto[]) {
      try {
          const r = await putNow(`services/ruim/${channelId}/widgets`,ogoServices.ms, widgets) as AxiosResponse<ChannelUIDto>;
          return r.data;
      } catch (reason) {
          const authStore = useAuthStore();
          authStore.logoutNeeded(reason);
          throw (prettyResponse(reason,undefined));
      }      
    },

    async getTextCustomizationDefaults() {
      try {
          const r = await getNow(`services/ruim/textcustomization-defaults`,ogoServices.ms, undefined) as AxiosResponse<Array<TextCustomizationDto>>;
          return r.data;
      } catch (reason) {
          const authStore = useAuthStore();
          authStore.logoutNeeded(reason);
          throw (prettyResponse(reason,undefined));
      }      
    },

    async updateTextCustomizations(channelId: number, tcs: TextCustomizationDto[]) {
      try {
          const r = await putNow(`services/ruim/${channelId}/textcustomization`,ogoServices.ms, tcs) as AxiosResponse<ChannelUIDto>;
          return r.data;
      } catch (reason) {
          const authStore = useAuthStore();
          authStore.logoutNeeded(reason);
          throw (prettyResponse(reason,undefined));
      }      
    },

  }
})