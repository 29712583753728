export class ProductListItemDto {
    code = "";
    title = "";
    discountGroups: string[] = [];
    categoryId: string | undefined;
    costCategory: string | undefined;
    warehouses: string[] = [];
}

export class ProductAdminDto {
    code = "";
    title = "";
    discountGroups: string[] = [];
    costCategory: string | undefined;
    categoryId: string | undefined;
    warehouses: ProductWarehouseInfoAdminModel[] = [];
}

export class ProductWarehouseInfoAdminModel {
    code = "";
    id: string | undefined;
    warehouseId: string | undefined;
    costList: CostCacheItem[] = [];
}

export class CostCacheItem {
    id: string | undefined;
    from = "";
    to: string | undefined;
    cost: number | undefined;
    currency = "";
}

export class ProductAdminUpdateDto {
    code: string | undefined;
    discountGroups: string[] | undefined;
    costCategory: string | undefined;
    categoryId: string | undefined;
}

export class NewCostCodeDto {
    code = "";
    discountGroups: string[] | undefined;
    costCategory: string | undefined;
    categoryId: string | undefined;
    warehouseId = "";
}

export class NewCostDto {
    id = "";
    from = "";
    cost: number | undefined;
    currency: string | undefined;
}

export class RemoveCostDto {
    id = "";
    from = "";
}