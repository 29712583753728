import { defineStore } from 'pinia'
import { ogoServices, deleteNow, getNow } from './index';
import { AxiosResponse } from 'axios';
import { AuthBrowserDto, AuthLogDto } from '@/models/merchantService/UsersModels';


export const useUsersStore = defineStore('users', {
  state: () => ({}),

  getters: {
  }, 

  actions: {
    async getBrowsers() {
      const r = await getNow(`users/browsers`,ogoServices.ms, undefined) as AxiosResponse<Array<AuthBrowserDto>>;            
      return r.data;
    },

    async revokeBrowser(id: string) {
      await deleteNow(`users/browsers/${id}`, ogoServices.ms, undefined);
    },

    async getLatestLogins() {
      const r = await getNow(`users/logins-latest`,ogoServices.ms, undefined) as AxiosResponse<Array<AuthLogDto>>;            
      return r.data;
    },

  }
})