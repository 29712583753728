import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 0,
  style: {"height":"16px"},
  src: "\\assets\\images\\icons\\16\\Increasing.svg"
}
const _hoisted_3 = {
  key: 1,
  style: {"height":"16px"},
  src: "\\assets\\images\\icons\\16\\Decreasing.svg"
}
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_infographic = _resolveComponent("ui-infographic")!

  return (_openBlock(), _createBlock(_component_ui_infographic, null, {
    default: _withCtx(() => [
      _createElementVNode("h3", null, _toDisplayString($props.title), 1),
      _createElementVNode("h1", null, _toDisplayString($props.value) + " " + _toDisplayString($props.unitType), 1),
      ($props.comparison !== undefined)
        ? (_openBlock(), _createElementBlock("p", _hoisted_1, [
            ($props.comparison >= 0)
              ? (_openBlock(), _createElementBlock("img", _hoisted_2))
              : _createCommentVNode("", true),
            ($props.comparison < 0)
              ? (_openBlock(), _createElementBlock("img", _hoisted_3))
              : _createCommentVNode("", true),
            _createTextVNode(" " + _toDisplayString($props.comparison) + "% vs comparison ", 1)
          ]))
        : _createCommentVNode("", true),
      ($props.comparison === undefined)
        ? (_openBlock(), _createElementBlock("p", _hoisted_4, "No comparison data"))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}