import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!

  return (_openBlock(), _createBlock(_component_ion_grid, { class: "top-menu" }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_col, null, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.links, (link) => {
                return (_openBlock(), _createBlock(_component_ion_button, {
                  key: link.title,
                  class: _normalizeClass({active: _ctx.isActive(link.url)}),
                  expand: "full",
                  fill: "clear",
                  onClick: ($event: any) => (_ctx.router.replace(link.url))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(link.title), 1)
                  ]),
                  _: 2
                }, 1032, ["class", "onClick"]))
              }), 128))
            ]),
            _: 1
          }),
          (_ctx.defaultSlotUsed())
            ? (_openBlock(), _createBlock(_component_ion_col, {
                key: 0,
                size: "auto"
              }, {
                default: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "default")
                ]),
                _: 3
              }))
            : _createCommentVNode("", true)
        ]),
        _: 3
      })
    ]),
    _: 3
  }))
}