export class WarehouseFilter  
{
  title: string;
  warehouseId: string;

  constructor(){
    this.title = "";
    this.warehouseId = "";
  }
}

export class ShippingMethodFilter  
{
  title: string;
  code: string;

  constructor(){
    this.title = "";
    this.code = "";
  }
}

export class TimeFilter  
{
  title: string;
  code: string;

  constructor(){
    this.title = "";
    this.code = "";
  }
}
export class ShipmentsDashboardFiltersDto  
{
    timeFrame: Array<TimeFilter>;
    warehouses: Array<WarehouseFilter>;
    shippingMethods: Array<ShippingMethodFilter>;
    // organizations: Array<OrganizationInfo> | undefined;
      constructor() {
        this.timeFrame = [];
        this.warehouses = [];
        this.shippingMethods = [];
    }
}

export class DeliveryTime
{
  title: string;
  percentage: number;

  constructor(){
    this.title = "";
    this.percentage = 0;
  }
}

export class ShipmentData  
{
  title: string;
  amount: number;

  constructor(){
    this.title = "";
    this.amount = 0;
  }
}

export class ShippingMethod  
{
  title: string;
  percentage: number;

  constructor(){
    this.title = "";
    this.percentage = 0;
  }
}

export class ShipmentsDashboardDataDto  
{
    deliveryTimes: Array<DeliveryTime>;
    shipments: Array<ShipmentData>;
    shippingMethods: Array<ShippingMethod>;
    // organizations: Array<OrganizationInfo> | undefined;
      constructor() {
        this.deliveryTimes = [];
        this.shipments = [];
        this.shippingMethods = [];
    }
}

export class ReturnStatistic
{
  value: number;
  comparison: number;

  constructor(){
    this.value = 0;
    this.comparison = 0;
  }
}

export class ReturnValueStatistic
{
  value: number;
  comparison: number;
  currency: string;

  constructor(){
    this.value = 0;
    this.comparison = 0;
    this.currency = "";
  }
}

export class AllReturnStatistics  
{
  returns: ReturnStatistic;
  returnValue: ReturnValueStatistic;

  constructor(){
    this.returns = new ReturnStatistic();
    this.returnValue = new ReturnValueStatistic();
  }
}

export class TopValue
{
  title: string;
  percentage: number;

  constructor(){
    this.title = "";
    this.percentage = 0;
  }

}

export class TopCountries
{
  title: string;
  countryCode: string;
  percentage: number;

  constructor(){
    this.title = "";
    this.countryCode = "";
    this.percentage = 0;
  }
}

export class ReturnSource
{
  title: string;
  percentage: number;

  constructor(){
    this.title = "";
    this.percentage = 0;
  }
}

export class ReturnDashboardDataDto  
{
  startDate: Date;
  endDate: Date;
  statistics: AllReturnStatistics;
  topCountries: Array<TopCountries>;
  topReasons: Array<TopValue>;
  topProducts: Array<TopValue>;
  returnPercentage: number;
  returnSources: Array<ReturnSource>;

  constructor(){
    this.startDate = new Date();
    this.endDate = new Date();
    this.statistics = new AllReturnStatistics();
    this.topCountries = [];
    this.topReasons = [];
    this.topProducts = [];
    this.returnPercentage = 0;
    this.returnSources = [];
  }
}


export class OrderEmissionDto {
  period = "";
  startDate = "";
  endDate = "";
  statistics = new AllStatistics;
  shippingModes: ShippingMode[] = [];
  topDestinationCountries: TopCountries[] = [];
  topDepartureCountries: TopCountries[] = [];
  co2Emissions = new Co2Emission;
  shippingDistances = new ShippingDistance;
}

export enum SelectedPeriod {
  Day,
  Week,
  Month
}

export class AllStatistics {
  shipments: Statistic = new Statistic;
  returns: Statistic = new Statistic;
  averageWeight: Statistic = new Statistic;
}

export class Statistic {
  value = 0;
  comparison: number | null = null;
}

export class ShippingMode {
  title = "";
  value = 0;
}

export class Co2Emission {
  total = 0;
  totalComparison: number | null = null;
  average = 0;
  averageComparison: number | null = null;
  entity = "";
  chartData = new ChartData;
}

export class ShippingDistance {
  total = 0;
  totalComparison: number | null = null;
  average = 0;
  averageComparison: number | null = null;
  entity = "";
  chartData = new ChartData;
}
export class ChartData {
  labels: string[] = [];
  datasets: Dataset[] = [];
}

export class Dataset {
  label = "";
  data: number[] = [];
}
