import { defineStore } from 'pinia'
import { prettyResponse, getNow, ogoServices, putNow, postNow } from './index';
import { AxiosResponse } from 'axios';

import { RemovableRef, StorageSerializers, useLocalStorage } from '@vueuse/core';
import { Campaign, CampaignWithFeedbackView, FeedbackDto } from '@/models/feedbackService/campaign';
import { ListViewState } from '@/models/feedbackService/CampaignListFilter';
import { eventBus } from '@/composables/useOgoEvents';

interface FeedbackState {
    campaignListPage: RemovableRef<ListViewState>;
  }
  

export const useFeedbackStore = defineStore('feedback', {
    state: (): FeedbackState => ({
        campaignListPage: useLocalStorage("campaignListPage", new ListViewState(), { serializer: StorageSerializers.object,  mergeDefaults: true  }),
      }),
    
  getters: {
  }, 

  actions: {
    
    async getCampaigns(merchantId: string, channelId: number) {      
      try {
        const r = await getNow(`campaign/${merchantId}/${channelId}`,ogoServices.fbs, undefined) as AxiosResponse<Campaign>;
        return r.data;
      } catch (reason) {
        throw (prettyResponse(reason,undefined));
      }
    },

    async createCampaign(merchantId: string, channelId: number, data: any) {      
      try {
        const r = await postNow(`campaign/${merchantId}/${channelId}`,ogoServices.fbs, data) as AxiosResponse<Campaign>;
        eventBus.emit('campaignChanged', r.data);
        return r.data;
      } catch (reason) {
        throw (prettyResponse(reason,undefined));
      }
    },
    async getCampaign(merchantId: string, channelId: number, id: string, revision: number | undefined = undefined) {      
      try {
        let params = undefined;
        if(revision)
            params = new URLSearchParams(`?revision=${revision}`);
        const r = await getNow(`campaign/${merchantId}/${channelId}/${id}`,ogoServices.fbs, params) as AxiosResponse<Campaign>;
        return r.data;
      } catch (reason) {
        throw (prettyResponse(reason,undefined));
      }
    },
    async updateCampaign(merchantId: string, channelId: number, id: string, data: any) {      
      try {
        const r = await putNow(`campaign/${merchantId}/${channelId}/${id}`,ogoServices.fbs, data) as AxiosResponse<Campaign>;
        eventBus.emit('campaignChanged', r.data);
        return r.data;
      } catch (reason) {
        throw (prettyResponse(reason,undefined));
      }
    },

    async getFeedback(merchantId: string, campaignId: string) {      
      try {
        const r = await getNow(`feedback/${merchantId}/campaign/${campaignId}`,ogoServices.fbs, undefined) as AxiosResponse<Array<FeedbackDto>>;
        return r.data;
      } catch (reason) {
        throw (prettyResponse(reason,undefined));
      }
    },

    async getCustomerCampaign(channelId: number, orderId: string, key: string, lang: string, extraId: string|undefined) {
      try {
        const shortLocale = lang.substring(0,2);

        const params = new URLSearchParams([['lang',shortLocale]]);
        if(extraId) params.append('extraId', extraId);

        const r = await getNow(`c/campaign/channel/${channelId}/order/${orderId}/${key}`, ogoServices.fbs, params) as AxiosResponse<CampaignWithFeedbackView>;
        return r.data;
      } catch (reason) {
          throw (prettyResponse(reason,undefined));
      }      
    },

    // async getCampaign(id: string, channelId: number) {      
    //   try {
    //       const r = await getNow(`campaign/${channelId}/${id}`,ogoServices.fbs, undefined) as AxiosResponse<Campaign>;
    //       return r.data;
    //   } catch (reason) {
    //       throw (prettyResponse(reason,undefined));
    //   }
    // },
  }
})