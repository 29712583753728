import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1f91b21a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "description-content" }
const _hoisted_2 = { class: "ui-Breadcrumbs" }
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!

  return (_openBlock(), _createBlock(_component_ion_grid, { class: "page-description" }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_col, null, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                ($props.pageDescription && $props.pageDescription !== '')
                  ? (_openBlock(), _createBlock(_component_ion_text, {
                      key: 0,
                      color: "primary"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString($props.pageDescription), 1)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                _createElementVNode("ul", _hoisted_2, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.links, (link, i) => {
                    return (_openBlock(), _createElementBlock("li", {
                      key: i,
                      class: _normalizeClass({active: link.active}),
                      onClick: ($event: any) => (_ctx.$router.replace(link.url))
                    }, [
                      _createElementVNode("p", null, _toDisplayString(link.title), 1)
                    ], 10, _hoisted_3))
                  }), 128))
                ])
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}