
import { IonPage, IonContent, IonButton, IonIcon, } from '@ionic/vue';
import PageHeader from "@/components/PageHeader.vue";
import PageFooter from "@/components/PageFooter.vue";

import ReturnMenu from "@/views/Return/ReturnMenu.vue";

import { Ref, computed, defineComponent, onMounted, ref, } from 'vue';
import PageDescription from '@/components/PageDescription.vue';
import CustomerPortalSettings from '@/views/PostPurchase/CustomerPortalSettings.vue';
import WidgetSettings from '@/views/PostPurchase/WidgetSettings.vue';
import TextCustomizationSettings from './TextCustomizationSettings.vue';
import { getTrackingDomain } from '@/store';

import { useAuthStore } from '@/store/authStore';
import { cloudDoneOutline, openOutline, } from 'ionicons/icons';

export default defineComponent({
  name: 'CustomerPortalSettingsPage',
  components: {
    PageHeader,
    PageFooter,
    IonPage, 
    IonContent,
    IonButton,
    IonIcon,
    ReturnMenu,
    PageDescription,
    CustomerPortalSettings,
    WidgetSettings,
    TextCustomizationSettings,
  },
  setup (){
    const auth = useAuthStore();
    const widgets: Ref<null|typeof WidgetSettings> = ref(null);
    const cpSettings: Ref<null|typeof CustomerPortalSettings> = ref(null);
    const trackingPageUrl = `${getTrackingDomain()}/${auth.channelId}`;
    const textCustomizations: Ref<null|typeof TextCustomizationSettings> = ref(null);

    onMounted(async () => {
      if(!auth.merchantId || !auth.channelId)
        return;

      
    });

    const save = async () => {
      if(widgets.value?.isDirty)
        await widgets.value?.save();
      if(cpSettings.value?.isDirty)
        await cpSettings.value?.save();
      if(textCustomizations.value?.isDirty)
        await textCustomizations.value?.save();
    }

    const isDirty = computed(() => {
      return widgets.value?.isDirty || cpSettings.value?.isDirty || textCustomizations.value?.isDirty || false;
    });

    const openPreview = () => {
      window.open(trackingPageUrl, '_blank');
    }


  return { 
    widgets, cpSettings, save, openPreview, isDirty, textCustomizations,
    openOutline, cloudDoneOutline, }
  }
});

