

import {
    IonSelect, IonSelectOption, IonGrid, IonRow, IonCol, IonItem, IonToggle, IonLabel, IonList,
    IonButton, IonInput, toastController, IonTextarea, IonReorderGroup, IonReorder,IonBadge, IonModal, IonHeader, IonContent, IonToolbar, IonTitle, IonButtons, IonIcon
} from '@ionic/vue';
import { computed, defineComponent, onMounted, ref, Ref, } from 'vue';
import { useAuthStore } from '@/store/authStore';
import { useMerchantStore } from '@/store/merchantStore';
import { ChannelUIDto, LanguageSettings, Theme, WidgetDto, BasicWidgetProps, HighlightedProductWidgetProps, LinksWidgetProps, WidgetLink } from '@/models/merchantService/CustomerPortalSettings';
import { useErrorBox } from '@/components/errorBox';
import { stringToHash } from '@/utils/changeTracking';
import { RebuyLevel } from '@/models/merchantService/Merchant';
import { trashOutline, copyOutline } from 'ionicons/icons';
export default defineComponent({
    name: 'WidgetSettings',
    components: {
        IonItem,
        IonGrid,
        IonRow,
        IonCol,
        IonLabel,
        IonList,
        IonButton,
        IonSelect,
        IonSelectOption,
        IonTextarea,
        IonToggle,
        IonInput,
        IonReorderGroup,
        IonReorder,
        IonBadge,
        IonModal, IonHeader, IonContent,
        IonTitle, IonToolbar, IonButtons,
        IonIcon,
    },
    setup(props,  {expose} ) {
        const auth = useAuthStore();
        const merchantStore = useMerchantStore();
        const settings: Ref<ChannelUIDto | undefined> = ref(undefined);
        const rebuyLevel = merchantStore.currentMerchant?.level ?? RebuyLevel.Free;

        const widgetEditorOpen: Ref<boolean> = ref(false);

        // Widget open in the editor    
        const openWidget: Ref<WidgetDto | undefined> = ref(undefined);

        const newWidgetType: Ref<string> = ref("BASIC");

        // Props for different types of widgets used in the editor
        const basicWidgetProps: Ref<BasicWidgetProps> = ref(new BasicWidgetProps());
        const highlightedProductWidgetProps: Ref<HighlightedProductWidgetProps> = ref(new HighlightedProductWidgetProps());
        const linksWidgetProps: Ref<LinksWidgetProps> = ref(new LinksWidgetProps());

        // Widgets visible in settings
        const editableWidgets: Ref<WidgetDto[]> = ref([]);

        // Widgets of the languages that are not active for the channel
        // and are not visible in the settings.
        let otherWidgets: WidgetDto[] = [];

        const widgetOptions = computed(() => {
            return [
                { value: "BASIC", label: "Basic" },
                { value: "HIGHLIGHTED_PRODUCT", label: "Highlighted product" },
                { value: "LINKS", label: "Navigation" },
            ];
        });

        const isValidWidget = (widget: WidgetDto) => {
            return widget.lang && widget.type && widget.size;
        };



        // Size (scale) options.
        const sizeOptions = [1, 2, 3, 4];

        const languageOptions: string[] = [];

        const { showError } = useErrorBox();

        onMounted(async () => {
            if (!auth.merchantId || !auth.channelId)
                return;

            initializeNewWidget();

            settings.value = await merchantStore.getCustomerPortalSettings(auth.channelId);

            if (!settings.value.whiteLabelling)
                settings.value.whiteLabelling = new Theme();

            if (!settings.value.whiteLabelling.languageSettings)
                settings.value.whiteLabelling.languageSettings = new LanguageSettings();
            
            // Set the language options
            Object.entries(settings.value.whiteLabelling.languageSettings).forEach(([key, value]) => {
                if (value && key.length == 2)
                    languageOptions.push(key);
            });

            otherWidgets = settings.value.widgets.filter(i => !languageOptions.includes(i.lang));
            editableWidgets.value = settings.value.widgets.filter(i => languageOptions.includes(i.lang));

            savedHash.value = hashComponentState.value;

        });

        const initializeNewWidget = () => {
            openWidget.value = new WidgetDto();
            basicWidgetProps.value = new BasicWidgetProps();
            linksWidgetProps.value = new LinksWidgetProps();
            highlightedProductWidgetProps.value = new HighlightedProductWidgetProps();
        }

        // Clear and hide the editor by setting the openWidget to undefined.
        const clearOpenWidget = async () => {
            widgetEditorOpen.value = false;
            openWidget.value = undefined;
        }

        const editWidget = async (widget: WidgetDto) => {
            openWidget.value = widget;

            if (openWidget.value.type == "BASIC") {
                basicWidgetProps.value = widget.props as BasicWidgetProps;
            }
            if (openWidget.value.type == "HIGHLIGHTED_PRODUCT") {
                highlightedProductWidgetProps.value = widget.props as HighlightedProductWidgetProps;
            }
            if (openWidget.value.type == "LINKS") {
                linksWidgetProps.value = widget.props as LinksWidgetProps;
            }

            // Open the editor when everything is ok
            widgetEditorOpen.value = true;
        }

        // Add new link to the LINKS widget
        const addNewLink = async () => {
            linksWidgetProps.value.links.push(new WidgetLink());
        }

        const addNewWidget = async () => {
            openWidget.value = new WidgetDto();
            initializeNewWidget();
            openWidget.value.type = newWidgetType.value;
            if(!newWidgetType.value)
                return;

            if (openWidget.value.type == "BASIC")
                openWidget.value.props = basicWidgetProps.value;

            if (openWidget.value.type == "HIGHLIGHTED_PRODUCT")
                openWidget.value.props = highlightedProductWidgetProps.value;

            if (openWidget.value.type == "LINKS")
                openWidget.value.props = linksWidgetProps.value;

            // if(!openWidget.value)
            editableWidgets.value.push(openWidget.value);

            // Open the editor when everything is ok
            widgetEditorOpen.value = true;

        }

        const save = async () => {
            if (!auth.merchantId || !auth.channelId || !settings.value || !settings.value.widgets)
                return;

            if (editableWidgets.value.some(i => !isValidWidget(i))){
                showError(undefined, "Error validating widgets!");
                return;
            }

            try {
                const allWidgets: WidgetDto[] = [];
                allWidgets.push(...editableWidgets.value);
                allWidgets.push(...otherWidgets);
                settings.value = await merchantStore.updateWidgetSettings(settings.value.uid, allWidgets);

                editableWidgets.value = settings.value.widgets.filter(i => languageOptions.includes(i.lang));
                otherWidgets = settings.value.widgets.filter(i => !languageOptions.includes(i.lang));
                savedHash.value = hashComponentState.value;

                const toast = await toastController.create({
                    message: "Widgets saved successfully!",
                    duration: 1500,
                    position: "top",
                    color: "primary",
                });

                await toast.present();

            } catch (e) {
                showError(e as string, "Error saving widgets!");
            }
        }

        //#region isDirty check for relative simple components
        const savedHash: Ref<number> = ref(0);
        const hashComponentState = computed(() => {
            return stringToHash(JSON.stringify({
                editableWidgets: editableWidgets.value,
            }));
        });

        const isDirty = computed(() => {
            if(savedHash.value === 0)
                return false;
            return savedHash.value !== hashComponentState.value;
        });
        //#endregion

        expose({ isDirty, save });

        const handleReorder = (event: CustomEvent) => {
            editableWidgets.value = event.detail.complete(editableWidgets.value);
        };

        return {
            isDirty, settings, widgetOptions, languageOptions,
            openWidget, initializeNewWidget, sizeOptions, basicWidgetProps,
            highlightedProductWidgetProps, linksWidgetProps, addNewLink, addNewWidget,
            editableWidgets, clearOpenWidget, editWidget, save, handleReorder, rebuyLevel, RebuyLevel,
            widgetEditorOpen,newWidgetType, trashOutline, copyOutline
        }
    }
});

