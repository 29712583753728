
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonTitle,
  IonToolbar,
  IonInput,
  IonList,
  IonItem,
  IonText,
  IonCol,
  IonRow,
  IonGrid,
  // handleAlert,
} from "@ionic/vue";
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import { logInOutline, batteryFullOutline, personAddOutline, } from "ionicons/icons";
import { useErrorBox } from "@/components/errorBox";
import { useI18n } from "vue-i18n";
import { MessageSchema, NumberSchema } from "@/main";

export default defineComponent({
  name: "RegisterView",
  data() {
    return {
      loginInfo: { username: "", password: "", password2: "" },
      processing: false,
    };
  },
  components: {
    IonBackButton,
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonList,
    IonIcon,
    IonItem,
    IonPage,
    IonTitle,
    IonToolbar,
    IonInput,
    IonText,
    IonCol,
    IonRow,
    IonGrid,
  },
  setup() {
    const router = useRouter();
    const { showError } = useErrorBox();

    const { t, n, te } = useI18n<{ message: MessageSchema; number: NumberSchema }>({
      useScope: "global",
      // inheritLocale: true,
    });

    const registerNow = async (p: any) => {
      try {
        if (p.password != p.password2) {
          showError(t("register.checkPasswdErr"), t("global.Error"));
          return;
        }
        if (p.password.length < 6) {
          showError(t("register.passwdShortErr"), t("global.Error"));
          return;
        }

        //await store.dispatch(ActionTypes.REGISTER, p);
        //if (session.enteredRoute) router.replace(session.enteredRoute);
        //else 
        router.replace("/main/home");
        //console.log(ret);
      } catch (err) {
        //console.log(err);

        if(te(err as string))
          showError(t(err as string), t("global.Error"));
        else
          showError(err as string, t("global.Error"));
      }
    };

    return {
      router,
      registerNow,
      logInOutline,
      batteryFullOutline,
      personAddOutline,
      t,
      n,
    };
  },
});
