
  import { IonTitle, IonPage, IonList, IonItem, IonGrid, 
    IonRow, IonCol, IonButton, IonLabel, IonContent, loadingController, IonSelect, IonSelectOption 
  } from '@ionic/vue';
  import { Ref, computed, defineComponent, onMounted, ref } from 'vue';
  import { useStore } from '@/store';
  import { useRouter } from 'vue-router';

  import { RefundStatusEnum, ReturnDetailedViewDto } from '@/models/orderService/Return';
  import PageHeader from "@/components/PageHeader.vue";
  import PageFooter from "@/components/PageFooter.vue";
  import PageDescription from '@/components/PageDescription.vue';
  import Timeline from '@/components/Timeline.vue';

  import SingleOrder from '@/views/Return/SingleOrder.vue';

  import * as moment from 'moment-timezone';
import { useErrorBox } from '@/components/errorBox';
import { useAuthStore } from '@/store/authStore';
import { useOrderStore } from '@/store/orderStore';
import { stringToHash } from '@/utils/changeTracking';

  export default defineComponent({
    name: 'ReturnDetails',
    components: { 
      // IonSplitPane, 
      IonPage,
      IonTitle,
      IonList, IonItem, IonGrid, IonRow, IonCol, IonContent,
      IonButton,
      IonLabel,
      IonSelect, IonSelectOption,
      // MenuComponent,
      PageHeader,
      PageFooter,
      PageDescription,
      Timeline,
      SingleOrder,
    },
    setup(){
    
      const store = useStore();
      const auth = useAuthStore();
      const router = useRouter();
      const orderStore = useOrderStore();
      const { showError } = useErrorBox();

      const momentjs: any = moment;
      moment.locale(store.state.locale ?? "en");

      const returnId: Ref<string|undefined> = ref(undefined);
      const returnDetails: Ref<ReturnDetailedViewDto|undefined> = ref(undefined);

      const refundOptions =  [ 
        {id: RefundStatusEnum.NotAvailable, title: "Not Available"},
        {id: RefundStatusEnum.Validating, title: "Validating"},
        {id: RefundStatusEnum.Waiting, title: "Waiting"},
        {id: RefundStatusEnum.Refunded, title: "Refunded"},
        {id: RefundStatusEnum.Rejected, title: "Rejected"},
      ];
    

      const loading = async () => {
        const loading = await loadingController.create({
          cssClass: "my-custom-class",
          message: "Processing...",
          duration: undefined,
        });

        await loading.present();
        return loading;
      };

      onMounted( async() => {
        const id = router.currentRoute.value.params["id"].toString();
        if(id){
          returnId.value = id;

          const l = await loading();
          try {
            returnDetails.value = await orderStore.getReturn(returnId.value);
            savedHash.value = hashComponentState.value;
          } catch (error) {
            showError(error as string, "Error");
            router.back();
          } finally {
            await l.dismiss();
          }

        } else {
          router.back();
        }
      });

      const openOrder = () => {
        if(returnDetails.value?.orderExternalId) {
          window.location.assign(auth.myOGO1Domain + `/order/edit/${returnDetails.value?.orderId}`);
        }
      }

      const updateRefundStatus = async (status: RefundStatusEnum) => {
        if(!returnDetails.value) return;

        try {
          await orderStore.updateRefundStatus(returnDetails.value.id, status);
          returnDetails.value.refundStatus = status;
        } catch (error) {
          showError(error, "Error");
        }
      }


      //#region isDirty check for relative simple components
      const savedHash: Ref<number> = ref(0);
      const hashComponentState = computed(() => {
        return stringToHash(JSON.stringify( {
        return: returnDetails.value,
      }));
    });

    const isDirty = computed(() => {
      return savedHash.value !== hashComponentState.value;
    });
    //#endregion


    const save = async () => {
      if(!returnDetails.value?.refundStatus) return;

      const l = await loading();
      try {
        await updateRefundStatus(returnDetails.value.refundStatus)
        savedHash.value = hashComponentState.value;
      } catch (error) {
        showError(error, "Error");
      } finally {
        await l.dismiss();
      }
    }

      return {returnId, returnDetails, momentjs, openOrder, 
        updateRefundStatus, refundOptions,
        isDirty,save,
      }
    }
  });
