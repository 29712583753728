export class ReturnSettingsDto { 
    enabled: boolean; 
    returnPolicyUrl: string;
    returnInstructionsEn: string;
    refundEnabled: boolean;
    exchangeEnabled: boolean;
    storeCreditEnabled: boolean;
    storeCreditMultiplier?: number;
    approvalRequired: boolean;
    directReturnsEnabled: boolean;
    directReturnsToken: string;
    hideFreeComments: boolean;
    photoSetting: PhotoSettingEnum;
    exchangeSetting: ExchangeProductSettingEnum;
    exchangePriceSetting: ExchangePriceSettingEnum;

    constructor(){
        this.enabled = false;
        this.returnPolicyUrl = "";
        this.returnInstructionsEn = "";
        this.refundEnabled = false;
        this.exchangeEnabled = false;
        this.storeCreditEnabled = false;
        this.storeCreditMultiplier = 0;
        this.approvalRequired = false;
        this.directReturnsEnabled = false;
        this.directReturnsToken = "";
        this.hideFreeComments = false;
        this.photoSetting = PhotoSettingEnum.Disabled;
        this.exchangeSetting = ExchangeProductSettingEnum.OnlyAvailable;
        this.exchangePriceSetting = ExchangePriceSettingEnum.SamePrice;
    }
}


export class ReturnRuleDto {
    id: string;
    country: string;
    currency: string;
    warehouse: string;
    deadline: number;
    deliveryTypes: Array<string>;
    fee: number;
    replaceEnabled: boolean;
    replaceFee: number;
    constructor(){
        this.id = "";
        this.country = "";
        this.currency = "";
        this.warehouse = "";
        this.deadline = 0;
        this.deliveryTypes = [];
        this.fee = 0;
        this.replaceEnabled = false;
        this.replaceFee = 0;
    }
}

export enum PhotoSettingEnum {
    Disabled = "Disabled",
    Optional = "Optional",
    Required = "Required"
}

export enum ExchangeProductSettingEnum {
    AllVariants = "AllVariants",
    Incoming = "Incoming",
    OnlyAvailable = "OnlyAvailable"
}

export enum ExchangePriceSettingEnum {
    All = "All",
    SamePrice = "SamePrice",
    SamePriceOrLower = "SamePriceOrLower"
}