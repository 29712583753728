
import { IonGrid,IonRow,IonCol,IonList,IonItem,IonToggle,
IonInput, IonLabel, IonText, toastController, IonSelect, IonSelectOption  } from '@ionic/vue';
import { useErrorBox } from "@/components/errorBox";

import { computed, defineComponent, onMounted, ref, Ref, watch } from 'vue';
import { PhotoSettingEnum, ExchangeProductSettingEnum, ReturnSettingsDto, ExchangePriceSettingEnum } from '@/models/merchantService/Returns';
import { RebuyLevel } from "@/models/merchantService/Merchant";
import { useAuthStore } from '@/store/authStore';
import { useMerchantStore } from '@/store/merchantStore';
import { stringToHash } from '@/utils/changeTracking';
import { storeToRefs } from 'pinia';

export default defineComponent({
  name: 'ReturnSettings',
  components: {
    IonGrid,
    IonRow,
    IonCol, 
    IonList,IonItem,
    IonToggle,
    IonInput,
    IonText,
    // IonButton,
    IonLabel,
    IonSelect,
    IonSelectOption
  },
  data() {
    return {
      chartOptions: {
        responsive: true
      }
    }
  },
  setup (props,  {expose} ){
    const auth = useAuthStore();
    const merchantStore = useMerchantStore();
    const { showError } = useErrorBox();
    const returnSettings: Ref<ReturnSettingsDto|undefined> = ref(undefined);

    const adminsRole = computed(()=>auth.filteredRoles.includes("Admins"));
    const { merchantId, channelId } = storeToRefs(auth);

    const photoSettingOptions = [
      { id: PhotoSettingEnum.Disabled, title: "Photos section is hidden" },
      { id: PhotoSettingEnum.Optional, title: "Customer can add photos" },
      { id: PhotoSettingEnum.Required, title: "Customer is required to add photo" },
    ];
    const exchangeProductSettingOptions = [
      { id: ExchangeProductSettingEnum.AllVariants, title: "All product variants" },
      { id: ExchangeProductSettingEnum.Incoming, title: "Variant with stock or purchase order coming" },
      { id: ExchangeProductSettingEnum.OnlyAvailable, title: "Variant with stock available only" },
    ];
    const exchangePriceSettingOptions = [
      { id: ExchangePriceSettingEnum.All, title: "All product variants" },
      { id: ExchangePriceSettingEnum.SamePrice, title: "Variant with the same price" },
      { id: ExchangePriceSettingEnum.SamePriceOrLower, title: "Variant with the same or lower price" },
    ];

    const initialize = async () => {
      if(!merchantId.value || !channelId.value)
        return;
      
        savedHash.value = 0;
      
        try {
          returnSettings.value = await merchantStore.getReturnSettings(merchantId.value, channelId.value);
          savedHash.value = hashComponentState.value;          
        } catch (e) {
          showError(e as string, "Error loading settings!");
        }

    }

    onMounted(initialize);
    watch(channelId, initialize);

    const save = async () => {
      if (!merchantId.value || !channelId.value || !returnSettings.value)
        return;

      try {

        await merchantStore.updateReturnSettings( merchantId.value, channelId.value, returnSettings.value );
        returnSettings.value = await merchantStore.getReturnSettings(merchantId.value, channelId.value);

        savedHash.value = hashComponentState.value;

        const toast = await toastController.create({
          message: "Return settings saved successfully!",
          duration: 1500,
          position: "top",
          color: "primary",
        });

        await toast.present();

      } catch (e) {
        showError(e as string, "Error saving settings!");
      }
    }

    //#region isDirty check for relative simple components
    const savedHash: Ref<number> = ref(0);
    const hashComponentState = computed(() => {
      return stringToHash(JSON.stringify({
        returnSettings: returnSettings.value,
      }));
    });

    const isDirty = computed(() => {
      if (savedHash.value === 0)
        return false;
      return savedHash.value !== hashComponentState.value;
    });
    //#endregion

    expose({ isDirty, save });

  return { 
    isDirty, returnSettings, 
    save, RebuyLevel, 
    currentMerchant: merchantStore.currentMerchant,
    auth, adminsRole, photoSettingOptions, exchangeProductSettingOptions, exchangePriceSettingOptions }
}
});

