
import { IonPage, IonTitle, IonContent, IonGrid, IonRow, IonCol, IonDatetimeButton, IonModal,IonDatetime, IonSelect, IonSelectOption, IonIcon, IonButton, IonButtons, IonToolbar, IonHeader } from '@ionic/vue';
import PageHeader from "@/components/PageHeader.vue";
import PageFooter from "@/components/PageFooter.vue";

import { Doughnut, Line as LineJS } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement, LineElement, PointElement, Colors } from 'chart.js'
import { computed, defineComponent, onMounted, ref, Ref, watchEffect } from 'vue';

import { OrderEmissionDto, SelectedPeriod } from '@/models/orderService/Dashboard';
import { useRouter } from 'vue-router';
import { useErrorBox } from '@/components/errorBox';
import PageDescription from '@/components/PageDescription.vue';
import { useOrderStore } from '@/store/orderStore';
import { useAuthStore } from '@/store/authStore';
import DashboardMenu from '@/views/Dashboard/DashboardMenu.vue';
import { informationCircleOutline } from 'ionicons/icons';
import { useConfigStore } from '@/store/configStore';
import { useMerchantStore } from '@/store/merchantStore';
import CTAButton from '@/components/CTAButton.vue';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement, LineElement, PointElement, Colors);

export default defineComponent({
  name: 'Co2Dashboard',
  components: {
    PageHeader,
    PageFooter,
    PageDescription,
    IonGrid,
    IonRow,
    IonCol,
    IonPage,
    IonContent,
    IonTitle,
    IonDatetimeButton, IonModal, IonDatetime,
    Doughnut,
    LineJS,
    DashboardMenu,
    IonSelect,
    IonSelectOption,
    // IonToggle,
    IonIcon,
    IonButton,
    IonButtons,
    IonToolbar,
    IonHeader,
    CTAButton,
},
  data() {
    return {
      chartOptions: {
        responsive: true
      }
    }
  },
  setup (){
    const router = useRouter();
    const orderStore = useOrderStore();
    const configStore = useConfigStore();
    const merchantStore = useMerchantStore();
    const snippets = ref({demo: "", how:""});
    const { showError } = useErrorBox();

    const warehouseSelected: Ref<string|undefined> = ref(undefined);
    const timeFrameSelected: Ref<SelectedPeriod> = ref(1);
    const showOGOshipdata = ref(true);
    const auth = useAuthStore();
    const adminsRole = computed(()=>auth.filteredRoles.includes("Admins"));
    const isModalOpen1 = ref(false);
    const isModalOpen2 = ref(false);

    const co2Chart = computed(()=>{
      if(data?.value === undefined ){
        return [];
      }
      if(showOGOshipdata.value){
        return data?.value?.co2Emissions.chartData.datasets;
      }
      else {
        const emissionDatasets = data?.value?.co2Emissions.chartData.datasets.filter(v => v.label.toLowerCase() !== "ogoship")
        return emissionDatasets;
      }
    });

    const distanceChart = computed(()=>{
      if(data?.value === undefined ){
        return [];
      }
      if(showOGOshipdata.value){
        return data?.value?.shippingDistances.chartData.datasets;
      }
      else {
        const distanceDatasets = data?.value?.shippingDistances.chartData.datasets.filter(v => v.label.toLowerCase() !== "ogoship")
        return distanceDatasets;
      }
    });

    const data: Ref<OrderEmissionDto|undefined> = ref(undefined);

    const removeDays = (date: Date, days: number) => {
      const result = new Date(date)
      result.setDate(result.getDate() - days)
      return result
    }

    const endDate: Ref<Date> = ref(new Date());
    const startDate: Ref<Date> = ref(removeDays(new Date(), 30));
    const warehouseId: Ref<string|undefined> = ref(undefined);
    const levelOptions = computed(() => {
      return [
        {value: SelectedPeriod.Day, label: "Daily"},
        {value: SelectedPeriod.Week, label: "Weekly"},
        {value: SelectedPeriod.Month, label: "Monthly"},
      ];
    });

    const refreshCharts = async () => {
      try {
        if(merchantStore.currentMerchant?.co2Enabled)
          data.value = await orderStore.getCo2Dashboard(timeFrameSelected.value, startDate.value, endDate.value, warehouseId.value);
        else
          data.value = await orderStore.getCo2DummyDashboard();
      } catch (err) {
        await showError(err, "Error")
      }
    }

    onMounted(async () => {
      timeFrameSelected.value = SelectedPeriod.Week;

      snippets.value.demo = await configStore.getSnippet('co2.demo.only');
      snippets.value.how = await configStore.getSnippet('co2.how.calculated');
    });

    const getBackgroundColor = (index: number) => {
    switch (index) {
      case 0:
        return "#E07E00"
      case 1:
        return "#E89800"
      case 2:
        return "#EDA900"
      case 3:
        return "#F2C34D"
      case 4:
        return "#FAE5B3"

      default:
        return "#FDF5E0"
    }
  }
            
  watchEffect(async ()=>{
    if(!startDate.value && !endDate.value && !timeFrameSelected.value)
      return;
      
    await refreshCharts();
  });
  
  return {
    warehouseSelected, timeFrameSelected,
    data, router, getBackgroundColor,
    startDate, endDate, levelOptions,
    showOGOshipdata, adminsRole, co2Chart, distanceChart, informationCircleOutline,
    isModalOpen1,isModalOpen2, snippets,
    co2Enabled: merchantStore.currentMerchant?.co2Enabled,
  }
}
});

