import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_input = _resolveComponent("ion-input")!

  return (_openBlock(), _createBlock(_component_ion_input, {
    debounce: 500,
    label: "enter text",
    labelPlacement: "floating",
    onIonInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.filterChanged($event))),
    fill: "outline",
    modelValue: _ctx.text,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.text) = $event)),
    enterkeyhint: "search"
  }, null, 8, ["modelValue"]))
}