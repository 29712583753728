import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_page_header = _resolveComponent("page-header")!
  const _component_return_menu = _resolveComponent("return-menu")!
  const _component_page_description = _resolveComponent("page-description")!
  const _component_customer_portal_settings = _resolveComponent("customer-portal-settings")!
  const _component_widget_settings = _resolveComponent("widget-settings")!
  const _component_text_customization_settings = _resolveComponent("text-customization-settings")!
  const _component_page_footer = _resolveComponent("page-footer")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, {
    class: "ion-page",
    id: "main"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_page_header, {
        hilight: _ctx.isDirty,
        pageName: "Customer portal settings"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_button, {
            color: "primary",
            onClick: _ctx.openPreview,
            disabled: _ctx.isDirty
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_icon, { icon: _ctx.openOutline }, null, 8, ["icon"]),
              _createTextVNode(" Preview")
            ]),
            _: 1
          }, 8, ["onClick", "disabled"]),
          _createVNode(_component_ion_button, {
            color: "tertiary",
            onClick: _ctx.save,
            disabled: !_ctx.isDirty
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_icon, { icon: _ctx.cloudDoneOutline }, null, 8, ["icon"]),
              _createTextVNode(" Save")
            ]),
            _: 1
          }, 8, ["onClick", "disabled"])
        ]),
        _: 1
      }, 8, ["hilight"]),
      _createVNode(_component_ion_content, { fullscreen: true }, {
        default: _withCtx(() => [
          _createVNode(_component_return_menu),
          _createVNode(_component_page_description, {
            pageName: "Customer portal settings",
            pageDescription: "Configure customer portal here",
            breadCrumbs: [{title: 'Customer portal settings', active: true, url: '/returns/portalsettings'}]
          }),
          _createVNode(_component_customer_portal_settings, { ref: "cpSettings" }, null, 512),
          _createVNode(_component_widget_settings, { ref: "widgets" }, null, 512),
          _createVNode(_component_text_customization_settings, { ref: "textCustomizations" }, null, 512),
          _createVNode(_component_page_footer)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}