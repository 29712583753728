
import { IonPage, IonContent } from '@ionic/vue';
import PageHeader from "@/components/PageHeader.vue";
import PageFooter from "@/components/PageFooter.vue";
import ReturnMenu from "@/views/Return/ReturnMenu.vue";
import ReturnDashboardComponent from "@/views/Return/ReturnDashboardComponent.vue";
import { defineComponent } from 'vue';
import PageDescription from '@/components/PageDescription.vue';


export default defineComponent({
  name: 'ReturnDashboard',
  components: { 
    PageHeader,
    PageFooter,
    IonPage, 
    IonContent,
    ReturnMenu, 
    ReturnDashboardComponent,
    PageDescription,
  },
  data() {
    return {
      chartOptions: {
        responsive: true
      }
    }
  },
  setup (){
      return {}
    }
});

