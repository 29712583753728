
import {IonSelect, IonSelectOption, IonGrid, IonRow, IonCol, IonItem, IonToggle, IonLabel,
 IonButton, toastController,IonIcon, IonText, } from '@ionic/vue';

import { computed, defineComponent, onMounted, ref, Ref, watch, } from 'vue';
import { ChannelNotificationsSettingsDto, ChannelNotificationsSettingsUpdateDto } from '@/models/notificationService/Providers';
import { useErrorBox } from '@/components/errorBox';
import { LanguageDto } from '@/models/configService/Languages';
import { useAuthStore } from '@/store/authStore';
import { useNotificationStore } from '@/store/notificationStore';
import { useMerchantStore } from '@/store/merchantStore';
import { NotificationSettingDto } from '@/models/merchantService/Merchant';
import { pencilOutline } from 'ionicons/icons';
import { useConfigStore } from '@/store/configStore';
import { NotificationFrameDto, NotificationTemplateDto } from '@/models/notificationService/NotificationFrame';
import { stringToHash } from '@/utils/changeTracking';
import router from '@/router';
import { storeToRefs } from 'pinia';
import { useOgoEvents } from '@/composables/useOgoEvents';

export default defineComponent({
  name: 'TemplateList',
  components: {
    IonGrid,
    IonRow,
    IonCol, 
    IonItem,
    IonSelect,
    IonSelectOption,
    IonToggle, IonLabel,
    IonIcon,
    IonText,
    //IonButtons,
    // IonToggle,
    // IonInput,
    IonButton,
  },
  setup (props,{expose}){
    const auth = useAuthStore();
    const notifications = useNotificationStore();
    const merchantStore = useMerchantStore();
    const config = useConfigStore();

    const { showError } = useErrorBox();

    const { merchantId, channelId } = storeToRefs(auth);

    const allLanguages: Ref<LanguageDto[]> = ref ([]);
    const selectedLanguages: Ref<LanguageDto[]> = ref([]);
    const defaultLanguage: Ref<LanguageDto|undefined> = ref(undefined);
    const settings: Ref<ChannelNotificationsSettingsDto|undefined> = ref(undefined);
    const notificationSettings: Ref<NotificationSettingDto[]> = ref([]);
    const channelFrames: Ref<NotificationFrameDto[]> = ref([]);
    const templateList: Ref<NotificationTemplateDto[]> = ref([]);

    const initialize = async ()=> {
      if(!merchantId.value || !channelId.value)
        return;

      savedHash.value = 0;

      try {
        // Service is not ready yet
        allLanguages.value = await config.getLanguages();
        settings.value = await notifications.getChannelNotificationSettings(channelId.value);
      
        if(!settings.value.languages)
          settings.value.languages = [];

        notificationSettings.value = await merchantStore.getNotificationSettings(merchantId.value, channelId.value);

        // Add missing default notification settings
        const defaultKeys =  await config.getEventKeys("notificationDefault");
        defaultKeys.forEach(key => {
          if(!notificationSettings.value.find(j=>j.templateKey === key.key)){
            const newSetting = new NotificationSettingDto();
            newSetting.templateKey = key.key;
            newSetting.deliveryOptions = [];
            newSetting.deliveryOptions.push({method: "sms", isAllowed: false, isDefault: false});
            newSetting.deliveryOptions.push({method: "email", isAllowed: false, isDefault: false});
            notificationSettings.value.push(newSetting);
          }                   
        });

        templateList.value = await notifications.getTemplates(channelId.value);

        selectedLanguages.value = allLanguages.value
          .filter(l => (settings.value?.languages.findIndex(j=>j === l.code) ?? -1) >= 0);

        defaultLanguage.value = allLanguages.value.find(i => i.code === settings.value?.defaultLanguage);

        // Get frames. Get default frames first and then channel specific frames
        channelFrames.value = await notifications.getFrames(1);
        if(channelId.value !== 1) { 
          (await notifications.getFrames(channelId.value)).forEach(i=>channelFrames.value?.push(i));
        }
        const emptyFrame = new NotificationFrameDto();
        emptyFrame.name = "Empty frame";
        channelFrames.value.push(emptyFrame);

        // Update hash
        savedHash.value = hashComponentState.value;

      } catch (err) {
        await showError(err, "Error"); 
      }
    }

    onMounted( initialize );
    watch(channelId, initialize);
    useOgoEvents({ templateChanged: initialize });

    const save = async() => {
      if(!settings.value || !merchantId.value || !channelId.value)
        return;

      try {
        const updateModel: ChannelNotificationsSettingsUpdateDto = new ChannelNotificationsSettingsUpdateDto(); 
        updateModel.languages = selectedLanguages.value.map(i => i.code);
        updateModel.defaultLanguage = defaultLanguage.value?.code ?? "";
        settings.value = await notifications.updateChannelNotificationSettings(settings.value.channelId, updateModel);

        // Update notification settings
        notificationSettings.value = await merchantStore.updateNotificationSettings(merchantId.value, channelId.value,notificationSettings.value);
        selectedLanguages.value = allLanguages.value
          .filter(l => (settings.value?.languages.findIndex(j=>j === l.code) ?? -1) >= 0);

        // Update hash
        savedHash.value = hashComponentState.value;
        const toast = await toastController.create({
            message: "Return settings saved successfully!",
            duration: 1500,
            position: "top",
            color: "primary",
          });

        await toast.present();

      } catch (e) {
          showError(e, "Error saving settings!");
      }      
    }

    //#region isDirty check for relative simple components
    const savedHash: Ref<number> = ref(0);
    const hashComponentState = computed(() => {
      return stringToHash(JSON.stringify( {
        selectedLanguages: selectedLanguages.value.map(i=>i.code),
        defaultLanguage: defaultLanguage.value?.code ?? "",
        notificationSettings: notificationSettings.value.map(i=> { return {
          key: i.templateKey, 
          sms: i.deliveryOptions.find(j=>j.method === 'sms'), 
          email: i.deliveryOptions.find(j=>j.method === 'email')}}),
      }));
    });

    const isDirty = computed(() => {
      if(savedHash.value === 0)
        return false;
      return savedHash.value !== hashComponentState.value;
    });
    //#endregion

    expose({ isDirty, save });

    const templateEnabledChange = (type: string, key: string,  enable: boolean) => {
      if(!notificationSettings.value)
        return;

      const template = notificationSettings.value.find(i=>i.templateKey === key);
      if(!template) return;

      const option = template.deliveryOptions.find(i=>i.method === type);
      if(!option) return;

      option.isAllowed = enable;
      option.isDefault = enable;
    }

    const templateEdit = (type: string, key: string) => {
      router.push({name: "TemplateDetails", params: {type: type, key: key}});
    }

    const frameEdit = (frame: NotificationFrameDto)=>{
      if(!frame)
        return;

      router.push({name: "FrameDetails", params: {channel:frame.channelId, id: frame.id}});
    }

    const getColor = (notificationChannel: string, usageKey: string) => {
      if(templateList.value.find(i=>i.usageKey === usageKey && i.notificationChannel === notificationChannel) !== undefined)
        return "success";

      if(!notificationSettings.value)
        return "medium";

      const template = notificationSettings.value.find(i=>i.templateKey === usageKey);
      if(!template) return "medium";

      const option = template.deliveryOptions.find(i=>i.method === notificationChannel);
      if(!option) return "medium";

      if(option.isAllowed)
        return "danger";

      return "medium";
    }


  return { getColor, allLanguages, settings, save, selectedLanguages, notificationSettings,
    pencilOutline,templateEnabledChange,templateEdit,defaultLanguage,channelFrames, frameEdit, isDirty };
}
});

