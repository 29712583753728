import { ref, onMounted, watch } from 'vue';
import { Camera, CameraResultType, CameraSource, Photo } from '@capacitor/camera';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { Preferences } from '@capacitor/preferences';

const PHOTO_STORAGE = 'photos';

export const usePhotoGallery = () => {

  const loadSaved = async () => {
    const photoList = await Preferences.get({ key: PHOTO_STORAGE });
    const photosInPreferences = photoList.value ? JSON.parse(photoList.value) : [];

    for (const photo of photosInPreferences) {
      const file = await Filesystem.readFile({
        path: photo.filepath,
        directory: Directory.Data,
      });
      photo.webviewPath = `data:image/jpeg;base64,${file.data}`;
    }

    photos.value = photosInPreferences;
  };

  onMounted(loadSaved);

  const cachePhotos = () => {
    Preferences.set({
      key: PHOTO_STORAGE,
      value: JSON.stringify(photos.value),
    });
  };


  const convertBlobToBase64 = (blob: Blob) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => { resolve(reader.result); };
      reader.readAsDataURL(blob);
    });

  const removePicture = async (photo: UserPhoto) => {
    debugger;
    photos.value = photos.value.filter(p => p.filepath !== photo.filepath);
  };

  const savePicture = async (photo: Photo, fileName: string): Promise<UserPhoto> => {
    // Fetch the photo, read as a blob, then convert to base64 format
    const response = await fetch(photo.webPath!);
    const blob = await response.blob();
    const base64Data = (await convertBlobToBase64(blob)) as string;

    const _savedFile = await Filesystem.writeFile({
      path: fileName,
      data: base64Data,
      directory: Directory.Data,
    });

    // Use webPath to display the new image instead of base64 since it's
    // already loaded into memory
    return {
      filepath: fileName,
      webviewPath: photo.webPath,
    };
  };

  const photos = ref<UserPhoto[]>([]);

  watch(photos, cachePhotos);

  const takePhoto = async () => {

    try {

      const photo = await Camera.getPhoto({
        resultType: CameraResultType.Uri,
        source: CameraSource.Camera,
        quality: 100,
      });
  
      const fileName = new Date().getTime() + '.jpeg';
  
      const savedFileImage = await savePicture(photo, fileName);
      // const savedFileImage = {
      //     filepath: fileName,
      //     webviewPath: photo.webPath,
      // };
  
      photos.value = [savedFileImage, ...photos.value];

    } catch (error) {
      console.log('error', error);

    }

  };

  return {
    photos,
    takePhoto,
    removePicture,
  };
}


export interface UserPhoto {
  filepath: string;
  webviewPath?: string;
}