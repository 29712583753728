
  export default {
    props: {
      title: {
        type: String,
        require: true,
      },
      unitType: String,
      value: {
        type: Number,
        require: false,
      },
      comparison: {
        type: Number,
        require: false,
      },
    },
    name: "InfoGraphic",
  };
  