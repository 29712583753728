import { defineStore } from 'pinia'
import { RemovableRef, useStorage } from '@vueuse/core'
import axios, { AxiosError } from 'axios';
import jwtDecode from 'jwt-decode';
import { useMerchantStore } from './merchantStore';
import { prettyResponse } from './index';

// Can be removed if no use. Pinia will type it automatically with TypeScript anyway.
interface UserState {
  username: RemovableRef<string>; 
  id: RemovableRef<number>;
  authToken: RemovableRef<string>; 
  roles: RemovableRef<string[]>;
  hideRoles: RemovableRef<string[]>;
  expires: RemovableRef<number|undefined>;
  merchantId: RemovableRef<string|undefined>; 
  channelId: RemovableRef<number|undefined>;
  locale: RemovableRef<string>; 
  }


export const useAuthStore = defineStore('auth', {
  state: ():UserState => ({
    username: useStorage("username", ""),
    id: useStorage("id", 0),
    authToken: useStorage("authToken", ""),
    roles: useStorage("roles",[]),
    hideRoles: useStorage("hideRoles",[]),
    expires: useStorage("expires",undefined),
    merchantId: useStorage("merchantId", undefined),
    channelId: useStorage("channelId", undefined),
    locale: useStorage("locale", "en"),
  }),

  getters: {
    isAuthenticated: (state) => {
      return state.username !== "" && state.authToken !== "";
    },    
    myOGO1Domain: (_state) => {
      let myOGO1domain = "https://my.ogoship.com";
      if(location.hostname.includes("localhost") || location.hostname.includes("dev"))
        myOGO1domain = "https://devogo.ogoship.com";
      else if(location.hostname.includes("beta"))
        myOGO1domain = "https://beta.ogoship.com";
      return myOGO1domain;
    }, 
    trackingDomain: (_state) => {
      let myOGO1domain = "https://ogo.live";
      if(location.hostname.includes("localhost") || location.hostname.includes("dev"))
        myOGO1domain = "https://trackingdev.ogoship.com";
      else if(location.hostname.includes("beta"))
        myOGO1domain = "https://trackingbeta.ogoship.com";
      return myOGO1domain;
    }, 
    filteredRoles: (state) => {
      if(!Array.isArray(state.roles) || !Array.isArray(state.hideRoles))
        return [];
      return state.roles.filter(i=>state.hideRoles.indexOf(i)===-1);
    },  

  }, 
  actions: {
    logout() {
      this.username = "";
      this.id = 0;
      this.authToken = "";
      this.roles = [];
      this.expires = undefined;
    }, 
    logoutNeeded(err: any) {
      const errorResponse = err as AxiosError;
      if(!errorResponse.isAxiosError){
        return;
      } else {
        if(errorResponse.response?.status == 401)
          this.logout();
        return;
      }
    },
    async loginWithToken(payload: string) {
                
                const merchantStore = useMerchantStore();

                const token = jwtDecode(payload) as any;

                const userNameClaim = "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name";
                const userIdClaim = "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier";
                const roleClaim = "http://schemas.microsoft.com/ws/2008/06/identity/claims/role";
                const channelIdClaim = "urn:ogoship:cid";
                const merchantIdClaim = "urn:ogoship:mid";
                const _rebuyLevelClaim = "urn:ogoship:rblevel";

                const merchantId = token[merchantIdClaim] as string|undefined;
                const channelId = token[channelIdClaim] ? Number.parseInt(token[channelIdClaim] as string) : undefined;
                let roles = token[roleClaim] ?? [] as string | string[];
                if(!Array.isArray(roles)) roles = [roles];

                // Save login
                this.username = token[userNameClaim];
                this.id = token[userIdClaim];
                this.authToken = payload;
                this.roles = roles
                if(!this.hideRoles) this.hideRoles = [];
                this.expires = token.exp;
                this.merchantId = merchantId;
                this.channelId = channelId;
                try {
                  if(!merchantId || !channelId) {
                    merchantStore.updateCurrentMerchant(undefined, undefined)
                  } else {
                    const merc = await merchantStore.getMerchant(merchantId);

                    if(!merc){
                      this.logout();
                      throw new Error(`Merchant ${merchantId} not found`);
                    } 
                    
                    // Save merchant
                    merchantStore.updateCurrentMerchant({
                      channels: merc.channels.map(i=>{return {id: i.id, name: i.name};}),
                      id: merc.uid,
                      name: merc.companyName,
                      level: merc.services.rebuyService.level,
                      co2Enabled: merc.services.co2Service?.enabled ?? false,
                    }, channelId)
                  }
                  return;

                } catch (error) {
                  this.logout();
                  throw (error);
                }

              },
            
    async loginToChannel(merchantId: string, channelId: number) {
      try {
        const response = await axios.get<string>(`${this.myOGO1Domain}/api/v1/auth/channel/${channelId}`,
          { headers: { 
            Authorization: 'Bearer ' + this.authToken, 
            Locale: this.locale ?? "en", 
            "Accept-Language": this.locale ?? "en" }
          });

        await this.loginWithToken(response.data);
        return true;

      } catch (error) {
        this.logoutNeeded(error);
        throw prettyResponse(error,undefined);
      }                  
    },

    checkAuth() {
      const noe = Date.now() / 1000;

      if(!this.expires) {
        return false;
      } else if(this.expires > noe){
        return true;
      } else if (this.expires) {
        this.logout();
        return false;
      }

      return false;
    },
  }
  // other options...
})