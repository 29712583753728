import router from "@/router";
import { useAuthStore } from "@/store/authStore";

export function getToLink() {
    const auth = useAuthStore();

    const url = window.location.href;

    const domain = url.split("/")[2];
    const port = url.split("/")[0];
    const fullPath = encodeURIComponent(router.currentRoute.value.fullPath);

    // combine the domain and port
    const linkUrl = port + "//" + domain + "/to/?cid=" + auth.channelId + "&mid=" + auth.merchantId + "&redir=" + fullPath;

    return linkUrl;
}