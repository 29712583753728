import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import Login from '@/views/Auth/Login.vue';
import Register from '@/views/Auth/Register.vue';
import ResetWithToken from '@/views/Auth/ResetWithToken.vue';
import ResetPassword from '@/views/Auth/ResetPassword.vue';
import VerifyEmailWithToken from '@/views/Auth/VerifyEmail.vue';
import ReturnPage from '@/views/Auth/ReturnPage.vue';
import ToPage from '@/views/Auth/To.vue';

import UserUsagePage from '@/views/Auth/UserUsagePage.vue';
import WebHookDetailsPage from '@/views/Merchant/WebHooks/WebHookDetailsPage.vue';

import ReturnDetails from '@/views/Return/ReturnDetails.vue'
import ReturnDashboard from '@/views/Return/ReturnDashboard.vue'
import TrackingDashboard from '@/views/Admin/TrackingDashboardPage.vue'
import PostPurchaseSettingsPage from '@/views/PostPurchase/SettingsPage.vue'
import ReturnRules from '@/views/Return/ReturnRules.vue'
import ReturnRuleDetails from '@/views/Return/ReturnRuleDetails.vue'
import ReturnDeliveryTypes from '@/views/Return/ReturnDeliveryTypes.vue'
import ReportsList from '@/views/Report/ReportsList.vue'
import ShippingDashboardPage from '@/views/Dashboard/ShippingDashboardPage.vue'
import FrameDetails from '@/views/Notification/FrameDetails.vue'
import TemplateDetails from '@/views/Notification/TemplateDetails.vue'
import ChannelList from '@/views/Admin/ChannelList.vue'
import TrackingDetails from '@/views/Tracking/TrackingDetails.vue'
import ReturnReasonsAdmin from '@/views/Admin/ReturnReasonsAdmin.vue'
import ReturnReasonAdminDetails from '@/views/Admin/ReturnReasonsDetails.vue';
import ServiceStatus from '@/views/Admin/ServiceStatus.vue'
import Co2Dashboard from '@/views/Co2/Co2Dashboard.vue'
import { useAuthStore } from '@/store/authStore';
import ReturnDashboardPage from '@/views/Dashboard/ReturnDashboardPage.vue'
import StaffSearch from '@/views/Search/StaffSearch.vue'
import CustomerPortalSettingsPage from '@/views/PostPurchase/CustomerPortalSettingsPage.vue'
import ReturnDeliveryTypeList from '@/views/Admin/ReturnDeliveryTypeList.vue'
import ReturnDeliveryTypeDetails from '@/views/Admin/ReturnDeliveryTypeDetails.vue'
import CampaignList from '@/views/Feedback/CampaignList.vue';
import CampaignDetails from '@/views/Feedback/CampaignDetails.vue';
import Returns from '@/views/Return/Returns.vue';
import ReturnRequestsPage from '@/views/Return/ReturnRequestsPage.vue';
import CameraSamplePage from '@/views/Sample/CameraSamplePage.vue';
import ReturnRequestDetails from '@/views/Return/ReturnRequestDetails.vue';
import WarehouseProductList from '@/views/Admin/WarehouseProductList.vue';
import WarehouseProductDetails from '@/views/Admin/WarehouseProductDetails.vue';

import InvoiceTestPage from '@/views/Invoice/InvoiceTestPage.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'ShippingDashboardPage',
    component: ShippingDashboardPage,
    meta: { requiresAuth: true, menuRoot: "dashboard", merchantRequired: true },
  },
  {
    path: '/invoice/test',
    name: 'InvoiceTestPage',
    component: InvoiceTestPage,
    meta: { requiresAuth: true, menuRoot: "invoice", merchantRequired: false },
  },
  {
    path: '/dashboard/co2',
    name: 'Co2Dashborad',
    component: Co2Dashboard,
    meta: { requiresAuth: false, menuRoot: "dashboard", merchantRequired: true },
  },
  {
    path: '/dashboard/returns',
    name: 'ReturnDashboardPage',
    component: ReturnDashboardPage,
    meta: { requiresAuth: true, menuRoot: "dashboard", merchantRequired: true  },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { requiresAuth: false },
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta: { requiresAuth: false },
  },
  {
    path: '/auth/return',
    name: 'ReturnPage',
    component: ReturnPage,
    meta: { requiresAuth: false },
  },
  {
    path: '/to',
    name: 'ToPage',
    component: ToPage,
    meta: { requiresAuth: false },
  },
  {
    path: '/user/usage',
    name: 'UserUsagePage',
    component: UserUsagePage,
    meta: { requiresAuth: true },
  },
  {
    path: '/resetPassword',
    name: 'ResetPassword',
    component: ResetPassword,
    meta: { requiresAuth: false },
  },
  {
    path: '/resetWithToken',
    name: 'ResetWithToken',
    component: ResetWithToken,
    meta: { requiresAuth: false },
  },
  {
    path: '/verifyEmail',
    name: 'VerifyEmailWithToken',
    component: VerifyEmailWithToken,
    meta: { requiresAuth: false },
  },
  {
    path: '/returns/dashboard',
    name: 'ReturnDashboard',
    component: ReturnDashboard,
    meta: { requiresAuth: true, menuRoot: "pps", merchantRequired: true },
  },
  {
    path: '/returns/list',
    name: 'ReturnList',
    component: Returns,
    meta: { requiresAuth: true, menuRoot: "pps", merchantRequired: true },
  },
  {
    path: '/returns/requests',
    name: 'ReturnRequests',
    component: ReturnRequestsPage,
    meta: { requiresAuth: true, menuRoot: "pps", merchantRequired: true },
  },
  {
    path: '/returns/settings',
    name: 'ReturnSettings',
    component: PostPurchaseSettingsPage,
    meta: { requiresAuth: true, menuRoot: "pps", merchantRequired: true },
  },
  {
    path: '/returns/deliveryTypes',
    name: 'ReturnDeliveryTypes',
    component: ReturnDeliveryTypes,
    meta: { requiresAuth: true, menuRoot: "pps", merchantRequired: true },
  },
  {
    path: '/returns/rules',
    name: 'ReturnRules',
    component: ReturnRules,
    meta: { requiresAuth: true, menuRoot: "pps", merchantRequired: true },
  },
  {
    path: '/returns/portalsettings',
    name: 'CustomerPortalSettings',
    component: CustomerPortalSettingsPage,
    meta: { requiresAuth: true, menuRoot: "pps" },
  },
  {
    path: '/return/rule/:id?',
    name: 'ReturnRuleDetails',
    component: ReturnRuleDetails,
    meta: { requiresAuth: true, menuRoot: "pps", merchantRequired: true },
  },
  {
    path: '/return/:id',
    name: 'ReturnDetails',
    component: ReturnDetails,
    meta: { requiresAuth: true, menuRoot: "pps" },
  },
  {
    path: '/return/request/:id',
    name: 'ReturnRequestDetails',
    component: ReturnRequestDetails,
    meta: { requiresAuth: true, menuRoot: "pps" },
  },
  {
    path: '/feedback/campaignlist/:channelId?',
    name: 'CampaignList',
    component: CampaignList,
    meta: { requiresAuth: true, menuRoot: "pps", merchantRequired: true },
  },
  {
    path: '/feedback/campaigndetails/:id?/:channelId?/:revision?',
    name: 'CampaignDetails',
    component: CampaignDetails,
    meta: { requiresAuth: true, menuRoot: "pps", merchantRequired: true },
  },

  {
    path: '/notification/frame/:channel/:id?',
    name: 'FrameDetails',
    component: FrameDetails,
    meta: { requiresAuth: true, menuRoot: "pps" },
  },
  {
    path: '/tracking/details/:channel/:id?',
    name: 'TrackingDetails',
    component: TrackingDetails,
    meta: { requiresAuth: true, menuRoot: "pps" },
  },
  {
    path: '/notification/template/:type/:key',
    name: 'TemplateDetails',
    component: TemplateDetails,
    meta: { requiresAuth: true, menuRoot: "pps" },
  },
  {
    path: '/merchant/webhook/:id',
    name: 'WebHookDetails',
    component: WebHookDetailsPage,
    meta: { requiresAuth: true, menuRoot: "merchant" },
  },
  {
    path: '/reports/',
    name: 'Reports',
    component: ReportsList,
    meta: { requiresAuth: true, menuRoot: "reports", merchantRequired: true  },
  },
  {
    path: '/warehouse',
    name: 'Warehouse',
    redirect: '/warehouse/channels',
  },
  {
    path: '/warehouse/channels',
    name: 'WarehouseChannels',
    component: ChannelList,
    meta: { requiresAuth: true, menuRoot: "admin"},
  },
  {
    path: '/warehouse/search',
    name: 'WarehouseStaffSearch',
    component: StaffSearch,
    meta: { requiresAuth: true, menuRoot: "warehouse"},
  },
  {
    path: '/admin',
    name: 'Admin',
    redirect: '/admin/channellist',
  },
  {
    path: '/admin/channellist',
    name: 'ChannelList',
    component: ChannelList,
    meta: { requiresAuth: true, menuRoot: "admin"},
  },
  {
    path: '/admin/trackingDashboard',
    name: 'TrackingDashboard',
    component: TrackingDashboard,
    meta: { requiresAuth: true, menuRoot: "admin" },
  },
  {
    path: '/admin/returnReasonsAdmin',
    name: 'ReturnReasons',
    component: ReturnReasonsAdmin,
    meta: { requiresAuth: true, menuRoot: "admin" },
  },
  {
    path: '/admin/returnReasonsDetails/:id?',
    name: 'ReturnReasonAdminDetails',
    component: ReturnReasonAdminDetails,
    meta: { requiresAuth: true, menuRoot: "admin" },
  },
  {
    path: '/admin/servicestatus',
    name: 'ServiceStatus',
    component: ServiceStatus,
    meta: { requiresAuth: true, menuRoot: "admin" },
  },
  {
    path: '/admin/warehouseProducts',
    name: 'WarehouseProductList',
    component: WarehouseProductList,
    meta: { requiresAuth: true, menuRoot: "admin" },
  },
  {
    path: '/admin/warehouseProducts/edit/:code',
    name: 'WarehouseProductDetails',
    component: WarehouseProductDetails,
    meta: { requiresAuth: true, menuRoot: "admin" },
  },
  {
    path: '/admin/warehouseProducts/edit/',
    name: 'WarehouseProductNew',
    component: WarehouseProductDetails,
    meta: { requiresAuth: true, menuRoot: "admin" },
  },
  {
    path: '/admin/returnDeliverytype',
    name: 'ReturnDeliveryTypeList',
    component: ReturnDeliveryTypeList,
    meta: { requiresAuth: true, menuRoot: "admin" },
  },
  {
    path: '/admin/returnDeliverytype/:id',
    name: 'ReturnDeliveryTypeDetails',
    component: ReturnDeliveryTypeDetails,
    meta: { requiresAuth: true, menuRoot: "admin" },
  },
  {
    path: '/sample/camera',
    name: 'CameraSamplePage',
    component: CameraSamplePage,
    meta: { requiresAuth: false, menuRoot: "sample" },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach((to, from, next) => {
  const auth = useAuthStore();
  
  // Redirect to login page if not authenticated and trying to access a restricted page
  if (to.meta.requiresAuth && !auth.isAuthenticated) {
    // console.log("Not authenticated");
    next({ name: 'Login' });
  } else if(to.meta.merchantRequired && !auth.merchantId) {
    // console.log("Merchant required");
    if(auth.filteredRoles.includes("Admins"))
      next({ name: 'Admin' });
    else if(auth.filteredRoles.includes("Staff"))
      next({ name: 'Warehouse' });
    else
      next({ name: 'Login' });
  } else {
    // console.log("Authenticated");
    next();
  }
})


export default router
