
  import { IonTitle, IonPage, IonItem, IonGrid, 
    IonRow, IonCol, IonButton, IonContent, IonTextarea, IonInput,
    IonSelect,IonSelectOption, IonButtons, IonText, loadingController, toastController,
  } from '@ionic/vue';
  import { Ref, computed, defineComponent, onMounted, ref, watch } from 'vue';
  import { useRouter } from 'vue-router';

  import PageHeader from "@/components/PageHeader.vue";
  import PageFooter from "@/components/PageFooter.vue";
  import PageDescription from '@/components/PageDescription.vue';

  import * as moment from 'moment-timezone';
  import { useErrorBox } from '@/components/errorBox';
  import { FrameDetails, NotificationFrameDto, NotificationTemplateDto, TemplateLocalizedString } from '@/models/notificationService/NotificationFrame';
  import { useNotificationStore } from '@/store/notificationStore';
  import { useAuthStore } from '@/store/authStore';
  import { ChannelNotificationsSettingsDto } from '@/models/notificationService/Providers';
import { useConfigStore } from '@/store/configStore';
import { stringToHash } from '@/utils/changeTracking';

  export default defineComponent({
    name: 'TemplateDetails',
    components: { 
      IonSelect,IonSelectOption,
      IonPage,
      IonTitle,
      IonItem, IonGrid, IonRow, IonCol, IonContent,
      IonButton,
      IonTextarea,
      IonInput,
      IonText,
      IonButtons,
      PageHeader,
      PageFooter,
      PageDescription,
    },
    setup(){
    
      const router = useRouter();
      const notifications = useNotificationStore();
      const configStore = useConfigStore();
      const auth = useAuthStore();
      const { showError } = useErrorBox();

      const momentjs: any = moment;
      moment.locale(auth.locale);

      const channelFrames: Ref<NotificationFrameDto[]> = ref([]);      
      const frameDetails: Ref<NotificationFrameDto|undefined> = ref(undefined);
      const templateDetails: Ref<NotificationTemplateDto|undefined> = ref(undefined);
      const previewHtml: Ref<string> = ref("");
      const settings: Ref<ChannelNotificationsSettingsDto|undefined> = ref(undefined);
      const currentLanguage: Ref<string|undefined> = ref(undefined);
      const newSectionName: Ref<string> = ref("");
      const testRecipient: Ref<string> = ref("");

      const sampleModel: Ref<string|undefined> = ref(undefined);

      const loading = async () => {
        const loading = await loadingController.create({
          cssClass: "my-custom-class",
          message: "Processing...",
          duration: undefined,
        });

        await loading.present();
        return loading;
      };

      onMounted( async() => {
        const type = router.currentRoute.value.params["type"].toString();
        const key = router.currentRoute.value.params["key"].toString();

        if(!auth.channelId)
          return;
        
        // Show loading dialog while loading
        const l1 = await loading();   

        try {
        // Init frame options
        channelFrames.value = await notifications.getFrames(1);
        (await notifications.getFrames(auth.channelId))
          .forEach(i=>channelFrames.value?.push(i));

        // Init language options. Validate settings.
        settings.value = await notifications.getChannelNotificationSettings(auth.channelId);

        } catch (e) {
          showError(e, "Error while getting template details!");
          return;
        } finally {
          l1.dismiss();
        }
       
        if(!settings.value.languages){
          await showError("No languages selected!", "Error"); 
          return;
        }        
        if(!settings.value.defaultLanguage){
          await showError("No default languages selected!", "Error"); 
          return;
        }

        currentLanguage.value = settings.value.languages[0];

        // Show loading dialog while loading
        const l = await loading();        
        try {      
          // Load template
          templateDetails.value = await notifications.getTemplate(auth.channelId,key,type);          
        } catch (error) {
          // create new template if not found
          templateDetails.value = new NotificationTemplateDto();
          templateDetails.value.usageKey = key;
          templateDetails.value.notificationChannel = type as any;
        } finally {
          l.dismiss();
        }

        if(templateDetails.value.frame?.id){
          frameDetails.value = channelFrames.value.find(i=>i.id == templateDetails.value?.frame?.id);

          await frameChanged();
        } 
        savedHash.value = hashComponentState.value;
      });

      const revertToDefault = async () => {
        if(!auth.channelId)
          return;
        const type = router.currentRoute.value.params["type"].toString();
        const key = router.currentRoute.value.params["key"].toString();

        // Show loading dialog while loading
        const l = await loading();        
        try {      
          // Load template
          templateDetails.value = await notifications.getTemplate(1,key,type);      
          templateDetails.value.channelId = auth.channelId;    // Replace old version

        } catch (error) {
          // create new template if not found
          templateDetails.value = new NotificationTemplateDto();
          templateDetails.value.usageKey = key;
          templateDetails.value.notificationChannel = type as any;
        } finally {
          l.dismiss();
        }

        if(templateDetails.value.frame?.id){
          frameDetails.value = channelFrames.value.find(i=>i.id == templateDetails.value?.frame?.id);
        }

        await frameChanged();        
      }

      const addLocalizedString = () => {
        if(!templateDetails.value || !newSectionName.value || newSectionName.value.includes(" ")){
          return;
        }
        if(!templateDetails.value.localizedStrings || !settings.value?.languages){
          return;
        }

        const t = new TemplateLocalizedString(newSectionName.value,"",settings.value!.languages);
        t.frameValue = "";
        t.isInherited = false;
        templateDetails.value!.localizedStrings[newSectionName.value] = t;  
      
        newSectionName.value = "";
      }

      const translateEmpty = async (template: TemplateLocalizedString) => {
      const l = await loading();
      try {
        const enTxt = template.locales["en"]?.value;
        const languages = Object.keys(template.locales)
          .filter(i=>i !== "en" && !template.locales[i].value)
          .join(",");
        
        if(!enTxt || !languages)
          throw new Error("No empty fields to translate");
      
        const translations = await configStore.translate(enTxt, "en", languages)
        translations.forEach(element => {
          const empty = template.locales[element.language];
          if(empty && !empty.value && element.text)
          empty.value = element.text;        
        });
    
      } catch (error) {
        await showError(error, "Error translating text");
      } finally {
        await l.dismiss();
      }
    }

      const previewModel = async () => {
        if(!templateDetails.value){
          return;
        }

        try {
          const key = router.currentRoute.value.params["key"].toString();
          sampleModel.value = JSON.stringify(await notifications.getSampleNotificationModel(key),null,"\t");

        } catch (error) {
          showError(error, "Error previewing model!");
        }
      }

      const preview = async () => {
        if(!templateDetails.value){
          return;
        }

        try {
          previewHtml.value = await notifications.previewTemplate(templateDetails.value,currentLanguage.value ?? "en");
        } catch (error) {
          showError(error, "Error previewing template!");
        }
      }

      const testPreview = async () => {
        if(!templateDetails.value || !auth.channelId){
          return;
        }

        try {
          await notifications.testPreviewTemplate(auth.channelId, templateDetails.value, currentLanguage.value ?? "en", testRecipient.value);
        } catch (error) {
          showError(error, "Error previewing template!");
        }
      }

      watch(currentLanguage, async (_newVal, _oldVal) => {
        if(!templateDetails.value){
          return;
        }
        previewHtml.value = "refreshing...";
        await preview();
    });

      const removeLocalizedString = (key: string) => {
        if(!templateDetails.value || !templateDetails.value.localizedStrings)
          return;
        
        delete templateDetails.value.localizedStrings[key];
      }

      const save = async () => {
        if(!templateDetails.value || !auth.channelId){
          return;
        }

        // Show loading dialog while loading
        const l = await loading();
        try {          
          if(templateDetails.value.channelId != auth.channelId){
            templateDetails.value = await notifications.addTemplate(auth.channelId,templateDetails.value);
          } else {
            templateDetails.value = await notifications.updateTemplate(auth.channelId,templateDetails.value);
          }

        } catch (error) {
          showError(error, "Error");
        } finally {
          savedHash.value = hashComponentState.value;
          l.dismiss();
        }
      }

    //#region isDirty check for relative simple components
    const savedHash: Ref<number> = ref(0);
    const hashComponentState = computed(() => {

      return stringToHash(JSON.stringify({
        templateDetails: templateDetails.value,
      }));
    });

    const isDirty = computed(() => {
      if (savedHash.value === 0)
        return false;
      return savedHash.value !== hashComponentState.value;
    });
    //#endregion


      const saveButtonText = computed(() => {
        if(!templateDetails.value)
          return "";
        else if(templateDetails.value.channelId != auth.channelId)
          return "Save as new template";
        else
           return "Save";
         }
      );

      const frameChanged = async () => {
        if(!frameDetails.value || !templateDetails.value || !settings.value?.languages){
          return;
        }
        
        if(!templateDetails.value.frame)
        templateDetails.value.frame = new FrameDetails();
      
        // Update details of latest frame version
        templateDetails.value.frame.id = frameDetails.value.id;
        templateDetails.value.frame.name = frameDetails.value.id;
        templateDetails.value.frame.content = frameDetails.value.content;
      
        // Remove all inherited values
        Object.entries(templateDetails.value.localizedStrings).forEach(element => {
          if(element[1].isInherited)
            delete templateDetails.value!.localizedStrings[element[0]];
          else {          
            TemplateLocalizedString.updateLocales(templateDetails.value!.localizedStrings[element[0]],settings.value!.languages);
          }
        });
    
        // Replace all values from frame
        Object.entries(frameDetails.value.textSections).forEach(element => {
          const key = element[0];
          const value = element[1];
      
          const existing = templateDetails.value?.localizedStrings[key];
          if(existing) {
            console.log("existing",existing);
            existing.frameValue = value;
            TemplateLocalizedString.updateLocales(existing,settings.value!.languages);
          } else {
            console.log("new",key);
            const t = new TemplateLocalizedString(key,value,settings.value!.languages);
            t.frameValue = value;
            t.text = value;
            t.isInherited = true;
            templateDetails.value!.localizedStrings[key] = t;
          }
        });

      };

      const copyToClipboard = async (text: string) => {
        navigator.clipboard.writeText(text);

          const toast = await toastController.create({
            message: "Copied '" + text + "' to clipboard",
            duration: 1500,
            position: "top",
            color: "light",
          });
          await toast.present();
      }

      return {
        channelFrames, frameDetails, frameChanged, revertToDefault,
        translateEmpty,
        momentjs,sampleModel,
        copyToClipboard, isDirty,
        preview,previewModel, testPreview, testRecipient,
        removeLocalizedString, previewHtml,
        save, saveButtonText, templateDetails, 
        newSectionName, addLocalizedString, settings, currentLanguage,
      }
    }
  });
