
import {
  IonPage, IonContent, IonLabel, IonItem, IonRow, IonInput,
  IonSelect, IonGrid, IonToggle, IonCol, IonSelectOption, IonButton, toastController, loadingController
} from '@ionic/vue';
import PageHeader from "@/components/PageHeader.vue";
import PageFooter from "@/components/PageFooter.vue";
import ChannelSelect from "@/components/ChannelSelect.vue";
import { useRouter } from 'vue-router';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { computed, defineComponent, onMounted, ref, Ref, watch } from 'vue';

import PageDescription from '@/components/PageDescription.vue';
import { useAuthStore } from '@/store/authStore';
import { useErrorBox } from "@/components/errorBox";
import { LocalizedStringDto, ReturnDeliveryTypeEditorCountryInfo, ReturnDeliveryTypeDto, ReturnDeliveryTypeEditorWarehouse, ReturnDeliveryTypeUpdateDto, ReturnCountryInfoDto, ChannelReference, ReturnDeliveryTypeStatus, ReturnDeliveryPickupType, ReturnPickupTimePickerType } from '@/models/orderService/DeliveryTypes';
import { useOrderStore } from '@/store/orderStore';
import { ActionTypes, useStore } from '@/store';
import { WarehouseDto } from '@/models/warehouseService/Warehouse';
import { LanguageDto } from '@/models/configService/Languages';
import { useConfigStore } from '@/store/configStore';
import { CountryDto } from '@/models/configService/Country';
import { stringToHash } from '@/utils/changeTracking';
import { cloudDoneOutline } from 'ionicons/icons';

export default defineComponent({
  name: 'ReturnDeliveryTypeDetails',
  components: {
    PageHeader,
    PageFooter,
    PageDescription,
    IonPage,
    IonContent,
    IonInput,
    IonGrid,
    IonToggle,
    IonItem,
    IonLabel,
    IonCol,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonButton,
    ChannelSelect,
  },
  setup() {

    const store = useStore();
    const router = useRouter()
    const auth = useAuthStore();
    const orderStore = useOrderStore();
    const configStore = useConfigStore();

    const deliveryTypeId: Ref<string | undefined> = ref(undefined);
    const allLanguages: Ref<Array<LanguageDto>> = ref([]);
    const returnDeliveryType: Ref<ReturnDeliveryTypeDto | undefined> = ref(undefined);
    const allWarehouses: Ref<Array<WarehouseDto>> = ref([]);
    const activeWarehouses: Ref<Array<string>> = ref([]);

    const allCountries: Ref<Array<CountryDto>> = ref([]);
    const countryWarehouseList: Ref<Array<ReturnDeliveryTypeEditorCountryInfo>> = ref([]);

    const tagString: Ref<string> = ref("");
    const currentLanguages: Ref<Array<string>> = ref([]);

    const editor = ClassicEditor;
    const editorConfig = {};

    const { showError } = useErrorBox();

    const loading = async () => {
        const loading = await loadingController.create({
          cssClass: "my-custom-class",
          message: "Processing...",
          duration: undefined,
        });

        await loading.present();
        return loading;
      };

    const statusOptions = [
        {value: ReturnDeliveryTypeStatus.Draft, label: "Draft"},
        {value: ReturnDeliveryTypeStatus.Active, label: "Active"},
        {value: ReturnDeliveryTypeStatus.Cancelled, label: "Cancelled"},
      ];

    const pickUpOptions =  [
        {value: ReturnDeliveryPickupType.Home, label: "Home"},
        {value: ReturnDeliveryPickupType.ServicePoint, label: "Service point"},
      ];

      const pickUpTimePickerTypeOptions =  [
        {value: ReturnPickupTimePickerType.None, label: "Not available"},
        {value: ReturnPickupTimePickerType.NextBusinessDay, label: "Next business day"},
      ];

    onMounted(async () => {
      if (!auth.filteredRoles.includes("Admins")) {
        await showError("Missing admins role", "Missing permission");
        return;
      }

      const id = router.currentRoute.value.params["id"].toString();

      if (!id) {
        router.back();
      }
      try {
        allLanguages.value = await configStore.getLanguages();
        allCountries.value = await configStore.getCountries();

        if (id !== "create") {
          deliveryTypeId.value = id;
          returnDeliveryType.value = await orderStore.getReturnDeliveryType(deliveryTypeId.value);
        }
        else
          returnDeliveryType.value = new ReturnDeliveryTypeDto();

        tagString.value = returnDeliveryType.value.tags.join(",");
        currentLanguages.value = returnDeliveryType.value.returnInstructions.map(i => i.lang);

        allWarehouses.value = await store.dispatch(ActionTypes.WS_GET_WAREHOUSES, undefined);
        returnDeliveryType.value.availableCountries.forEach(availableCountry => {
          availableCountry.warehouses.forEach(warehouseId => {
            if (activeWarehouses.value.find(i => i === warehouseId) === undefined)
              activeWarehouses.value.push(warehouseId);
          })
        })

        refreshCountryWarehouseList();
        savedHash.value = hashComponentState.value;

      } catch (error) {
        showError(error as string, "Error");
        router.back();
      }

    });

    const refreshCountryWarehouseList = () => {
      if (returnDeliveryType.value === undefined)
        return;

      countryWarehouseList.value = [];
      activeWarehouses.value = [];

      returnDeliveryType.value.availableCountries.forEach(availableCountry => {
        availableCountry.warehouses.forEach(warehouseId => {
          if (activeWarehouses.value.find(i => i === warehouseId) === undefined)
            activeWarehouses.value.push(warehouseId);
        })
      })

      allCountries.value.forEach(country => {
        const newCountry: ReturnDeliveryTypeEditorCountryInfo = new ReturnDeliveryTypeEditorCountryInfo;
        newCountry.country = { ...country };
        activeWarehouses.value.forEach(activeWarehouseId => {
          const newWarehouse: ReturnDeliveryTypeEditorWarehouse = new ReturnDeliveryTypeEditorWarehouse;
          const warehouseData = allWarehouses.value.find(aw => aw.id === activeWarehouseId);
          newWarehouse.code = warehouseData?.code ?? "";
          newWarehouse.id = activeWarehouseId;

          const countrysWarehouses = returnDeliveryType.value?.availableCountries
            .find(returnCountryInfo => returnCountryInfo.countryCode == country.code)?.warehouses;

          newWarehouse.enabled = countrysWarehouses?.includes(activeWarehouseId) ?? false;

          newCountry.warehouses.push(newWarehouse);

        })

        countryWarehouseList.value.push(newCountry);
      })

    }

    const updateLanguages = async () => {
      if (!returnDeliveryType.value)
        return;

      currentLanguages.value.forEach(currentLanguageCode => {
        if (returnDeliveryType.value?.title.filter(t => t.lang === currentLanguageCode).length == 0) {
          const newTitle = new LocalizedStringDto();
          newTitle.lang = currentLanguageCode;
          newTitle.text = "";
          returnDeliveryType.value?.title.push(newTitle);
        }

        if (returnDeliveryType.value?.returnInstructions.filter(t => t.lang === currentLanguageCode).length == 0) {
          const newInstruction = new LocalizedStringDto();
          newInstruction.lang = currentLanguageCode;
          newInstruction.text = "";
          returnDeliveryType.value?.returnInstructions.push(newInstruction);
        }

        if (returnDeliveryType.value?.pickupSelectInstructions.filter(t => t.lang === currentLanguageCode).length == 0) {
          const newInstruction = new LocalizedStringDto();
          newInstruction.lang = currentLanguageCode;
          newInstruction.text = "";
          returnDeliveryType.value?.pickupSelectInstructions.push(newInstruction);
        }

      }
      );

      returnDeliveryType.value.title = returnDeliveryType.value.title.filter(i => {
        return currentLanguages.value.find(currentLanguageCode => currentLanguageCode == i.lang) !== undefined;
      })

      returnDeliveryType.value.returnInstructions = returnDeliveryType.value.returnInstructions.filter(i => {
        return currentLanguages.value.find(currentLanguageCode => currentLanguageCode == i.lang) !== undefined;
      })
    };


    const translateEmpty = async (template: Array<LocalizedStringDto>) => {
      const l = await loading();
      try {
        const enTxt = template.find(i=>i.lang =="en")?.text;
        const languages = template
          .filter(i=>i.lang !== "en" && !i.text)
          .map(i => i.lang )
          .join(",");
        
        if(!enTxt || !languages)
          throw new Error("No empty fields to translate");
      
        const translations = await configStore.translate(enTxt, "en", languages)
        translations.forEach(element => {
          const empty = template.find(i=>i.lang == element.language);
          if(empty && !empty.text && element.text)
          empty.text = element.text;        
        });
    
      } catch (error) {
        await showError(error, "Error translating text");
      } finally {
        await l.dismiss();
      }
    }



    const updateWarehouse = async (event: Array<string>) => {

      event.forEach(e => {
        if (countryWarehouseList.value[0].warehouses.find(w => w.id == e) === undefined) {
          countryWarehouseList.value.forEach(c => {

            const newWarehouse: ReturnDeliveryTypeEditorWarehouse = new ReturnDeliveryTypeEditorWarehouse;
            const warehouseData = allWarehouses.value.find(aw => aw.id === e);
            newWarehouse.code = warehouseData?.code ?? "";
            newWarehouse.id = e;
            newWarehouse.enabled = false;

            c.warehouses.push(newWarehouse);

          })
        }

      })

    }

    const save = async () => {

      if (returnDeliveryType.value?.id === undefined) {
        createNewReturnDeliveryType();
        return;
      }


      const updateModel: ReturnDeliveryTypeUpdateDto = new ReturnDeliveryTypeUpdateDto;
      tagString.value = tagString.value.trim();
      updateModel.tags = tagString.value.split(",");
      updateModel.code = returnDeliveryType.value?.code;
      updateModel.status = returnDeliveryType.value?.status;
      updateModel.pickupType = returnDeliveryType.value?.pickupType;
      updateModel.pickupTimeType = returnDeliveryType.value?.pickupTimeType;
      updateModel.provider = returnDeliveryType.value?.provider;
      updateModel.logo = returnDeliveryType.value?.logo;
      updateModel.title = returnDeliveryType.value?.title;
      updateModel.returnInstructions = returnDeliveryType.value?.returnInstructions;
      updateModel.pickupSelectInstructions = returnDeliveryType.value?.pickupSelectInstructions;

      if (updateModel.returnInstructions.length == 0){
        showError(undefined, "One language required!");
        return;
      }

      if(updateModel.returnInstructions.filter(i => i.lang == "en").length == 0){
        showError(undefined, "English language required!");
        return;
      }

      updateModel.isRemoved = returnDeliveryType.value?.isRemoved;
      updateModel.isVisible = returnDeliveryType.value?.isVisible;
      updateModel.indexNumber = returnDeliveryType.value?.indexNumber;
      updateModel.channelReferences = returnDeliveryType.value?.channelReferences;

      const enabledCountries = countryWarehouseList.value.filter(i => i.warehouses.length > 0);
      const newReturnCountryInfoDtoList: Array<ReturnCountryInfoDto> = [];
      const countriesWithWarehousesList = enabledCountries.filter(x => x.warehouses.find(y => y.enabled) !== undefined);

      countriesWithWarehousesList.forEach(countryInfo => {
        const newReturnCountryInfoDto: ReturnCountryInfoDto = new ReturnCountryInfoDto();
        newReturnCountryInfoDto.countryCode = countryInfo.country.code;
        newReturnCountryInfoDto.warehouses = countryInfo.warehouses.filter(t => t.enabled).map(o => o.id);
        newReturnCountryInfoDtoList.push(newReturnCountryInfoDto);
      });
      updateModel.availableCountries = newReturnCountryInfoDtoList;

      if (updateModel.availableCountries.length == 0){
        showError(undefined, "Delivery type needs to be active in one country!");
        return;
      }

      try {

        returnDeliveryType.value = await orderStore.updateReturnDeliveryType(returnDeliveryType.value.id, updateModel);
        savedHash.value = hashComponentState.value;
        refreshCountryWarehouseList();

        const toast = await toastController.create({
          message: "Return delivery type saved successfully!",
          duration: 1500,
          position: "top",
          color: "primary",
        });

        await toast.present();


      } catch (error) {
        showError(error as string, "Error");
      }

    }

    const createNewReturnDeliveryType = async () => {

      if (returnDeliveryType.value == undefined)
        return;

      const enabledCountries = countryWarehouseList.value.filter(i => i.warehouses.length > 0);
      const newReturnCountryInfoDtoList: Array<ReturnCountryInfoDto> = [];
      const countriesWithWarehousesList = enabledCountries.filter(x => x.warehouses.find(y => y.enabled) !== undefined);

      countriesWithWarehousesList.forEach(countryInfo => {
        const newReturnCountryInfoDto: ReturnCountryInfoDto = new ReturnCountryInfoDto();
        newReturnCountryInfoDto.countryCode = countryInfo.country.code;
        newReturnCountryInfoDto.warehouses = countryInfo.warehouses.filter(t => t.enabled).map(o => o.id);
        newReturnCountryInfoDtoList.push(newReturnCountryInfoDto);
      });
      returnDeliveryType.value.availableCountries = newReturnCountryInfoDtoList;

      if (returnDeliveryType.value.returnInstructions.length == 0){
        showError(undefined, "One language required!");
        return;
      }

      if(returnDeliveryType.value.returnInstructions.filter(i => i.lang == "en").length == 0){
        showError(undefined, "English language required!");
        return;
      }

      if (returnDeliveryType.value.availableCountries.length == 0){
        showError(undefined, "Delivery type needs to be active in one country!");
        return;
      }

      try {

        returnDeliveryType.value = await orderStore.createReturnDeliveryType(returnDeliveryType.value);
        savedHash.value = hashComponentState.value;
        refreshCountryWarehouseList();

        const toast = await toastController.create({
          message: "Return delivery type created successfully!",
          duration: 1500,
          position: "top",
          color: "primary",
        });

        await toast.present();

      } catch (error) {
        showError(error as string, "Error");
      }

    }

    const removeChannel = async (removedId:number) => {
      if(!returnDeliveryType.value)
        return;
        returnDeliveryType.value.channelReferences = returnDeliveryType.value.channelReferences.filter(i => i.channelId != removedId);
    }

    const addChannel = async (id:number, name:string) => {
      if(!returnDeliveryType.value)
        return;

        const channelReference = new ChannelReference();
        channelReference.channelId = id;
        channelReference.name = name;
        returnDeliveryType.value.channelReferences.push(channelReference);
    }

    //#region isDirty check for relative simple components
    const savedHash: Ref<number> = ref(0);
    const hashComponentState = computed(() => {

      return stringToHash(JSON.stringify({
        returnDeliveryType: returnDeliveryType.value,
        countryWarehouseList: countryWarehouseList.value,
        tagString: tagString.value,
      }));
    });

    const isDirty = computed(() => {
      if (savedHash.value === 0)
        return false;
      return savedHash.value !== hashComponentState.value;
    });
    //#endregion

    watch(currentLanguages, async (_newVal, _oldVal) => {
      await updateLanguages();
    });

    return {
      returnDeliveryType, allLanguages, editor, editorConfig, tagString,
      translateEmpty,
      currentLanguages, allCountries, activeWarehouses, allWarehouses, countryWarehouseList,
      updateWarehouse, save, isDirty, removeChannel, addChannel, statusOptions, pickUpOptions, pickUpTimePickerTypeOptions, cloudDoneOutline,
      ReturnPickupTimePickerType,
    }
  }
});

