import { RebuyLevel } from "../merchantService/Merchant";

export class ProvidersListResponse {
    smsProviders: Array<ProviderResponse>;
    emailProviders: Array<ProviderResponse>;

    constructor() {
        this.smsProviders = [];
        this.emailProviders = [];
    }
}

export class ProviderResponse {
    key= "";
    name = "";
    minimumLevel = RebuyLevel.Free;
}

export class UiDataFieldOption {
    code: string;
    title: string;

    constructor() {
        this.code = "";
        this.title = "";
    }
}

export class UiDataField {
    fieldID: string;
    placeHolderResource: string;
    titleResource: string;
    fieldType: string;
    dataValue: string;
    optional: boolean;
    fieldOptions: UiDataFieldOption[];

    constructor() {
        this.fieldID = "";
        this.placeHolderResource = "";
        this.titleResource = "";
        this.fieldType = "";
        this.dataValue = "";
        this.optional = false;
        this.fieldOptions = [];
    }
}

export class CreateOrUpdateSettingsModel {
    channelId: number;
    emailProviderKey: string;
    emailSettingJson: string;
    testEmail: string;
    isEmailTest: boolean;
    smsProviderKey: string;
    smsSettingsJson: string;
    testPhone: string;
    isSMSTest: boolean;
    languages: string[];
    defaultLanguage: string;

    constructor() {
        this.channelId = 0;
        this.emailProviderKey = "";
        this.emailSettingJson = "";
        this.testEmail = "";
        this.isEmailTest = false;
        this.smsProviderKey = "";
        this.smsSettingsJson = "";
        this.testPhone = "";
        this.isSMSTest = false;
        this.languages = [];
        this.defaultLanguage = "";
    }
}

export class UpdateEmailSettingsModel {
    emailProviderKey: string;
    emailSettingJson: string;
    testEmail: string;
    isEmailTest: boolean;

    constructor() {
        this.emailProviderKey = "";
        this.emailSettingJson = "";
        this.testEmail = "";
        this.isEmailTest = false;
    }
}

export class UpdateSmsSettingsModel {
    smsProviderKey: string;
    smsSettingsJson: string;
    testPhone: string;
    isSMSTest: boolean;

    constructor() {
        this.smsProviderKey = "";
        this.smsSettingsJson = "";
        this.testPhone = "";
        this.isSMSTest = false;
    }
}

export class ChannelSettingsDto {
    channelId: number;
    emailProviderKey: string;
    emailSettingJson: string;
    testEmail: string;
    isEmailTest: boolean;
    smsProviderKey: string;
    smsSettingsJson: string;
    testPhone: string;
    isSMSTest: boolean;
    languages: string[];
    defaultLanguage: string;

    constructor() {
        this.channelId = 0;
        this.emailProviderKey = "";
        this.emailSettingJson = "";
        this.testEmail = "";
        this.isEmailTest = false;
        this.smsProviderKey = "";
        this.smsSettingsJson = "";
        this.testPhone = "";
        this.isSMSTest = false;
        this.languages = [];
        this.defaultLanguage = "";
    }
}

export class SendTestDto {
    recipients: string[];
    notificationChannel: string;
    channelId: number | null;

    constructor(){
        this.recipients = [];
        this.notificationChannel = "";
        this.channelId = null;
    }

}

export class ChannelNotificationsSettingsDto {
    channelId: number;
    notificationProviderSettings: NotificationProviderSettingsDto[];
    languages: string[];
    defaultLanguage: string;

    constructor() {
        this.channelId = 0;
        this.languages = [];
        this.defaultLanguage = "";
        this.notificationProviderSettings = [];
    }
}

export class NotificationProviderSettingsDto {
    type: string;
    key: string;
    settingsJSON: string;
    testMode: boolean;
    testAddress: string | null;

    constructor() {
        this.type = "";
        this.key = "";
        this.settingsJSON = "";
        this.testMode = false;
        this.testAddress = null;
    }

}

export class ChannelNotificationsSettingsUpdateDto {
    notificationProviderSettings: NotificationProviderSettingsDto[] | null;
    languages: string[] | null;
    defaultLanguage: string | null;

    constructor() {
        this.languages = null;
        this.defaultLanguage = null;
        this.notificationProviderSettings = null;
    }

}

