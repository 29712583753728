
  import { IonTitle, IonGrid,IonRow,IonCol,IonDatetimeButton, IonModal,IonDatetime, IonButton, IonIcon } from '@ionic/vue';
  import { gridOutline, downloadOutline, flameOutline, } from "ionicons/icons";

  import { Doughnut } from 'vue-chartjs'
  import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement } from 'chart.js'
  import { defineComponent, onMounted, ref, Ref, watchEffect } from 'vue';
  
  import { ReturnDashboardDataDto } from '@/models/orderService/Dashboard';
  import { useRouter } from 'vue-router';
  import { useErrorBox } from '@/components/errorBox';
  import { useOrderStore } from '@/store/orderStore';
  import { useAuthStore } from '@/store/authStore';

  import CTAButton from '@/components/CTAButton.vue';
  import InfoGraphic from '@/components/InfoGraphic.vue';
import { useMerchantStore } from '@/store/merchantStore';

  ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement);
  
  export default defineComponent({
    name: 'ReturnDashboardComponent',
    components: { 
      IonGrid,
      IonRow,
      IonCol, 
      IonTitle,
      IonDatetimeButton,IonModal,IonDatetime,  
      Doughnut,
      IonButton,
      IonIcon,
      CTAButton,
      InfoGraphic,
    },
    data() {
      return {
        chartOptions: {
          responsive: true
        }
      }
    },
    setup (){
      const router = useRouter();
      const orderStore = useOrderStore();
      const merchantStore = useMerchantStore();
      const auth  = useAuthStore();
  
      const { showError } = useErrorBox();
  
      const warehouseSelected: Ref<string|undefined> = ref(undefined);
      const shippingMethodselected: Ref<string|undefined> = ref(undefined);
      const data: Ref<ReturnDashboardDataDto|undefined> = ref(undefined);

      const checkReportLink: Ref<string|undefined> = ref(undefined); 
      const loadingReport: Ref<boolean> = ref(false); 
  
      const removeDays = (date: Date, days: number) => {
        const result = new Date(date)
        result.setDate(result.getDate() - days)
        return result
      }
  
      const endDate: Ref<Date> = ref(new Date());
      const startDate: Ref<Date> = ref(removeDays(new Date(), 30));
      const warehouseId: Ref<string|undefined> = ref(undefined);
      const returnsEnabled = ref(true);
  
      const refreshCharts = async () => {
        try {
          data.value = await orderStore.getReturnDashboard( startDate.value, endDate.value, warehouseId.value);
        } catch (err) {
          await showError(err, "Error")
        }
      }
  
      onMounted(async () => {
        await refreshCharts();
        if(auth.merchantId && auth.channelId) {
            const rs = await merchantStore.getReturnSettings(auth.merchantId, auth.channelId);
            returnsEnabled.value = rs.enabled;
          }
      });
  
      const getBackgroundColor = (index: number) => {
      switch (index) {
        case 0:
          return "#E07E00"
        case 1:
          return "#E89800"
        case 2:
          return "#EDA900"
        case 3:
          return "#F2C34D"
        case 4:
          return "#FAE5B3"
  
        default:
          return "#FDF5E0"
      }
    }
              
    watchEffect(async ()=>{
      if(!startDate.value && !endDate.value)
        return;
        
      await refreshCharts();
    })

      const getReport = async () => {

        loadingReport.value = true;
        try {
          const res = await orderStore.getReturnDashboardReport(startDate.value, endDate.value, warehouseId.value);

          const FILE = window.URL.createObjectURL(new Blob([res]));
          checkReportLink.value = FILE;
        } catch (err) {
          await showError(err, "Error");
        } finally {
          loadingReport.value = false;
        }
      }
    
    return {
      warehouseSelected,shippingMethodselected,
      data,router,getBackgroundColor,
      startDate,endDate, checkReportLink, loadingReport, getReport,
      gridOutline, downloadOutline, flameOutline,
      returnsEnabled,
    }
  }
  });
  
  