import { defineStore } from 'pinia'
import { prettyResponse, getNow, putNow, postNow, ogoServices, deleteNow, patchNow } from './index';
import { AxiosResponse } from 'axios';
import { LanguageDto } from '@/models/configService/Languages';
import { CountryDto } from '@/models/configService/Country';
import { CurrencyOption } from '@/models/configService/Currency';
import { EventDto } from '@/models/configService/Event';
import { ReturnReasonDto } from '@/models/configService/ReturnReasons';
import { CallToActionDto } from '@/models/umbraco/calltoaction';
import { TranslationResponse } from '@/models/configService/Translations';
import { NewCostCodeDto, NewCostDto, ProductAdminDto, ProductAdminUpdateDto, ProductListItemDto } from '@/models/configService/WarehouseProduct';
import { InvoicingCategoryDto } from '@/models/umbraco/invoicingCategory';
import { SingleResource } from '@/models/configService/Resource';
import { CategoryWithCostsDto, CostSearchFilter, InvoiceRowDetailed, RowSearchFilter } from '@/models/configService/InvoicingMetrics';
import { useAuthStore } from './authStore';

export class snippet {[id:string]: string}

export const useConfigStore = defineStore('config', {
  state: () => ({
    languages: undefined as LanguageDto[] | undefined,
    countries: undefined as CountryDto[] | undefined,
    currencies: undefined as CurrencyOption[] | undefined,
    eventKeys: undefined as EventDto[] | undefined,
    snippets: {} as snippet,
  }),

  getters: {
  }, 

  actions: {
    async getSnippet(code: string) {
      if(this.snippets != undefined && this.snippets[code] != undefined)
        return this.snippets[code];

      try {
        // const auth = useAuthStore();
        const params = new URLSearchParams({"code": code});   
        // const r = await axios.get(`https://localhost:44313/umbraco/content/snippet/`,
        //     { headers: { Authorization: 'Bearer ' + auth.authToken},params}) as AxiosResponse<string>;
        const r = await getNow(`content/snippet/`,ogoServices.umbraco, params) as AxiosResponse<string>;
        this.snippets[code] = r.data;
        return r.data;
      } catch (reason) {
        throw (prettyResponse(reason,undefined));
      }
    },

    async getInvoicingCategories() {
      try {
        const r = await getNow(`invoicingCategories/all`,ogoServices.umbraco, undefined) as AxiosResponse<Array<InvoicingCategoryDto>>;
        return r.data;
      } catch (reason) {
        throw (prettyResponse(reason,undefined));
      }
    },

    async getLanguages() {
      if(this.languages != undefined)
        return this.languages;

      try {
          const r = await getNow(`Languages`,ogoServices.config, undefined) as AxiosResponse<Array<LanguageDto>>;
          this.languages = r.data;
          return r.data;
      } catch (reason) {
          throw (prettyResponse(reason,undefined));
      }
    },

    async getCountries() {
      if(this.countries != undefined)
        return this.countries;

      try {
          const r = await getNow(`Countries`,ogoServices.config, undefined) as AxiosResponse<Array<CountryDto>>;
          this.countries = r.data;
          return r.data;
      } catch (reason) {
          throw (prettyResponse(reason,undefined));
      }
    },

    async getCurrencies() {
      if(this.currencies != undefined)
        return this.currencies;

      try {
          const r = await getNow(`Currencies`,ogoServices.config, undefined) as AxiosResponse<Array<CurrencyOption>>;
          this.currencies = r.data;
          return r.data;
      } catch (reason) {
          throw (prettyResponse(reason,undefined));
      }
    },

    async getChannelReturnReasons(channelId: number) {
      const params = new URLSearchParams([['channelId', channelId.toString()]]);

      try {
          const r = await getNow(`ReturnReasons`,ogoServices.config, params) as AxiosResponse<Array<ReturnReasonDto>>;
          return r.data;
      } catch (reason) {
          throw (prettyResponse(reason,undefined));
      }
    },

    async getEventKeys(tagFilter: string | undefined) {
      if(this.eventKeys != undefined){
        if(tagFilter != undefined)
          return this.eventKeys.filter(e => e.tags?.includes(tagFilter));
        else
          return this.eventKeys;
      }

      try {
          const r = await getNow(`EventKeys`,ogoServices.config, undefined) as AxiosResponse<Array<EventDto>>;
          this.eventKeys = r.data;

          if(tagFilter != undefined)
            return this.eventKeys.filter(e => e.tags?.includes(tagFilter));
          else
            return this.eventKeys;
          
      } catch (reason) {
          throw (prettyResponse(reason,undefined));
      }
    },

    async loadCTA(id:string) {
        try {
            const r = await getNow(`calltoaction/code/` + id,ogoServices.umbraco, undefined) as AxiosResponse<CallToActionDto>;
            return r.data;
        } catch (reason) {
            throw (prettyResponse(reason,undefined));
        }
    },

    async getAllReturnReasons() {
          try {
            const r = await getNow(`ReturnReasons/GetFullList`,ogoServices.config, undefined) as AxiosResponse<Array<ReturnReasonDto>>;
            return r.data;
        } catch (reason) {
            throw (prettyResponse(reason,undefined));
        }
    },

    async getReturnReason(id:string) {
      try {
          const r = await getNow(`ReturnReasons/` + id,ogoServices.config, undefined) as AxiosResponse<ReturnReasonDto>;
          return r.data;
      } catch (reason) {
          throw (prettyResponse(reason,undefined));
      }
    },

    async translate(text: string, sourceLang: string, targetLang: string) {
      const params = new URLSearchParams([['text', text],['sourceLang', sourceLang],['targetLang', targetLang]]);
      try {
          const r = await getNow('Translation', ogoServices.config, params) as AxiosResponse<TranslationResponse>;
          return r.data.translations;
      } catch (reason) {
          throw (prettyResponse(reason,undefined));
      }
    },

    async updateReturnReason(id:string, data:ReturnReasonDto) {
        try {
          const r = await putNow(`ReturnReasons/` + id, ogoServices.config, data) as AxiosResponse<ReturnReasonDto>;
          return r.data;
      } catch (reason) {
          throw (prettyResponse(reason,undefined));
      }
    },
    async createReturnReason(data:ReturnReasonDto) {
        try {
          const r = await postNow(`ReturnReasons`, ogoServices.config, data) as AxiosResponse<ReturnReasonDto>;
          return r.data;
      } catch (reason) {
          throw (prettyResponse(reason,undefined));
      }
    },
    async getAllWarehouseProducts() {
      try {
        const r = await getNow(`InvoicingProducts`, ogoServices.config, undefined) as AxiosResponse<Array<ProductListItemDto>>;
        return r.data;
      } catch (reason) {
        throw (prettyResponse(reason,undefined));
      }
    },
    async getWarehouseProduct(code:string) {
      try {
        const r = await getNow(`InvoicingProducts/` + code, ogoServices.config, undefined) as AxiosResponse<ProductAdminDto>;
        return r.data;
      } catch (reason) {
        throw (prettyResponse(reason,undefined));
      }
    },
    async updateWarehouseProduct(code:string, data:ProductAdminUpdateDto) {
      try {
        const r = await putNow(`InvoicingProducts/` + code, ogoServices.config, data) as AxiosResponse<ProductAdminDto>;
        return r.data;
    } catch (reason) {
        throw (prettyResponse(reason,undefined));
    }
  },
  async addWarehouseProduct(code:string, data:NewCostCodeDto) {
    try {
      const r = await postNow(`InvoicingProducts/` + code, ogoServices.config, data) as AxiosResponse<ProductAdminDto>;
      return r.data;
    } catch (reason) {
      throw (prettyResponse(reason,undefined));
    }
  },

  async addWarehouseCost( data:NewCostDto) {
    try {
      const r = await postNow(`InvoicingProducts/cost/add`, ogoServices.config, data) as AxiosResponse<ProductAdminDto>;
      return r.data;
    } catch (reason) {
      throw (prettyResponse(reason,undefined));
    }
  },

  async removeWarehouseProduct( id:string) {
    try {
      const r = await deleteNow(`InvoicingProducts/` + id, ogoServices.config, undefined) as AxiosResponse<ProductAdminDto>;
      return r.data;
    } catch (reason) {
      throw (prettyResponse(reason,undefined));
    }
  },
  async removeCostFromWarehouseProduct(id:string, from:string) {
    const params = new URLSearchParams([['id', id],['from', from]]);
    try {
      const r = await deleteNow(`InvoicingProducts/cost/delete`, ogoServices.config, params) as AxiosResponse<ProductAdminDto>;
      return r.data;
    } catch (reason) {
      throw (prettyResponse(reason,undefined));
    }
  },

  async getResources( resource:string) {
    try {
      const r = await getNow(`Resource/` + resource, ogoServices.config, undefined) as AxiosResponse<Array<SingleResource>>;
      return r.data;
    } catch (reason) {
      throw (prettyResponse(reason,undefined));
    }
  },

  async updateResourceValue( resource: string, key: string, locale: string, value: string) {
    try {
      const r = await patchNow(`Resource/${resource}/${key}/${locale}`, ogoServices.config, '"' + value + '"') as AxiosResponse<Array<SingleResource>>;
      return r.data;
    } catch (reason) {
      throw (prettyResponse(reason,undefined));
    }
  },


  async getCategoriesWithCosts( from: Date, to: Date, warehouseId: string|undefined, allMerchants: boolean, allChannels: boolean) {

    const filter = new CostSearchFilter();
    filter.from = from;
    filter.to = to;

    const auth = useAuthStore();

    if(allMerchants){
      if(!auth.filteredRoles.includes("Admins"))
        throw "Unauthorized";
    } else {
      filter.merchantId = auth.merchantId as string;

      if(!allChannels)
        filter.channelIdList = [auth.channelId as number];
    }

    if(warehouseId != undefined)
      filter.warehouseId = warehouseId;
    
    try {
      const r = await postNow(`InvoicingMetrics/search-costs-by-categories`, ogoServices.config, filter, undefined) as AxiosResponse<Array<CategoryWithCostsDto>>;
      return r.data;
    } catch (reason) {
      throw (prettyResponse(reason,undefined));
    }
  },

  async getInvoiceRowsWithCosts( from: Date, to: Date, warehouseId: string|undefined, categoryIdList:Array<string>, allMerchants: boolean, allChannels: boolean) {

    const filter = new RowSearchFilter();
    filter.from = from;
    filter.to = to;

    const auth = useAuthStore();

    if(allMerchants){
      if(!auth.filteredRoles.includes("Admins"))
        throw "Unauthorized";
    } else {
      filter.merchantId = auth.merchantId as string;

      if(!allChannels)
        filter.channelIdList = [auth.channelId as number];
    }

    if(warehouseId != undefined)
      filter.warehouseId = warehouseId;

    if(categoryIdList != undefined)
      filter.categoryList = categoryIdList;
    
    try {
      const r = await postNow(`InvoicingMetrics/search-invoice-rows`, ogoServices.config, filter, undefined) as AxiosResponse<Array<InvoiceRowDetailed>>;
      return r.data;
    } catch (reason) {
      throw (prettyResponse(reason,undefined));
    }
  },

  }
})

