
import {IonSelect, IonSelectOption, IonGrid, IonRow, IonCol, IonItem, IonToggle, IonLabel,
 toastController, IonInput, } from '@ionic/vue';

import { computed, defineComponent, onMounted, ref, Ref, watch, } from 'vue';
import { useErrorBox } from '@/components/errorBox';
import { useAuthStore } from '@/store/authStore';
import { useMerchantStore } from '@/store/merchantStore';
import { ChannelUpdateDto, MerchantUpdateModel, OrderCancellationSettingEnum, RebuyLevel, RebuyServiceDto, ServicesUpdateModel } from '@/models/merchantService/Merchant';
import { pencilOutline } from 'ionicons/icons';
import { stringToHash } from '@/utils/changeTracking';
import { storeToRefs } from 'pinia';

export default defineComponent({
  name: 'PostPurchaseSettings',
  components: {
    IonGrid,
    IonRow,
    IonCol, 
    IonItem,
    IonSelect,
    IonSelectOption,
    IonToggle,
     IonLabel,
    // IonIcon,
    // IonText,
    //IonButtons,
    // IonToggle,
    IonInput,
    // IonButton,
  },
  setup (props, {expose}){
    const auth = useAuthStore();
    const merchantStore = useMerchantStore();

    const { showError } = useErrorBox();

    const settings: Ref<RebuyServiceDto|undefined> = ref(undefined);
    const ppsUrl: Ref<string|undefined> = ref(undefined);
    const loginNotRequired: Ref<boolean|undefined> = ref(undefined);

    const cancellationSettings: Ref<OrderCancellationSettingEnum> = ref(OrderCancellationSettingEnum.Disabled);

    const isAdmin = computed(() => { return auth.filteredRoles.includes("Admins"); });
    const { merchantId, channelId } = storeToRefs(auth);
    
    const levelOptions =  [
        {value: RebuyLevel.Free, label: "Free"},
        {value: RebuyLevel.Growth, label: "Growth"},
        {value: RebuyLevel.Enterprise, label: "Enterprise"},
      ];

    const cancellationOptions =  [
        {value: OrderCancellationSettingEnum.Disabled, label: "Disabled"},
        {value: OrderCancellationSettingEnum.CancelBeforeShipping, label: "Cancel before shipping allowed"},
        {value: OrderCancellationSettingEnum.StopBeforeShipping, label: "Stop before shipping allowed"},
      ];

    const initialize = async ()=> {
      if(!merchantId.value || !channelId.value)
        return;
      
      savedHash.value = 0;

      try {
        const m = await merchantStore.getMerchant(merchantId.value);        
        settings.value = m.services.rebuyService;

        const channel = m.channels.find(c => c.id == channelId.value);
        if(!channel){
          throw new Error("Channel not found!");
        }

        cancellationSettings.value = channel.orderCancellationSettings;
        ppsUrl.value = channel.rebuyUrl;
        loginNotRequired.value = channel.loginNotRequired;

        // Update hash
        savedHash.value = hashComponentState.value;

      } catch (err) {
        await showError(err, "Error"); 
      }
    }

    onMounted(initialize);
    watch(channelId, initialize);

    const save = async() => {
      if(!settings.value || !merchantId.value || !channelId.value)
        return;

      try {

        const updateModel = new MerchantUpdateModel();
        updateModel.services = new ServicesUpdateModel();
        updateModel.services.rebuyService = settings.value;
        const m = await merchantStore.updateMerchant(merchantId.value, updateModel);
        settings.value = m.services.rebuyService;

        const channel = m.channels.find(c => c.id == channelId.value);
        if(!channel){
          throw new Error("Channel not found!");
        }

   

        if(ppsUrl.value != channel.rebuyUrl || loginNotRequired.value != channel.loginNotRequired || cancellationSettings.value != channel.orderCancellationSettings){
          const updateModel2 = new ChannelUpdateDto();
          updateModel2.rebuyUrl = ppsUrl.value;
          updateModel2.loginNotRequired = loginNotRequired.value;
          updateModel2.orderCancellationSettings = cancellationSettings.value;
          const m2 = await merchantStore.updateChannel(merchantId.value, channelId.value, updateModel2);
          ppsUrl.value = m2.rebuyUrl;
          cancellationSettings.value = m2.orderCancellationSettings;
        }

        // Reload merchant
        auth.loginToChannel(merchantId.value, channelId.value);

        // Update hash
        savedHash.value = hashComponentState.value;
        
        const toast = await toastController.create({
            message: "Return settings saved successfully!",
            duration: 1500,
            position: "top",
            color: "primary",
          });

        await toast.present();

        location.reload();
        
      } catch (e) {
          showError(e, "Error saving settings!");
      }      
    }

    //#region isDirty check for relative simple components
    const savedHash: Ref<number> = ref(0);
    const hashComponentState = computed(() => {
      return stringToHash(JSON.stringify({
        settings: settings.value,
        ppsUrl: ppsUrl.value,
        loginNotRequired: loginNotRequired.value,
        cancellationSettings: cancellationSettings.value,
      }));
    });

    const isDirty = computed(() => {
      if (savedHash.value === 0)
        return false;
      return savedHash.value !== hashComponentState.value;
    });
    //#endregion

    expose({ isDirty, save });

  return { 
    settings, levelOptions, ppsUrl, cancellationOptions, cancellationSettings,
    pencilOutline,
    RebuyLevel, loginNotRequired, 
    isDirty,
    isAdmin, };
}
});

