import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "page-layout" }
const _hoisted_2 = { class: "inner-panel" }
const _hoisted_3 = {
  key: 0,
  style: {"text-align":"center","padding-top":"50px","padding-bottom":"50px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_header = _resolveComponent("page-header")!
  const _component_PageDescription = _resolveComponent("PageDescription")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_modal = _resolveComponent("ion-modal")!
  const _component_page_footer = _resolveComponent("page-footer")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, {
    class: "ion-page",
    id: "main"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_page_header, { pageName: "Reports" }),
      _createVNode(_component_ion_content, { fullscreen: true }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_PageDescription, {
              pageName: "Reports",
              pageDescription: "Review and manage all reports",
              breadCrumbs: [{title: 'Reports', active: true, url: '/home'}]
            }),
            _createVNode(_component_ion_grid, { style: {"width":"100%"} }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_col, null, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_2, [
                          _createVNode(_component_ion_title, null, {
                            default: _withCtx(() => [
                              _createTextVNode("Received reports")
                            ]),
                            _: 1
                          }),
                          (!_ctx.reportsList || _ctx.reportsList.length === 0)
                            ? (_openBlock(), _createElementBlock("h2", _hoisted_3, "No reports found"))
                            : _createCommentVNode("", true),
                          _createVNode(_component_ion_list, null, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.reportsList, (report) => {
                                return (_openBlock(), _createBlock(_component_ion_item, {
                                  key: report.uid
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_icon, { icon: _ctx.documentOutline }, null, 8, ["icon"]),
                                    _createVNode(_component_ion_label, null, {
                                      default: _withCtx(() => [
                                        _createElementVNode("h2", null, _toDisplayString(report.name), 1),
                                        _createElementVNode("p", null, [
                                          _createTextVNode(_toDisplayString((report.size / 1000).toFixed(1)) + "KB - " + _toDisplayString(_ctx.momentjs(report.createdAt).calendar()) + " ", 1),
                                          _createVNode(_component_ion_button, {
                                            fill: "clear",
                                            href: report.url
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_ion_icon, {
                                                icon: _ctx.downloadOutline,
                                                type: "button"
                                              }, null, 8, ["icon"])
                                            ]),
                                            _: 2
                                          }, 1032, ["href"]),
                                          _createVNode(_component_ion_button, {
                                            fill: "clear",
                                            onClick: ($event: any) => {_ctx.copyToClipboard(report.url);}
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_ion_icon, {
                                                icon: _ctx.copyOutline,
                                                type: "button"
                                              }, null, 8, ["icon"])
                                            ]),
                                            _: 2
                                          }, 1032, ["onClick"]),
                                          _createVNode(_component_ion_button, {
                                            fill: "clear",
                                            onClick: ($event: any) => (_ctx.setModalOpen(true, report.uid, report.name))
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_ion_icon, {
                                                icon: _ctx.trashOutline,
                                                type: "button"
                                              }, null, 8, ["icon"])
                                            ]),
                                            _: 2
                                          }, 1032, ["onClick"])
                                        ])
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]),
                                  _: 2
                                }, 1024))
                              }), 128))
                            ]),
                            _: 1
                          })
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_ion_modal, {
              "is-open": _ctx.isModalOpen,
              onDidDismiss: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setModalOpen(false, '', '')))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_header, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_toolbar, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_buttons, { slot: "start" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_button, {
                              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setModalOpen(false, '', '')))
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode("Cancel")
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_title, null, {
                          default: _withCtx(() => [
                            _createTextVNode("Delete report?")
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_buttons, { slot: "end" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_button, {
                              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.deleteFile(_ctx.deleteId)))
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode("Delete")
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_content, { class: "ion-padding" }, {
                  default: _withCtx(() => [
                    _createElementVNode("p", null, " Delete report " + _toDisplayString(_ctx.deleteName) + "? ", 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["is-open"]),
            _createVNode(_component_page_footer)
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}