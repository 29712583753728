class localeString {
    code: string;
    value: string;

    constructor() {
        this.code = "";
        this.value = "";
    }
}

export class TemplateLocalizedString {
    key: string;
    isInherited: boolean;
    isLocalized: boolean;
    frameValue: string;
    text: string;
    locales: { [key: string]: localeString };

    constructor(key: string, text: string, languages: string[]) {
        this.key = key;
        this.isInherited = false;
        this.isLocalized = false;
        this.text = text;
        this.frameValue = "";
        this.locales = {};
        languages.forEach(l => {
            this.locales[l] = { code: l, value: "" };
        });
    }

    static updateLocales(current: TemplateLocalizedString, languages: string[]) {
        languages.forEach(l => {
            if (!current.locales[l]) {
                current.locales[l] = { code: l, value: "" };
            }
        });

        Object.keys(current.locales).forEach(key => {
            if (!languages.find(l => l === key)) {
                delete current.locales[key];
            } else {
                current.locales[key].code = key;
            }
        });
    }
}

export class NotificationFrameDto {
    id: string;
    channelId: number;
    name: string;
    content: string;
    notificationChannel: "email" | "sms";
    createdAt: Date;
    createdBy: string;
    editedAt: Date | null;
    editedBy: string;
    textSections: { [key: string]: string };

    constructor() {
        this.id = "00000000-0000-0000-0000-000000000000";
        this.channelId = 0;
        this.name = "";
        this.content = "";
        this.notificationChannel = "email";
        this.createdAt = new Date();
        this.createdBy = "";
        this.editedAt = null;
        this.editedBy = "";
        this.textSections = {};
    }
}

export class FrameDetails{
    id: string;
    name: string;
    revision: number;
    content: string;

    constructor() {
        this.id = "";
        this.name = "";
        this.content = "";
        this.revision = 0;
    }
}

export class NotificationTemplateDto {
    channelId: number;
    name: string;
    subject: string;
    body: string;
    usageKey: string;
    notificationChannel:  "email" | "sms";
    createdAt: Date;
    createdBy: string;
    editedAt: Date | null;
    editedBy: string;
    frame: FrameDetails | undefined;
    localizedStrings: { [key: string]: TemplateLocalizedString };

    constructor() {
        this.channelId = 0;
        this.name = "";
        this.subject = "";
        this.body = "";
        this.usageKey = "";
        this.notificationChannel = "email";
        this.createdAt = new Date();
        this.createdBy = "";
        this.editedAt = null;
        this.editedBy = "";
        this.frame = undefined;
        this.localizedStrings = {};
    }
}




