import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6b293184"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "table-head" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_DateRangeSelect = _resolveComponent("DateRangeSelect")!
  const _component_RadioSelect = _resolveComponent("RadioSelect")!
  const _component_TextSearch = _resolveComponent("TextSearch")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_modal = _resolveComponent("ion-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_ion_button, {
        size: "small",
        fill: "clear",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setOpen(true))),
        color: _ctx.selectColor()
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.title) + "  ", 1),
          _createVNode(_component_ion_icon, {
            icon: _ctx.filterOutline,
            "aria-label": "filter"
          }, null, 8, ["icon"])
        ]),
        _: 1
      }, 8, ["color"])
    ]),
    _createVNode(_component_ion_modal, {
      "is-open": _ctx.isOpen,
      onDidDismiss: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setOpen(false))),
      "enter-animation": _ctx.enterAnimation,
      "leave-animation": _ctx.leaveAnimation
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_content, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_toolbar, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_title, null, {
                  default: _withCtx(() => [
                    _createTextVNode("Sort and filter")
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            (_ctx.filter.isSortable)
              ? (_openBlock(), _createBlock(_component_ion_title, { key: 0 }, {
                  default: _withCtx(() => [
                    _createTextVNode("Sort by " + _toDisplayString(_ctx.title), 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.filter.isSortable)
              ? (_openBlock(), _createBlock(_component_ion_item, { key: 1 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_button, {
                      size: "small",
                      color: "primary",
                      onClick: _ctx.ascSort,
                      disabled: _ctx.isActive && _ctx.ascSortActive
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("a-z")
                      ]),
                      _: 1
                    }, 8, ["onClick", "disabled"]),
                    _createVNode(_component_ion_button, {
                      size: "small",
                      color: "primary",
                      onClick: _ctx.descSort,
                      disabled: _ctx.isActive && !_ctx.ascSortActive
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("z-a")
                      ]),
                      _: 1
                    }, 8, ["onClick", "disabled"]),
                    _createVNode(_component_ion_button, {
                      size: "small",
                      color: "primary",
                      onClick: _ctx.removeSort,
                      disabled: !_ctx.isActive
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("remove sorting")
                      ]),
                      _: 1
                    }, 8, ["onClick", "disabled"])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_ion_title, null, {
              default: _withCtx(() => [
                _createTextVNode("Filter by " + _toDisplayString(_ctx.title), 1)
              ]),
              _: 1
            }),
            (_ctx.filter.inlineSearch === 'DATEPICKER')
              ? (_openBlock(), _createBlock(_component_DateRangeSelect, {
                  key: 2,
                  title: _ctx.title,
                  "filter-key": _ctx.filterKey,
                  filter: _ctx.filter,
                  onFilterChanged: _ctx.filterChanged
                }, null, 8, ["title", "filter-key", "filter", "onFilterChanged"]))
              : _createCommentVNode("", true),
            (_ctx.filter.inlineSearch === 'SELECT')
              ? (_openBlock(), _createBlock(_component_RadioSelect, {
                  key: 3,
                  title: _ctx.title,
                  "filter-key": _ctx.filterKey,
                  filter: _ctx.filter,
                  onFilterChanged: _ctx.filterChanged
                }, null, 8, ["title", "filter-key", "filter", "onFilterChanged"]))
              : _createCommentVNode("", true),
            (_ctx.filter.inlineSearch === 'TEXT')
              ? (_openBlock(), _createBlock(_component_TextSearch, {
                  key: 4,
                  title: _ctx.title,
                  "filter-key": _ctx.filterKey,
                  filter: _ctx.filter,
                  onFilterChanged: _ctx.filterChanged
                }, null, 8, ["title", "filter-key", "filter", "onFilterChanged"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["is-open", "enter-animation", "leave-animation"])
  ], 64))
}