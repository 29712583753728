import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1b289e4c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "data-table" }
const _hoisted_2 = {
  key: 0,
  class: "text"
}
const _hoisted_3 = {
  key: 1,
  class: "text"
}
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "issueNumber" }
const _hoisted_6 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_datetime_button = _resolveComponent("ion-datetime-button")!
  const _component_ion_datetime = _resolveComponent("ion-datetime")!
  const _component_ion_modal = _resolveComponent("ion-modal")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_toggle = _resolveComponent("ion-toggle")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_progress_bar = _resolveComponent("ion-progress-bar")!
  const _component_ion_label = _resolveComponent("ion-label")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_grid, {
      class: "outside-panel-col",
      style: {"margin-top":"20px","flex-grow":"0","width":"100%"}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_col, { class: "ui-daterange" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_datetime_button, {
                  disabled: _ctx.processing,
                  datetime: "datetime1",
                  color: "transparent"
                }, null, 8, ["disabled"]),
                _createVNode(_component_ion_modal, { "keep-contents-mounted": true }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_datetime, {
                      id: "datetime1",
                      "display-format": "DD.MM.YYYY",
                      presentation: "date",
                      modelValue: _ctx.startDateStr,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.startDateStr) = $event))
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }),
                _createTextVNode("   -   "),
                _createVNode(_component_ion_datetime_button, {
                  disabled: _ctx.processing,
                  datetime: "datetime2"
                }, null, 8, ["disabled"]),
                _createVNode(_component_ion_modal, { "keep-contents-mounted": true }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_datetime, {
                      id: "datetime2",
                      "display-format": "DD.MM.",
                      presentation: "date",
                      modelValue: _ctx.endDateStr,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.endDateStr) = $event))
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_ion_col, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_button, {
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.filtersVisible=true))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Filters")
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_modal, {
                  isOpen: _ctx.filtersVisible,
                  onDidDismiss: _cache[9] || (_cache[9] = ($event: any) => (_ctx.filtersVisible=false))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_header, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_toolbar, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_buttons, { slot: "start" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_button, {
                                  disabled: _ctx.processing,
                                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.filtersVisible=false))
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode("Close")
                                  ]),
                                  _: 1
                                }, 8, ["disabled"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_ion_title, null, {
                              default: _withCtx(() => [
                                _createTextVNode("Filters")
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_content, { class: "ion-padding" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_item, { lines: "none" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_toggle, {
                              slot: "start",
                              disabled: _ctx.processing,
                              "label-placement": "end",
                              modelValue: _ctx.savedState.showDeliveryType,
                              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.savedState.showDeliveryType) = $event))
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode("Show delivery types")
                              ]),
                              _: 1
                            }, 8, ["disabled", "modelValue"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_item, { lines: "none" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_toggle, {
                              slot: "start",
                              disabled: _ctx.processing,
                              "label-placement": "end",
                              modelValue: _ctx.savedState.showDeliveryState,
                              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.savedState.showDeliveryState) = $event))
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode("Show delivery state")
                              ]),
                              _: 1
                            }, 8, ["disabled", "modelValue"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_item, { lines: "none" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_toggle, {
                              slot: "start",
                              disabled: _ctx.processing,
                              "label-placement": "end",
                              modelValue: _ctx.savedState.showLate,
                              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.savedState.showLate) = $event))
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode("Show late shipments")
                              ]),
                              _: 1
                            }, 8, ["disabled", "modelValue"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_item, { lines: "none" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_toggle, {
                              slot: "start",
                              disabled: _ctx.processing,
                              "label-placement": "end",
                              modelValue: _ctx.savedState.showIssues,
                              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.savedState.showIssues) = $event))
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode("Show issues")
                              ]),
                              _: 1
                            }, 8, ["disabled", "modelValue"])
                          ]),
                          _: 1
                        }),
                        (_ctx.adminsRole)
                          ? (_openBlock(), _createBlock(_component_ion_item, {
                              key: 0,
                              lines: "none"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_toggle, {
                                  slot: "start",
                                  disabled: _ctx.processing,
                                  "label-placement": "end",
                                  modelValue: _ctx.savedState.showAllChannels,
                                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.savedState.showAllChannels) = $event))
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_text, { color: "tertiary" }, {
                                      default: _withCtx(() => [
                                        _createTextVNode("Show all channels"),
                                        _createVNode(_component_ion_icon, {
                                          icon: _ctx.lockClosedOutline,
                                          size: "small"
                                        }, null, 8, ["icon"])
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }, 8, ["disabled", "modelValue"])
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["isOpen"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_ion_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_col, {
              class: "panel-col",
              style: {"overflow":"auto","min-height":"400px"}
            }, {
              default: _withCtx(() => [
                _createElementVNode("table", _hoisted_1, [
                  _createElementVNode("thead", null, [
                    _createElementVNode("tr", null, [
                      (_ctx.savedState.showDeliveryType)
                        ? (_openBlock(), _createElementBlock("th", {
                            key: 0,
                            class: "text",
                            onClick: _cache[10] || (_cache[10] = ($event: any) => {if(_ctx.savedState.sortBy === 'Type') _ctx.savedState.sortDesc = !_ctx.savedState.sortDesc; else _ctx.savedState.sortBy = 'Type';})
                          }, "Type"))
                        : _createCommentVNode("", true),
                      (_ctx.savedState.showDeliveryState)
                        ? (_openBlock(), _createElementBlock("th", {
                            key: 1,
                            class: "text",
                            onClick: _cache[11] || (_cache[11] = ($event: any) => {if(_ctx.savedState.sortBy === 'State') _ctx.savedState.sortDesc = !_ctx.savedState.sortDesc; else _ctx.savedState.sortBy = 'State';})
                          }, "State"))
                        : _createCommentVNode("", true),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dateList, (d) => {
                        return (_openBlock(), _createElementBlock("th", {
                          class: "number",
                          key: d.getTime()
                        }, _toDisplayString(_ctx.momentjs(d).format('DD.MM.')), 1))
                      }), 128))
                    ])
                  ]),
                  _createElementVNode("tbody", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sortedData, (data) => {
                      return (_openBlock(), _createElementBlock("tr", {
                        key: data.key
                      }, [
                        (_ctx.savedState.showDeliveryType)
                          ? (_openBlock(), _createElementBlock("td", _hoisted_2, _toDisplayString(data.deliveryType), 1))
                          : _createCommentVNode("", true),
                        (_ctx.savedState.showDeliveryState)
                          ? (_openBlock(), _createElementBlock("td", _hoisted_3, _toDisplayString(data.status), 1))
                          : _createCommentVNode("", true),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.getSortedQuantity(), (q) => {
                          return (_openBlock(), _createElementBlock("td", {
                            class: _normalizeClass(["number", {clickable:q.quantity > 0 }]),
                            key: q.date.getTime(),
                            onClick: ($event: any) => (_ctx.showShipments(data,q.date))
                          }, [
                            _createTextVNode(_toDisplayString(q.quantity > 0 ? q.quantity.toString() : '') + " " + _toDisplayString((q.isLate > 0  && _ctx.savedState.showLate) ? `(${q.isLate.toString()})` : '') + " ", 1),
                            _createElementVNode("div", _hoisted_5, _toDisplayString((q.merchantIssues > 0 && _ctx.savedState.showIssues) ? q.merchantIssues.toString() : '') + " " + _toDisplayString((q.ogoIssues > 0 && _ctx.savedState.showIssues && _ctx.adminsRole) ? `(${q.ogoIssues.toString()})` : ''), 1)
                          ], 10, _hoisted_4))
                        }), 128))
                      ]))
                    }), 128))
                  ])
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_modal, {
      isOpen: _ctx.isShipmentListOpen,
      onDidDismiss: _cache[13] || (_cache[13] = ($event: any) => (_ctx.isShipmentListOpen = false))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_header, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_toolbar, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_buttons, { slot: "start" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_button, {
                      disabled: _ctx.processing,
                      onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.isShipmentListOpen = false))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Close")
                      ]),
                      _: 1
                    }, 8, ["disabled"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_title, null, {
                  default: _withCtx(() => [
                    _createTextVNode("Shipments")
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_buttons, { slot: "end" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_button, {
                      disabled: _ctx.processing,
                      onClick: _ctx.refreshAllFromDay
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Refresh all")
                      ]),
                      _: 1
                    }, 8, ["disabled", "onClick"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_ion_progress_bar, { value: _ctx.progress }, null, 8, ["value"])
          ]),
          _: 1
        }),
        (_ctx.shipmentDetailsList)
          ? (_openBlock(), _createBlock(_component_ion_content, {
              key: 0,
              class: "ion-padding"
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.shipmentDetailsList, (shipment) => {
                  return (_openBlock(), _createBlock(_component_ion_item, {
                    key: shipment.uid
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(shipment.warehouse) + " => " + _toDisplayString(shipment.countryCode) + " (" + _toDisplayString(shipment.postCode) + ") ", 1),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(shipment.parcels, (p, index) => {
                            return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(p.trackingCodes, (t) => {
                                return (_openBlock(), _createElementBlock("span", {
                                  key: t.code
                                }, _toDisplayString(p.trackingProvider) + " " + _toDisplayString(t.code) + " ", 1))
                              }), 128))
                            ], 64))
                          }), 128)),
                          _createElementVNode("div", null, "Late: " + _toDisplayString(shipment.parcels.filter(i => i.isLate).length > 0 ? 'Yes' : 'No'), 1),
                          _createElementVNode("div", null, "Tracking Issue: " + _toDisplayString(shipment.merchantIssue), 1),
                          (_ctx.adminsRole)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_6, "OGOship Issue: " + _toDisplayString(shipment.ogoIssue), 1))
                            : _createCommentVNode("", true)
                        ]),
                        _: 2
                      }, 1024),
                      _createVNode(_component_ion_button, {
                        disabled: _ctx.processing,
                        slot: "end",
                        onClick: ($event: any) => (_ctx.router.push({name: 'TrackingDetails', params: {id: shipment.uid, channel: shipment.channelId}}))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("Open")
                        ]),
                        _: 2
                      }, 1032, ["disabled", "onClick"])
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["isOpen"])
  ], 64))
}