export class SenderInfoUpdateModel {
    name: string | undefined;
    street: string | undefined;
    zip: string | undefined;
    city: string | undefined;
    state: string | undefined;
    country: string | undefined;
    email: string | undefined;
    phone: string | undefined;
    language: string | undefined;
}

export class ChannelUpdateDto {
    name: string | undefined;
    rebuyUrl: string | undefined;
    notificationSettings: Array<NotificationSettingDto> | undefined;
    senderInfo: SenderInfoUpdateModel | undefined;
    defaultCurrency: string | undefined;
    returnReasons: Array<string> | undefined;
    loginNotRequired: boolean | undefined;
    orderCancellationSettings: OrderCancellationSettingEnum | undefined;
}

export class Co2ServiceUpdateModel {
    enabled: boolean | undefined;
}

export class ReturnServiceUpdateModel{
    enabled: boolean | undefined;
    inMaintenance: boolean | undefined;
}

export class RebuyServiceUpdateModel {
    enabled: boolean | undefined;
    level: RebuyLevel | undefined;
    inMaintenance: boolean | undefined;
}

export class ServicesUpdateModel {
    rebuyService: RebuyServiceUpdateModel | undefined;
    returnService: ReturnServiceUpdateModel | undefined;
    co2Service: Co2ServiceUpdateModel | undefined;
}

export class MerchantUpdateModel {
    companyName: string | undefined;
    brandName: string | undefined;
    lang: string | undefined;
    defaultCurrency: string | undefined;
    services: ServicesUpdateModel | undefined;
}

export class NotificationDeliveryOptionDto {
    method: string;
    isAllowed: boolean;
    isDefault: boolean;
    constructor() {
        this.method = "";
        this.isAllowed = false;
        this.isDefault = false;
    }
}

export class NotificationSettingDto {
    templateKey: string;
    deliveryOptions: Array<NotificationDeliveryOptionDto>;

    constructor() {
        this.templateKey = "";
        this.deliveryOptions = [];
    }
}

export class Co2ServiceDto {
    enabled: boolean;
    constructor() {
        this.enabled = false;
    }
}

export class ReturnServiceDto {
    enabled: boolean;
    inMaintenance: boolean;
    constructor() {
        this.enabled = false;
        this.inMaintenance = false;
    }
}

export class RebuyServiceDto {
    enabled: boolean;
    level: RebuyLevel;
    inMaintenance: boolean;
    constructor() {
        this.enabled = false;
        this.level = RebuyLevel.Free;
        this.inMaintenance = false;
    }
}

export enum OrderCancellationSettingEnum {
    Disabled = "Disabled",
    CancelBeforeShipping = "CancelBeforeShipping",
    StopBeforeShipping = "StopBeforeShipping",
}

export enum RebuyLevel
{
    Free = 0,
    Growth = 1,
    Enterprise = 2
}

export class ServicesDto  
{
    rebuyService: RebuyServiceDto;
    returnService: ReturnServiceDto;
    co2Service?: Co2ServiceDto;
    constructor(){
        this.rebuyService = new RebuyServiceDto();
        this.returnService = new ReturnServiceDto();
        this.co2Service = new Co2ServiceDto();
    }
}

export class ChannelDto  
{
    id: number;
    name: string;
    rebuyUrl: string;

    notificationSettings: any;
    senderInfo: any;
    defaultCurrency: any;
    warehouses: any;
    returnRules: any;
    returnSettings: any;
    returnReasons: any;
    orderCancellationSettings: OrderCancellationSettingEnum;
    loginNotRequired = false;

    constructor(){
        this.id = 0;
        this.name = "";
        this.rebuyUrl = "";
        this.orderCancellationSettings = OrderCancellationSettingEnum.Disabled;
    }
}

export class WarehouseConfigDto  
{
    id: string;
    enabled: boolean;

    constructor(){
        this.id = "";
        this.enabled = false;
    }
}


export class MerchantDto  
{
  uid: string;
  companyName: string;
  brandName: string;
  lang: string;
  defaultCurrency: string;

  services: ServicesDto;
  channels: Array<ChannelDto>;
  warehouses: Array<WarehouseConfigDto>;

  constructor(){
    this.uid = "";
    this.companyName = "";
    this.brandName = "";
    this.lang = "";
    this.defaultCurrency = "";

    this.services = new ServicesDto();
    this.channels = [];
    this.warehouses = [];
  }
}
export class ChannelWithSettingsView {
    merchantId = "";
    merchantCompany = "";
    merchantBrand = "";
    name = "";
    id = 0;
    country = "";
    returnSettings: ReturnSettingsPreView = new ReturnSettingsPreView;
    rebuySettings: RebuySettingsPreView = new RebuySettingsPreView;
    cO2Service: CO2ServicePreView = new CO2ServicePreView;
}

export class ReturnSettingsPreView {
    enabled = false;
}

export class RebuySettingsPreView {
    enabled = false;
    level: RebuyLevel | undefined;
}

export class CO2ServicePreView {
    enabled = false;
}